import { ComponentType, ReactNode } from 'react';
import { useAppSelector } from '@point-of-sale/store';
import * as Styles from './styles';
import Text from '../Typography/Text';

interface IWithHeaderProps {
  name?: ReactNode;
  right?: ReactNode;
}

function WithHeader<P extends object>(
  WrappedComponent: ComponentType<P>,
  headerProps?: IWithHeaderProps
) {
  const HigherOrderComponent: React.FC<P> = props => {
    const selectedSalesChannel = useAppSelector(
      store => store.identity.selectedSalesChannel
    );

    return (
      <>
        <Styles.Wrapper>
          <Styles.Left>
            <Text fontSize={16} weight="bold">
              {selectedSalesChannel?.name} | {headerProps?.name}
            </Text>
          </Styles.Left>
          {headerProps?.right && (
            <Styles.Right>{headerProps.right}</Styles.Right>
          )}
        </Styles.Wrapper>
        <WrappedComponent {...props} />
      </>
    );
  };

  return HigherOrderComponent;
}

export default WithHeader;
