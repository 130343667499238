import { useEffect } from 'react';
import toast from 'react-hot-toast';

export function useIsOnline() {
  function setStatusToBody(status: 'online' | 'offline') {
    const node = document.querySelector('body');
    if (!node) {
      return;
    }
    if (status === 'online') {
      node.classList.remove('offline');
    } else {
      node.classList.add('offline');
    }
  }

  useEffect(() => {
    function setOnline() {
      setStatusToBody('online');
      toast.success('You are Back Online', { duration: 1000 });
    }

    function setOffline() {
      setStatusToBody('offline');
      toast.error(
        'Oops, You are Offline, Please check your internet connection',
        { duration: 1000 }
      );
    }

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);
    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);
}
