import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LineItemDTOType, OrderDTOType } from '@point-of-sale/schemas';
import {
  getOrderDetailsById,
  setOrderDetails,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { isEmptyObject } from '@point-of-sale/utils';
import LineItem from './LineItem';
import { IOrderDetailsContext, OrderDetailsContext } from './context';
import { Button } from '@point-of-sale/components';
import { OperationType } from './types';
import CustomerDetails from './CustomerDetails';
import ReturnDetails from './ReturnDetails';
import CancellationDetails from './CancellationDetails';
import BasicDetails from './BasicDetails';
import { useBoolean } from '@point-of-sale/hooks';
import RefundReceiptGenerationModal from './RefundReceiptGenerationModal';
import * as Styles from './styles';

type ParamsType = {
  id: string;
};

const OrderDetails = () => {
  const { id } = useParams<ParamsType>();

  const data = useAppSelector(state => state.oms.details.data);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate('/orders');
      return;
    }

    if (isEmptyObject(data) || id !== String(data.id)) {
      dispatch(getOrderDetailsById(id));
      return;
    }
  }, [id, data]);

  // * UI specific state
  // TODO: Prevent Rerenders
  // const listContainerRef = useRef<HTMLDivElement>(null);
  // const { scrollYProgress } = useScroll({
  //   container: listContainerRef,
  // });
  // const [doesTopHaveBorder, setDoesTopHaveBorder] = useState(false);

  const [isModalOpen, modalOpenActions] = useBoolean();

  // useMotionValueEvent(scrollYProgress, 'change', latest => {
  //   setDoesTopHaveBorder(latest > 0);
  // });

  // * For context
  // TODO: Maybe move these to a separate file
  const [operation, setOperation] = useState<OperationType>('READ_ONLY');
  const [lineItemsSelection, setLineItemsSelection] = useState<
    IOrderDetailsContext['lineItemsSelection']
  >({});
  const [reasons, setReasons] = useState({});

  const onToggleLineItemSelection = (lineItemId: number) => {
    setLineItemsSelection(prevState => ({
      ...prevState,
      [lineItemId]: !prevState[lineItemId],
    }));
  };

  const onReasonChange = (lineItemId: number, reason: string) => {
    setReasons(prevState => ({
      ...prevState,
      [lineItemId]: reason,
    }));
  };

  useEffect(() => {
    if (data.lineItems) {
      const lineItemsSelection: Record<number, boolean> = {};
      const returnReasons: Record<number, string> = {};
      data.lineItems.forEach(item => {
        lineItemsSelection[item.id] = false;
        returnReasons[item.id] = '';
      });
      setLineItemsSelection(lineItemsSelection);
      setReasons(returnReasons);
    }
  }, [data.lineItems]);

  const [pdfData, setPdfData] = useState<Array<LineItemDTOType>>([]);

  useEffect(() => {
    return () => {
      setPdfData([]);

      dispatch(
        setOrderDetails({
          data: {} as OrderDTOType,
        })
      );
    };
  }, []);

  return (
    <OrderDetailsContext.Provider
      value={{
        operation,
        lineItemsSelection,
        onToggleLineItemSelection,
        reasons,
        onReasonChange,
      }}
    >
      <Styles.Wrapper>
        <Styles.Content>
          <Styles.Top>
            <BasicDetails data={data} />
          </Styles.Top>
          <Styles.Bottom>
            <Styles.Left>
              <Styles.LeftTop $doesTopHaveBorder={false}>
                {/* <Toggle
                  checked={operation === 'RETURNS_AND_EXCHANGES'}
                  onChange={() => {
                    if (operation === 'RETURNS_AND_EXCHANGES') {
                      setOperation('READ_ONLY');
                    } else {
                      setOperation('RETURNS_AND_EXCHANGES');
                    }
                    setReasons({});
                    setLineItemsSelection({});
                  }}
                  rightLabel="Return & Exchange"
                />
                <Toggle
                  checked={operation === 'CANCELLATION'}
                  onChange={() => {
                    if (operation === 'CANCELLATION') {
                      setOperation('READ_ONLY');
                    } else {
                      setOperation('CANCELLATION');
                    }
                    setReasons({});
                    setLineItemsSelection({});
                  }}
                  rightLabel="Cancellation"
                /> */}
                <Styles.ModeSwitchContainer>
                  <Button
                    sizeVariant="small"
                    onClick={() => {
                      if (operation === 'RETURNS_AND_EXCHANGES') {
                        setOperation('READ_ONLY');
                      } else {
                        setOperation('RETURNS_AND_EXCHANGES');
                      }

                      setReasons({});
                      setLineItemsSelection({});
                    }}
                    colorScheme="primary"
                    variant={
                      operation === 'RETURNS_AND_EXCHANGES' ? 'fill' : 'outline'
                    }
                  >
                    Return & Exchange
                  </Button>
                  <Button
                    sizeVariant="small"
                    onClick={() => {
                      if (operation === 'CANCELLATION') {
                        setOperation('READ_ONLY');
                      } else {
                        setOperation('CANCELLATION');
                      }

                      setReasons({});
                      setLineItemsSelection({});
                    }}
                    colorScheme="primary"
                    variant={operation === 'CANCELLATION' ? 'fill' : 'outline'}
                  >
                    Cancellation
                  </Button>
                </Styles.ModeSwitchContainer>
                {/* <RadioGroup
                  horizontal
                  name="operation"
                  options={[
                    {
                      label: 'Return & Exchange',
                      value: 'RETURNS_AND_EXCHANGES',
                    },
                    {
                      label: 'Cancellation',
                      value: 'CANCELLATION',
                    },
                  ]}
                  selectedValue={operation}
                  onClick={selectedValue => {
                    if (selectedValue === 'CANCELLATION') {
                      if (operation === 'CANCELLATION') {
                        setOperation('READ_ONLY');
                      } else {
                        setOperation('CANCELLATION');
                      }
                    }
                    if (selectedValue === 'RETURNS_AND_EXCHANGES') {
                      if (operation === 'RETURNS_AND_EXCHANGES') {
                        setOperation('READ_ONLY');
                      } else {
                        setOperation('RETURNS_AND_EXCHANGES');
                      }
                    }
                    setReasons({});
                    setLineItemsSelection({});
                  }}
                /> */}
              </Styles.LeftTop>
              <Styles.LineItemsContainer>
                {data.lineItems?.map(item => (
                  <LineItem key={item.id} data={item} />
                ))}
              </Styles.LineItemsContainer>
            </Styles.Left>
            <Styles.Right>
              {operation === 'RETURNS_AND_EXCHANGES' && (
                <ReturnDetails
                  data={data}
                  setPdfData={dataToPrint => {
                    setPdfData(dataToPrint);
                    modalOpenActions.on();
                  }}
                />
              )}

              {operation === 'CANCELLATION' && (
                <CancellationDetails
                  data={data}
                  setPdfData={dataToPrint => {
                    setPdfData(dataToPrint);
                    modalOpenActions.on();
                  }}
                />
              )}

              <CustomerDetails data={data} />

              <RefundReceiptGenerationModal
                orderData={data}
                pdfData={pdfData}
                isOpen={isModalOpen}
                onClose={modalOpenActions.off}
              />
            </Styles.Right>
          </Styles.Bottom>
        </Styles.Content>
      </Styles.Wrapper>
    </OrderDetailsContext.Provider>
  );
};

export default OrderDetails;
