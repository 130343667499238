import { ThemeProvider, useTheme } from 'styled-components';
import { ThemeTokens } from '../tokens/types';
import { useIsMobile } from '@point-of-sale/hooks';
import { ReactNode } from 'react';
import { useAppSelector } from '@point-of-sale/store';
import GlobalStyles from '../GlobalStyles';

interface IDesignSystemProviderProps {
  themeTokens: ThemeTokens;
  children: ReactNode;
}

const DesignSystemProvider = ({
  themeTokens,
  children,
}: IDesignSystemProviderProps) => {
  const isMobile = useIsMobile();

  const theme = {
    name: themeTokens.name,
    typography: themeTokens.typography[isMobile ? 'MOBILE' : 'DESKTOP'],
  };

  const mode = useAppSelector(store => store.pointOfSale.mode);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles $mode={mode} />
      {children}
    </ThemeProvider>
  );
};

export default DesignSystemProvider;
