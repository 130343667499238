import { IconPropsType } from '../types';

const LargeRedExclamationIcon = ({
  fill = 'var(--persian-red)',
  ...props
}: IconPropsType) => (
  <svg
    width={110}
    height={110}
    viewBox="0 0 1024 1024"
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill}
      d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896m0 192a58.43 58.43 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.43 58.43 0 0 0 512 256m0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4"
    />
  </svg>
);
export default LargeRedExclamationIcon;
