import { Text } from '@point-of-sale/components';
import EmptyPaperBag from '../../svgs/EmptyPaperBag';
import { FlavorType } from '../../types';
import * as Styles from './styles';

interface INoResultsProps {
  text?: string;
  flavor?: FlavorType;
}

const EmptyCart = ({
  text = 'Your cart is empty',
  flavor = 'blueberry',
}: INoResultsProps) => {
  return (
    <Styles.Wrapper>
      <EmptyPaperBag flavor={flavor} />
      <Text
        fontSize={16}
        color="var(--dove-gray)"
        weight="semibold"
        textAlign="center"
      >
        {text}
      </Text>
    </Styles.Wrapper>
  );
};

export default EmptyCart;
