import { IconPropsType } from '../types';

const DashboardIcon = ({
  stroke = 'var(--dove-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.418 7.984-4.889-3.802a2.444 2.444 0 0 0-3 0l-4.89 3.802a2.44 2.44 0 0 0-.944 1.93v6.6c0 1.012.821 1.833 1.834 1.833h11c1.012 0 1.833-.82 1.833-1.833v-6.6c0-.755-.348-1.467-.944-1.93"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.665 13.75c-2.025 1.222-5.309 1.222-7.333 0"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DashboardIcon;
