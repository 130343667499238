import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Text } from '@point-of-sale/components';
import { EMAIL_REGEX } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import PhoneOrEmailInput from '../../../components/PhoneOrEmailInput';
import {
  initializeCart,
  resetCheckoutState,
  resetPointOfSaleState,
  searchCustomer,
  setAddressesData,
  setCustomerDetails,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { createSearchParams, useNavigate } from 'react-router-dom';
import * as Styles from './styles';

interface IPhoneOrEmailProps {
  setStep: (step: number) => void;
}

const PhoneOrEmail = ({ setStep }: IPhoneOrEmailProps) => {
  // const [loginType, setLoginType] = useState<'MOBILE' | 'EMAIL'>('EMAIL');
  const [inputValue, setInputValue] = useState('');
  const [countryCode, setCountryCode] = useState('+91');

  const isLoading = useAppSelector(
    store => store.pointOfSale.customer.isLoading
  );

  const [isGuestCheckoutLoading, guestCheckoutLoadingActions] = useBoolean();

  const [areCTAsEnabled, ctasEnabledActions] = useBoolean();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  function onChange(value: string) {
    setInputValue(value);

    if (/^\d+$/.test(value)) {
      if (countryCode.length > 0) {
        ctasEnabledActions.on();
      } else {
        ctasEnabledActions.off();
      }
    }
  }

  function handleContinue() {
    if (!areCTAsEnabled) {
      toast('Provide valid phone number to proceed', {
        duration: 1000,
      });
      return;
    }

    function successCallback() {
      setStep(2);
    }

    dispatch(
      searchCustomer({ phoneNumber: inputValue, countryCode }, successCallback)
    );

    // if (loginType === 'MOBILE') {
    //   dispatch(
    //     searchCustomer(
    //       { phoneNumber: inputValue, countryCode },
    //       successCallback
    //     )
    //   );
    // }

    // if (loginType === 'EMAIL') {
    //   dispatch(searchCustomer({ email: inputValue }, successCallback));
    // }
  }

  function handleGuestCheckout() {
    function successCallback(cartId: string) {
      const params = createSearchParams({
        id: cartId,
      });

      guestCheckoutLoadingActions.off();

      navigate(`/point-of-sale/cart?${params.toString()}`);
    }

    guestCheckoutLoadingActions.on();

    dispatch(
      setCustomerDetails({
        data: {},
        isLoading: false,
        isSuccess: true,
      })
    );
    dispatch(
      setAddressesData({
        data: {
          ids: [],
          records: {},
        },
        isLoading: false,
        isSuccess: true,
      })
    );
    dispatch(resetPointOfSaleState());
    dispatch(resetCheckoutState());

    dispatch(initializeCart(successCallback));
  }

  return (
    <Styles.Wrapper>
      <PhoneOrEmailInput
        // loginType={loginType}
        countryCode={countryCode}
        inputValue={inputValue}
        onChange={onChange}
        setCountryCode={setCountryCode}
      />

      <Button colorScheme="dark" onClick={handleContinue} isLoading={isLoading}>
        CONTINUE
      </Button>

      <Text textAlign="center">or</Text>

      <Button
        colorScheme="dark"
        variant="outline"
        onClick={handleGuestCheckout}
        isLoading={isGuestCheckoutLoading}
      >
        GUEST CHECKOUT
      </Button>
    </Styles.Wrapper>
  );
};

export default PhoneOrEmail;
