import { updateAttributesOfCdnImage } from '@point-of-sale/utils';
import { Image } from '@point-of-sale/components';
import useProductContext from '../context/useProductContext';
import { useAppSelector } from '@point-of-sale/store';
import ReturnsAndExchangePolicy from '../../ReturnsAndExchangePolicy';
import { CUSTOM_TAILORED } from '@point-of-sale/constants';
import * as Styles from './styles';
import { useMemo } from 'react';

const ProductImage = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const {
    data: { images, name },
    selectedVariant,
    isExpanded,
  } = useProductContext();

  const firstImage = images?.[0];

  const displayedImage = selectedVariant
    ? selectedVariant.images?.[0] ?? firstImage
    : firstImage;

  const highResImage = useMemo(
    () =>
      updateAttributesOfCdnImage(displayedImage?.url, {
        width: 720,
        quality: 100,
      }),
    [displayedImage]
  );

  return (
    <>
      <Styles.ImageWrapper
        variants={{
          expanded: { height: '376px', width: '237px' },
          collapsed: { height: '90px', width: '60px' },
        }}
        transition={{ duration: 0.45 }}
      >
        <Image
          key={selectedVariant?.skuCode ?? name}
          height={isExpanded ? 376 : 90}
          width={isExpanded ? 237 : 60}
          duration={0.45}
          src={highResImage}
          alt="Product"
          iconSize={isExpanded ? 56 : 28}
        />
      </Styles.ImageWrapper>

      {mode === 'BUY_NOW' && isExpanded && selectedVariant && (
        <ReturnsAndExchangePolicy
          isExchangeable={selectedVariant.exchangeable}
          isReturnable={selectedVariant.returnable}
        />
      )}

      {mode === 'MADE_TO_ORDER' && isExpanded && selectedVariant && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {selectedVariant?.size === CUSTOM_TAILORED.key ? (
            <ReturnsAndExchangePolicy
              isExchangeable={selectedVariant?.mtcReturnable}
              isReturnable={selectedVariant?.mtcReturnable}
            />
          ) : (
            <ReturnsAndExchangePolicy
              isExchangeable={selectedVariant?.mtoReturnable}
              isReturnable={selectedVariant?.mtoReturnable}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductImage;
