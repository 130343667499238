export function isArray(array: any): array is Array<any> {
  return Array.isArray(array);
}

export function isString(str: unknown): str is string {
  return typeof str === 'string' || str instanceof String;
}

/**
 *
 * @returns whether the provided parameter is of type `object` but **not**
 *	`null`, an `array`, a `regexp`, nor a `date`.
 */
export function isObject(obj: unknown): boolean {
  // The method can't do a type cast since there are type (like strings) which
  // are subclasses of any put not positvely matched by the function. Hence type
  // narrowing results in wrong results.
  return (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof RegExp) &&
    !(obj instanceof Date)
  );
}

/**
 * In **contrast** to just checking `typeof` this will return `false` for `NaN`.
 * @returns whether the provided parameter is a JavaScript Number or not.
 */
export function isNumber(num: unknown): num is number {
  return typeof num === 'number' && !Number.isNaN(num);
}

function isParsableToNumberType(num: any): boolean {
  return !Number.isNaN(parseFloat(num)) && !Number.isNaN(num - 0);
}

/**
 * @returns whether the provided parameter is an Iterable, casting to the given generic
 */
export function isIterable(obj: unknown): boolean {
  return !!obj && typeof (obj as any)[Symbol.iterator] === 'function';
}

export function isBoolean(obj: unknown): obj is boolean {
  return obj === true || obj === false;
}

export function isUndefined(obj: unknown): obj is undefined {
  return typeof obj === 'undefined';
}

export function isNull(obj: unknown): obj is null {
  return obj === null;
}

export function isUndefinedOrNull(obj: unknown): boolean {
  return isUndefined(obj) || isNull(obj);
}

export function isDefined<T>(arg: T | null | undefined): boolean {
  return !isUndefinedOrNull(arg);
}

export function isEmptyArray(value: any): boolean {
  return isArray(value) && value.length === 0;
}

export function isEmptyObject(value: any): boolean {
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return true;
}

// Empty assertions
export function isEmpty(value: any): boolean {
  if (isArray(value)) {
    return isEmptyArray(value);
  }
  if (isObject(value)) {
    return isEmptyObject(value);
  }
  return Boolean(value == null || value === '');
}

// Check if a number given is actually a number
// '2.5' is number, although its in string format
export function convertStringToNumberFormat(
  numberString: unknown
): number | null {
  const numberVersion = Number(numberString);
  const isNumberFormat = Number.isFinite(numberVersion);
  return isNumberFormat ? numberVersion : null;
}

export function isStringNumber(
  value: string,
  max?: string | number,
  min?: string | number
): boolean {
  if (!isParsableToNumberType(value)) {
    return false;
  }
  if (
    typeof max !== 'undefined' &&
    Number(max) &&
    Number(value) &&
    Number(value) > max
  ) {
    return false;
  }
  if (
    typeof min !== 'undefined' &&
    Number(min) &&
    Number(value) &&
    Number(value) < min
  ) {
    return false;
  }
  return true;
}
