import { ReactNode, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useBoolean, useOnClickOutside } from '@point-of-sale/hooks';
import { ChevronDownIcon, ChevronRightIcon, CrossIcon } from '../icons';
import OptionItem from './OptionItem';
import { IOption, SelectSizeVariants } from './types';
import * as Styles from './styles';

interface ISelectProps {
  options: Array<IOption>;
  onChange: (value: IOption) => void;
  value: string | number | null;
  defaultValue?: string | number;
  isLoading?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  className?: string;
  onClear?: () => void;
  isOutlined?: boolean;
  sizeVariant?: SelectSizeVariants;
  isDisabled?: boolean;
  trigger?: boolean; // New trigger prop
  label?: ReactNode;
}

const Select = ({
  options,
  defaultValue,
  value,
  onChange,
  onClear,
  className = '',
  placeholder = 'Select',
  isLoading = false,
  isSearchable = false,
  isOutlined = false,
  sizeVariant = 'regular',
  isDisabled = false,
  trigger, // Destructure the trigger prop
  label,
}: ISelectProps) => {
  const [search, setSearch] = useState('');

  const [isSelectOpen, selectActions] = useBoolean();
  const [isClickOutsideAllowed, clickOutsideAllowedActions] = useBoolean(true);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLInputElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {});

  useOnClickOutside(wrapperRef, () => {
    if (isClickOutsideAllowed) {
      selectActions.off();
    }
  });

  const listableOptions = isSearchable
    ? search
      ? options.filter(option =>
          String(option.label).toLowerCase().includes(search.toLowerCase())
        )
      : options
    : options;

  useEffect(() => {
    if (defaultValue) {
      const defaultOption = options.find(
        option => option.value === defaultValue
      );
      if (defaultOption) {
        setSearch(defaultOption.label as string);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (trigger !== undefined) {
      if (trigger) {
        selectActions.on();
      } else {
        selectActions.off();
      }
    }
  }, [trigger]);

  return (
    <Styles.Wrapper
      className={className}
      ref={wrapperRef}
      $isOutlined={isOutlined}
      $sizeVariant={sizeVariant}
    >
      {label}
      <Styles.InputWrapper
        $sizeVariant={sizeVariant}
        ref={ref => setReferenceElement(ref)}
        placeholder={placeholder}
        value={search}
        onChange={event => {
          if (isSearchable) {
            setSearch(event.target.value);
          }

          if (event.target.value.length === 0) {
            onClear?.();
          }
        }}
        onFocus={selectActions.on}
        disabled={isDisabled}
        readOnly
      />
      <Styles.IconWrapper>
        {search ? (
          onClear && (
            <CrossIcon
              onClick={() => {
                onClear();
                setSearch('');
              }}
            />
          )
        ) : isSelectOpen ? (
          <ChevronRightIcon />
        ) : (
          <ChevronDownIcon />
        )}
      </Styles.IconWrapper>

      {createPortal(
        <AnimatePresence>
          {isSelectOpen && (
            <Styles.OptionsWrapper
              ref={ref => setPopperElement(ref)}
              style={{
                ...styles.popper,
                width: referenceElement?.getBoundingClientRect().width,
              }}
              {...attributes.popper}
              onMouseEnter={clickOutsideAllowedActions.off}
              onMouseLeave={clickOutsideAllowedActions.on}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: '300px' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              {listableOptions?.map(option => (
                <OptionItem
                  key={`${option.label}`}
                  onClick={() => {
                    onChange(option);
                    setSearch(option.label as string);
                    selectActions.off();
                  }}
                  isDisabled={option.isDisabled}
                  isSelected={option.value === value}
                  label={option.label}
                />
              ))}
            </Styles.OptionsWrapper>
          )}
        </AnimatePresence>,
        document.body
      )}
    </Styles.Wrapper>
  );
};

export default Select;
