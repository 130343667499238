import { Text } from '@point-of-sale/components';
import { ComponentProps } from 'react';

interface IKeyValuePairProps {
  title?: string | number;
  value: string | number;
  br?: boolean;
  titleProps?: Omit<ComponentProps<typeof Text>, 'children'>;
  valueProps?: Omit<ComponentProps<typeof Text>, 'children'>;
}

const KeyValuePair = ({
  title,
  value,
  br,
  titleProps,
  valueProps,
}: IKeyValuePairProps) => {
  return (
    <Text
      fontSize={14}
      weight="regular"
      color="var(--dove-gray)"
      {...titleProps}
    >
      {title && (
        <>
          {title}: &nbsp;{br && <br />}
        </>
      )}

      <Text
        fontSize={14}
        weight="semibold"
        color="var(--cod-gray)"
        {...valueProps}
      >
        {value}
      </Text>
    </Text>
  );
};

export default KeyValuePair;
