import { Select, SelectDropdown, Text } from '@point-of-sale/components';
import * as Styles from './styles';
import {
  setSalesPoc,
  setSalesStaffIdOfCart,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useEffect } from 'react';

const Parties = () => {
  const salesPoc = useAppSelector(store => store.checkout.salesPoc);
  const { ids: storeStaffIds, records: storeStaffRecords } = useAppSelector(
    store => store.common.storeStaff.data
  );
  const selectedSalesStaffId = useAppSelector(
    store => store.pointOfSale.cart.data.salesStaffId
  );

  const hasPaymentsStarted = useAppSelector(
    store => store.checkout.cartTransactions.data.ids.length > 0
  );

  useEffect(() => {
    if (selectedSalesStaffId) {
      const selectedSalesPoc = Object.values(storeStaffRecords).find(
        item => String(item.id) === String(selectedSalesStaffId)
      );

      if (selectedSalesPoc) {
        dispatch(setSalesPoc(selectedSalesPoc));
      }
    }
  }, [selectedSalesStaffId, storeStaffRecords]);

  const dispatch = useAppDispatch();

  return (
    <Styles.Wrapper>
      <Text fontSize={14} weight="bold" color="var(--black)">
        Sales POC
        <Text fontSize={14} color="var(--alizarin-crimson)">
          *
        </Text>
      </Text>
      <Select
        isOutlined
        isDisabled={hasPaymentsStarted}
        sizeVariant="small"
        options={storeStaffIds
          .filter(id => storeStaffIds.includes(id))
          .map(id => ({ label: storeStaffRecords[id].name, value: id }))}
        value={salesPoc?.id ?? ''}
        defaultValue={salesPoc?.id}
        onChange={selectedOption => {
          const newSalesPoc = Object.values(storeStaffRecords).find(
            item => String(item.id) === String(selectedOption.value)
          );
          if (newSalesPoc) {
            dispatch(setSalesStaffIdOfCart(newSalesPoc));
          }
        }}
      />
      {/* <SelectDropdown
        sizeVariant="small"
        options={storeStaffIds
          .filter(id => storeStaffIds.includes(id))
          .map(id => ({ label: storeStaffRecords[id].name, value: id }))}
        labelField="label"
        valueField="value"
        onChange={selectedOptions => {
          const selectedOption = selectedOptions[0];

          if (!selectedOption) {
            return;
          }

          console.log('TRIGGER', selectedOption.label);

          const newSalesPoc = Object.values(storeStaffRecords).find(
            item => String(item.id) === String(selectedOption.value)
          );
          if (newSalesPoc) {
            dispatch(setSalesStaffIdOfCart(newSalesPoc));
          }
        }}
        values={[
          storeStaffIds.find(id => id === selectedSalesStaffId)
            ? {
                label:
                  selectedSalesStaffId &&
                  storeStaffRecords[selectedSalesStaffId]?.name,
                value: selectedSalesStaffId,
              }
            : {},
        ]}
        dropdownHeight="250px"
      /> */}
    </Styles.Wrapper>
  );
};

export default Parties;
