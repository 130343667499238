import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, RadioGroup, Text } from '@point-of-sale/components';
import { bulkReturnOrderItems, useAppDispatch } from '@point-of-sale/store';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import {
  LineItemDTOType,
  OrderDTOType,
  RefundModeEnumSchema,
  RefundModeType,
} from '@point-of-sale/schemas';

import KeyValuePair from '../KeyValuePair';
import Box from '../Box';
import { useOrderDetailsContext } from '../context';

import { RETURN_REASONS } from '../constants';
import * as Styles from './styles';

interface IReturnDetailsProps {
  data: OrderDTOType;
  setPdfData: (data: Array<LineItemDTOType>) => void;
}

const ReturnDetails = ({ data, setPdfData }: IReturnDetailsProps) => {
  const { lineItemsSelection, reasons: returnReasons } =
    useOrderDetailsContext();

  const dispatch = useAppDispatch();

  const itemsToBeReturned = Object.entries(lineItemsSelection)
    .filter(([id, isSelected]) => isSelected)
    .map(([id]) => id)
    .map(Number);

  const currency =
    CURRENCY_SYMBOLS[
      (data?.costBreakup?.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR'
    ]?.code;

  const refundAmount = data.lineItems
    ?.filter(item => itemsToBeReturned.includes(item.id))
    ?.reduce((acc, curr) => {
      if (!curr.costBreakup?.amountPaid) {
        return acc + 0;
      }

      return acc + curr.costBreakup?.amountPaid;
    }, 0);

  const [refundMode, setRefundMode] = useState<RefundModeType | null>(
    RefundModeEnumSchema.Enum.STORE_CREDIT
  );

  return (
    <Box header="Return Details" hasPadding={false} flexOne>
      <Styles.Content>
        <Styles.Section>
          <KeyValuePair title="No of items" value={itemsToBeReturned.length} />
        </Styles.Section>
        <Styles.Section $flexOne>
          <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
            Refund Mode
            <Text weight="semibold" color="var(--alizarin-crimson)">
              *
            </Text>
          </Text>
          <RadioGroup
            horizontal
            name="refund-mode"
            options={[
              {
                label: 'Store Credit',
                value: RefundModeEnumSchema.Enum.STORE_CREDIT,
              },
              {
                label: 'Cash',
                value: RefundModeEnumSchema.Enum.CASH,
              },
            ]}
            selectedValue={refundMode ?? ''}
            onChange={selectedValue => {
              setRefundMode(selectedValue as RefundModeType);
            }}
          />
        </Styles.Section>
        {/* <Styles.Section>
          <KeyValuePair title="Store Credit For Size Exchange" value="price" />
        </Styles.Section> */}
        <Styles.Section>
          <KeyValuePair
            title="Refund Amount"
            value={`${currency} ${refundAmount}`}
          />
        </Styles.Section>
        <Styles.Section>
          <Button
            isFullWidth
            isDisabled={itemsToBeReturned.length === 0}
            onClick={() => {
              if (!refundMode) {
                toast.error('Please select refund mode');
                return;
              }

              dispatch(
                bulkReturnOrderItems(
                  {
                    ids: itemsToBeReturned,
                    refundMode: refundMode,
                    returnMode: 'AT_STORE',
                    returnReasons: itemsToBeReturned
                      .map(id => returnReasons[id])
                      .map(
                        reason =>
                          RETURN_REASONS.find(r => r.value === reason)?.label ??
                          ''
                      ),
                  },
                  setPdfData
                )
              );
            }}
          >
            Process Refund
          </Button>
        </Styles.Section>
      </Styles.Content>
    </Box>
  );
};

export default ReturnDetails;
