import { Button, RadioGroup, Text } from '@point-of-sale/components';

import {
  BulkCancelOrderItemDTOType,
  LineItemDTOType,
  OrderDTOType,
  RefundModeEnumSchema,
  RefundModeType,
} from '@point-of-sale/schemas';

import KeyValuePair from '../KeyValuePair';
import Box from '../Box';
import { useOrderDetailsContext } from '../context';

import * as Styles from './styles';
import { useState } from 'react';

import { bulkCancelOrderItems, useAppDispatch } from '@point-of-sale/store';
import toast from 'react-hot-toast';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';

interface ICancellationDetailsProps {
  data: OrderDTOType;
  setPdfData: (data: Array<LineItemDTOType>) => void;
}

const CancellationDetails = ({
  data,
  setPdfData,
}: ICancellationDetailsProps) => {
  const { lineItemsSelection } = useOrderDetailsContext();

  const dispatch = useAppDispatch();

  const itemsToBeCancelled = Object.entries(lineItemsSelection)
    .filter(([id, isSelected]) => isSelected)
    .map(([id]) => id)
    .map(Number);

  const [refundMode, setRefundMode] = useState<RefundModeType | null>(
    RefundModeEnumSchema.Enum.STORE_CREDIT
  );

  const currency =
    CURRENCY_SYMBOLS[
      (data.costBreakup?.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR'
    ]?.code;

  const refundAmount = data.lineItems
    .filter(item => itemsToBeCancelled.includes(item.id))
    .reduce((acc, curr) => {
      if (!curr.costBreakup?.amountPaid) {
        return acc + 0;
      }

      return acc + curr.costBreakup?.amountPaid;
    }, 0);

  return (
    <Box header="Cancellation Details" hasPadding={false} flexOne>
      <Styles.Content>
        <Styles.Section>
          <KeyValuePair title="No of items" value={itemsToBeCancelled.length} />
        </Styles.Section>
        <Styles.Section $flexOne>
          <Text as="label" fontSize={14} weight="semibold" lineHeight={20}>
            Refund Mode
            <Text weight="semibold" color="var(--alizarin-crimson)">
              *
            </Text>
          </Text>

          {data.costBreakup?.amountPaid
            ? data.costBreakup?.amountPaid > 0 && (
                <RadioGroup
                  horizontal
                  name="refund-mode"
                  options={[
                    {
                      label: 'Store Credit',
                      value: RefundModeEnumSchema.Enum.STORE_CREDIT,
                    },
                    {
                      label: 'Cash',
                      value: RefundModeEnumSchema.Enum.CASH,
                    },
                  ]}
                  selectedValue={refundMode ?? ''}
                  onChange={selectedValue => {
                    setRefundMode(selectedValue as RefundModeType);
                  }}
                />
              )
            : null}
        </Styles.Section>
        {/* <Styles.Section>
          <KeyValuePair title="Store Credit For Size Exchange" value="price" />
        </Styles.Section> */}
        <Styles.Section>
          <KeyValuePair
            title="Refund Amount"
            value={`${currency} ${refundAmount}`}
          />
        </Styles.Section>
        <Styles.Section>
          <Button
            isFullWidth
            isDisabled={itemsToBeCancelled.length === 0}
            onClick={() => {
              if (!refundMode) {
                toast.error('Please select refund mode');
                return;
              }

              let payload = {} as BulkCancelOrderItemDTOType;

              if (data.costBreakup?.amountPaid === 0) {
                payload = {
                  ids: itemsToBeCancelled,
                  cancellationReason: 'Ordered by mistake',
                };
              } else {
                payload = {
                  ids: itemsToBeCancelled,
                  cancellationReason: 'Ordered by mistake',
                  refundMode: refundMode,
                };
              }

              dispatch(bulkCancelOrderItems(payload, setPdfData));
            }}
          >
            Cancel {itemsToBeCancelled.length} Selected Items
          </Button>
        </Styles.Section>
      </Styles.Content>
    </Box>
  );
};

export default CancellationDetails;
