import { z } from 'zod';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { apiResponseHandler } from '@point-of-sale/utils';
import { ThunkActionType } from '../store';
import { ApiResponseEnum } from '@point-of-sale/types';
import { setOpenCarts, setOpenCartsPagination } from './actions';
import { normalizeArray } from '../utils/normalizeArray';
import { CartDTOSchema, CartDTOType } from '@point-of-sale/schemas';
import { getOpenCartsApi } from './api';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import toast from 'react-hot-toast';

export const getOpenCarts =
  (): ThunkActionType => async (dispatch, getState) => {
    const { page, size, shouldFetchMore } = getState().openCarts.pagination;
    const search = getState().openCarts.search;

    if (!shouldFetchMore) {
      return;
    }

    dispatch(
      setOpenCarts({
        isLoading: true,
      })
    );

    const filterBuilder = new FilterBuilder();

    filterBuilder.addFilter({
      field: 'salesChannelId',
      filterType: 'EQ',
      value: getState().identity?.selectedSalesChannel?.id ?? '',
    });

    if (search.query.length > 0) {
      if (!Number.isNaN(Number(search.query))) {
        filterBuilder.addFilter({
          field: 'id',
          filterType: 'EQ',
          value: search.query,
        });
      } else {
        filterBuilder.addFilter({
          field: 'customerBillingName',
          filterType: 'ICONTAINS',
          value: search.query,
        });
      }
    }

    const newPage = page + 1;

    const promise = getOpenCartsApi({
      page: newPage,
      size: size,
      filters: filterBuilder.build(),
    });

    const response = await apiResponseHandler<Array<CartDTOType>>(
      promise,
      z.array(CartDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setOpenCartsPagination({
          shouldFetchMore: false,
        })
      );
      dispatch(
        setOpenCarts({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    const shouldAppend = newPage !== 1;

    let { ids, records } = normalizeArray(response.data);

    if (shouldAppend) {
      ids = [...getState().openCarts.carts.data.ids, ...ids];
      records = { ...getState().openCarts.carts.data.records, ...records };
    }

    dispatch(
      setOpenCarts({
        data: {
          ids: Array.from(new Set(ids)),
          records,
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    dispatch(
      setOpenCartsPagination({
        page: newPage,
        shouldFetchMore: response.data.length !== 0,
      })
    );
  };
