// eslint-disable-next-line @nx/enforce-module-boundaries
import { createDescribedState, omit } from '@point-of-sale/utils';
import { OMSActionsTypes } from './constants';
import { OMSActionsType, IOrderManagementSystemState } from './types';
import { STANDARD_PAGINATION_SIZE } from '../constants';
import { OrderDTOType } from '@point-of-sale/schemas';

const INITIAL_STATE: IOrderManagementSystemState = {
  search: {
    query: '',
  },

  filters: {},

  orders: createDescribedState({
    ids: [],
    records: {},
  }),

  pagination: {
    page: 0,
    size: STANDARD_PAGINATION_SIZE,
    shouldFetchMore: true,
  },

  details: createDescribedState({} as OrderDTOType),
} as const;

export const orderManagementSystemReducer = (
  state = INITIAL_STATE,
  action: OMSActionsType
): IOrderManagementSystemState => {
  switch (action.type) {
    case OMSActionsTypes.SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    }

    case OMSActionsTypes.SET_ORDERS: {
      return {
        ...state,
        orders: {
          ...state.orders,
          ...action.payload,
        },
      };
    }

    case OMSActionsTypes.SET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }

    case OMSActionsTypes.SET_ORDER_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
      };
    }

    case OMSActionsTypes.SET_LINE_ITEM_DETAILS_BY_ID: {
      const newLineItems = state.details.data.lineItems.map(lineItem => {
        if (String(lineItem.id) === action.payload.id) {
          return action.payload.data;
        }

        return lineItem;
      });

      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            lineItems: newLineItems,
          },
        },
      };
    }

    case OMSActionsTypes.UPDATE_FILTER: {
      if (action.payload.filter.value === '') {
        const newFilters = omit(state.filters, [action.payload.name]);

        return {
          ...state,
          filters: newFilters,
        };
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.filter,
        },
      };
    }

    case OMSActionsTypes.DELETE_FILTER: {
      const newFilters = omit(state.filters, [action.payload]);

      return {
        ...state,
        filters: newFilters,
      };
    }

    case OMSActionsTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
