import { useEffect, useState } from 'react';
import { useBoolean } from '@point-of-sale/hooks';
import {
  PinCodeDetailsDTOSchema,
  PinCodeDetailsDTOType,
} from '@point-of-sale/schemas';
import { getPinCodeDetailsApi } from '@point-of-sale/store';
import { apiResponseHandler } from '@point-of-sale/utils';
import { ApiResponseEnum } from '@point-of-sale/types';
import toast from 'react-hot-toast';

const INITIAL_STATE: PinCodeDetailsDTOType = {
  pincode: '',
  cityCode: '',
  cityDisplayName: '',
  stateCode: '',
  stateDisplayName: '',
  countryCode: '',
  countryDisplayName: '',
  lat: 0,
  lng: 0,
  localities: [],
  formattedAddress: '',
} as const;

const usePinCodeDetails = (pinCode?: string) => {
  const [isFetching, fetchingActions] = useBoolean();
  const [data, setData] = useState<PinCodeDetailsDTOType>(INITIAL_STATE);

  useEffect(() => {
    async function fetchServiceability() {
      if (!pinCode || pinCode.length < 6) {
        setData(INITIAL_STATE);
        return;
      }

      fetchingActions.on();

      const promise = getPinCodeDetailsApi(pinCode);

      const response = await apiResponseHandler<PinCodeDetailsDTOType>(
        promise,
        PinCodeDetailsDTOSchema
      );

      if (response.type === ApiResponseEnum.Failure) {
        toast.error(response.meta?.message ?? 'Something went wrong');
        fetchingActions.off();
        setData(INITIAL_STATE);
        return;
      }

      setData(response.data);
      fetchingActions.off();
    }

    fetchServiceability();

    return () => {
      setData(INITIAL_STATE);
    };
  }, [pinCode]);

  return { data, isFetching };
};

export default usePinCodeDetails;
