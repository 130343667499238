export function getDiscountPercentage(
  mrp: number,
  sellingPrice: number
): number {
  if (mrp <= 0 || sellingPrice < 0) {
    throw new Error('Invalid MRP or selling price');
  }

  const discount = ((mrp - sellingPrice) / mrp) * 100;

  return parseFloat(discount.toFixed(2));
}
