import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  LargeGreenCheckIcon,
  LargeRedExclamationIcon,
  Modal,
  SpinnerIcon,
  Text,
} from '@point-of-sale/components';
import {
  CartTransactionDTOSchema,
  CartTransactionDTOType,
} from '@point-of-sale/schemas';
import {
  getCartById,
  getCartTransactions,
  getTransactionApiStatusApi,
  setProcessedOrder,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { ApiResponseEnum } from '@point-of-sale/types';
import { apiResponseHandler } from '@point-of-sale/utils';
import { POLLING_INTERVAL, POLLING_LIMIT } from './constants';
import * as Styles from './styles';

type PaymentStatus = 'PENDING' | 'SUCCESS' | 'FAILED';

interface IPaymentStatusIndicatorProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId?: number;
}

const PaymentStatusIndicator = ({
  isOpen,
  onClose,
  transactionId,
}: IPaymentStatusIndicatorProps) => {
  const cartId = useAppSelector(store => store.pointOfSale.cart.data.id);

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('PENDING');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    let count = 1;

    const interval = setInterval(async () => {
      if (paymentStatus === 'SUCCESS') {
        clearInterval(interval);

        return;
      }

      if (!transactionId) {
        clearInterval(interval);

        onClose();
        return;
      }

      if (count >= POLLING_LIMIT) {
        setPaymentStatus('FAILED');
        clearInterval(interval);
        return;
      }

      setPaymentStatus('PENDING');
      const promise = getTransactionApiStatusApi(cartId, transactionId);
      const response = await apiResponseHandler<CartTransactionDTOType>(
        promise,
        CartTransactionDTOSchema
      );

      count++;

      if (response.type === ApiResponseEnum.Failure) {
        console.log(response.meta);
        toast.error(response.meta?.message ?? 'Something went wrong');
        setPaymentStatus('FAILED');
        clearInterval(interval);
        return;
      }

      if (response.data.status === 'VERIFIED') {
        setPaymentStatus('SUCCESS');
        clearInterval(interval);

        if (response.data.order) {
          clearInterval(interval);
          dispatch(
            setProcessedOrder({
              isLoading: false,
              isSuccess: true,
              isError: false,
              data: response.data.order,
            })
          );
        }

        dispatch(getCartTransactions(cartId));
        dispatch(getCartById(String(cartId)));
      }
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, cartId, transactionId]);

  useEffect(() => {
    return () => {
      setPaymentStatus('PENDING');
    };
  }, []);

  if (!transactionId) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} size="regular">
      <Styles.Wrapper>
        {paymentStatus === 'PENDING' && (
          <>
            <SpinnerIcon size={48} stroke="var(--tulip-tree)" />
            <Text
              fontSize={24}
              weight="bold"
              textAlign="center"
              color="var(--tulip-tree)"
            >
              Your payment is being verified
            </Text>
          </>
        )}
        {paymentStatus === 'SUCCESS' && (
          <>
            <LargeGreenCheckIcon />
            <Text
              fontSize={24}
              weight="bold"
              color="var(--chateau-green)"
              textAlign="center"
            >
              Your Payment is successful
            </Text>
            <Button
              colorScheme="dark"
              onClick={() => {
                setPaymentStatus('PENDING');
                onClose();
              }}
            >
              Close
            </Button>
          </>
        )}
        {paymentStatus === 'FAILED' && (
          <>
            <LargeRedExclamationIcon />
            <Text
              fontSize={24}
              textAlign="center"
              color="var(--alizarin-crimson)"
            >
              Your transaction failed
            </Text>
            <Button
              colorScheme="dark"
              onClick={() => {
                setPaymentStatus('PENDING');
                onClose();
              }}
            >
              Close
            </Button>
          </>
        )}
      </Styles.Wrapper>
    </Modal>
  );
};

export default PaymentStatusIndicator;
