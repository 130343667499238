// eslint-disable-next-line @nx/enforce-module-boundaries
import { callApi } from '@point-of-sale/store';

class AssetUploaderService {
  async uploadFile({
    file,
    onError,
    onSuccess,
    usage,
    source,
  }: {
    file: any;
    onError: (error: string) => void;
    onSuccess: (file: any) => void;
    usage:
      | 'CONFIG'
      | 'GRN'
      | 'VENDOR'
      | 'USER'
      | 'BANNER'
      | 'INVOICE'
      | 'STATIC'
      | 'MANIFEST'
      | 'PURCHASE_ORDER'
      | 'CUSTOM'
      | 'RECEIPT'
      | 'CATALOG'
      | 'PICKLIST'
      | 'ORDER'
      | 'CONTENT'
      | 'DC'
      | 'ICON';
    source?: 'ASSET' | 'BANNER' | 'SKU' | 'PRODUCT';
  }): Promise<any> {
    try {
      if (!source) {
        source = 'ASSET';
      }

      const uploadRequestInfo = await this.initFileUpload(file, usage, source);
      await this.uploadFileToCloudflare(uploadRequestInfo.url, file);
      const uploadedAssetInfo = await this.finishFileUpload(
        uploadRequestInfo.path
      );
      file.url = uploadedAssetInfo.url;
      file.id = uploadedAssetInfo.id;
      onSuccess(file);
    } catch (error: any) {
      onError(error.message);
    }
  }

  private async initFileUpload(
    file: any,
    usage: string,
    source?: string
  ): Promise<any> {
    const payload = {
      usage: usage,
      type: file.type,
      name: file.name,
      source: source ? source : 'ASSET',
    };
    const response = await callApi('POST', `/pos/asset/upload/init`, payload);

    return response.data;
  }

  private async uploadFileToCloudflare(url: string, file: any): Promise<any> {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
    return response;
  }

  private async finishFileUpload(path: string): Promise<any> {
    const payload = { path: path };
    const response = await callApi('POST', `/pos/asset/upload/finish`, payload);
    return response.data;
  }
}

export { AssetUploaderService };
