import BaseText from '../BaseText';
import { IBaseTextProps } from '../BaseText/types';

export interface ITextProps {
  children: IBaseTextProps['children'];
  color?: IBaseTextProps['color'];
  as?: IBaseTextProps['as'];
  textAlign?: IBaseTextProps['textAlign'];
  textDecorationLine?: IBaseTextProps['textDecorationLine'];
  wordBreak?: IBaseTextProps['wordBreak'];
  fontSize?: IBaseTextProps['fontSize'];
  /**
   * * regular: 400
   * * medium: 500
   * * semibold: 600
   * * bold: 700
   */
  weight?: IBaseTextProps['fontWeight'];
  display?: IBaseTextProps['display'];
  width?: IBaseTextProps['width'];
  textTransform?: IBaseTextProps['textTransform'];
  lineHeight?: IBaseTextProps['lineHeight'];
}

const Text = ({
  children,
  weight,
  display,
  width,
  as = 'p',
  ...props
}: ITextProps) => {
  return (
    <BaseText
      as={as}
      fontWeight={weight}
      display={display}
      width={width}
      {...props}
    >
      {children}
    </BaseText>
  );
};

export default Text;
