import { z } from 'zod';
import { CostBreakupDTOSchema } from '../common';
import { AddressDTOSchema } from '../customer';

export const CustomizationCategorySchema = z.union([
  z.literal('DRESS'),
  z.literal('TOP_WEAR'),
  z.literal('BOTTOM_WEAR'),
  z.literal('CO_ORD_SET'),
  z.literal('NONE'),
]);

export type CustomizationCategoryType = z.infer<
  typeof CustomizationCategorySchema
>;

export const DeliveryModeSchema = z.enum([
  'STORE_PURCHASE',
  'STORE_PICKUP',
  'HOME_DELIVERY',
]);

export const AlterationEntryDTOSchema = z.object({
  referenceColor: z.string(),
  referenceSize: z.string(),
  remarks: z.string().optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
  topwearAlterationEntry: z
    .object({
      shoulder: z.string().optional(),
      bust: z.string().optional(),
      armHole: z.string().optional(),
      waist: z.string().optional(),
      hips: z.string().optional(),
      sleeveLength: z.string().optional(),
      sleeveRound: z.string().optional(),
      dressLength: z.string().optional(),
    })
    .optional(),
  bottomwearAlterationEntry: z
    .object({
      waist: z.string().optional(),
      hips: z.string().optional(),
      thigh: z.string().optional(),
      pantLength: z.string().optional(),
    })
    .optional(),
});

export type AlterationEntryDTOType = z.infer<typeof AlterationEntryDTOSchema>;

export type DeliveryModeType = z.infer<typeof DeliveryModeSchema>;

export const FulfillmentModeSchema = z.enum(['ONHAND', 'JIT', 'MTO', 'MTC']);

export type FulfillmentModeType = z.infer<typeof FulfillmentModeSchema>;

export const AddCartItemDTOSchema = z.object({
  skuId: z.number(),
  skuCode: z.string(),
  quantity: z.number(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  fulfillmentFacilityId: z.number(),
  customerDeliveryStoreId: z.number().optional(),
  customerDeliveryAddressId: z.number().optional(),
  promiseDate: z.string().optional(),
  isExpress: z.boolean().optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  alterationEntry: AlterationEntryDTOSchema.optional(),
});

export type AddCartItemDTOType = z.infer<typeof AddCartItemDTOSchema>;

export const CartItemProductVariantDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  images: z
    .array(z.object({ url: z.string(), uploaded: z.boolean() }))
    .optional(),
  brandName: z.string(),
  productName: z.string(),
  components: z.string(),
  size: z.string(),
  color: z.string(),
  sellingPrice: z.number(),
  mrp: z.number(),
  option: z.object({ SIZE: z.string(), COLOR: z.string() }),
  skuId: z.number(),
  vendorId: z.number(),
  skuCode: z.string(),
  currency: z.string(),
  barcode: z.string(),
  taxClassPerc: z.number().optional(),
  exchangeable: z.boolean(),
  returnable: z.boolean(),
  vendorArticleNumber: z.string().optional(),
  enabled: z.boolean(),
  availableFacilities: z.array(z.string()).optional(),
  inStockFacilities: z.array(z.string()).optional(),
  facilityInventory: z
    .record(z.string(), z.object({ availableInventory: z.number() }))
    .optional(),
  inventorySummary: z.record(z.string(), z.number()).optional(),
  mtoAcceptedByBrand: z.boolean(),
  mtcAcceptedByBrand: z.boolean().optional(),
  mtoTAT: z.number().optional(),
  mtcTAT: z.number().optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
});

export const LineItemBrandJitFulfillmentModelEnumSchema = z.enum([
  'B2B',
  'B2B2C',
]);

export type LineItemBrandJitFulfillmentModelEnumType = z.infer<
  typeof LineItemBrandJitFulfillmentModelEnumSchema
>;

export const LineItemStatusEnumSchema = z.enum([
  'CREATED', // * show : Order confirmed
  'BRAND_WIP', // * show brand status in PB as described below
  'WIP', // * don't show -> show last brand status
  'PACKED', // * don't show -> show last brand status
  'DISPATCHED', // * show : Ready for store pickup || Shipped to customer :: based on delivery mode
  'READY_FOR_PICKUP', // * show : Ready for store pickup || Shipped to customer :: based on delivery mode
  'DELIVERED', // * show : Delivery Complete || Home Delivery Complete :: based on delivery mode
  'CANCELLED', // * show : Order Cancelled
  'RTO', // * don't show
  'RETURN_WIP', // * don't show
  'RETURNED', // * don't show
]);

export const LineItemBrandStatusEnumSchema = z.enum([
  'ADDED', // * don't show
  'INTERNAL_REVIEW_DONE', // * don't show
  'SHARED', // * show : PO sent to brand
  'ACCEPTED', // *  show : PO accepted by brand
  'DECLINED', // * show : PO rejected by brand
  'DISPATCHED', // * show : Dispatched
  'RECEIVED_AT_WAREHOUSE', // * don't show
  'QC_FAILED', // * show : QC failed
  'QC_PASSED', // * show : QC passed
]);

export type LineItemStatusEnumType = z.infer<typeof LineItemStatusEnumSchema>;

export type LineItemBrandStatusEnumType = z.infer<
  typeof LineItemBrandStatusEnumSchema
>;

export const LineItemHistorySchema = z.object({
  status: z.string(),
  eventDate: z.string(),
  brandStatus: z.string().optional(),
  remark: z.string().optional(),
  icon: z.string().optional(),
  actorId: z.number().optional(),
});

export type LineItemHistoryType = z.infer<typeof LineItemHistorySchema>;

export const LineItemDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  orderId: z.number(),
  cartItemId: z.number(),
  customerDeliveryAddress: z
    .intersection(z.object({}), AddressDTOSchema.optional())
    .optional(),
  customerDeliveryStoreId: z.number().optional(),
  isSameStorePurchase: z.boolean(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  fulfillmentFacilityId: z.number(),
  unicomFacilityCode: z.string().optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  skuCode: z.string(),
  productName: z.string(),
  productImage: z.object({ url: z.string(), uploaded: z.boolean() }).optional(),
  color: z.string(),
  size: z.string(),
  brandId: z.number(),
  vendorId: z.number(),
  brandName: z.string(),

  alterationEntry: AlterationEntryDTOSchema.optional(),

  returnable: z.boolean(),
  exchangeable: z.boolean(),
  cgstPerc: z.number().optional(),
  sgstPerc: z.number().optional(),
  costBreakup: CostBreakupDTOSchema,
  vendorDiscount: z.number(),
  platformDiscount: z.number(),
  onhold: z.boolean(),
  onholdReason: z.string().optional(),
  packageId: z.number().optional(),
  isExpress: z.boolean(),
  history: z.array(LineItemHistorySchema).optional(),

  customerName: z.string().optional(),
  customerPhone: z.string().optional(),
  salesStaffId: z.number().optional(),
  salesChannelOrderId: z.string().optional(),

  returnPolicy: z.string().optional(),
  washCareDescription: z.string().optional(),

  brandJitFulfillmentModel:
    LineItemBrandJitFulfillmentModelEnumSchema.optional(),

  actualDeliveryDate: z.string().optional(),
  estimatedDeliveryDate: z.string().optional(),

  cancellable: z.boolean().optional(),
  hsnCode: z.string().optional(),
  taxClassPerc: z.number().optional(),

  status: LineItemStatusEnumSchema.optional(),

  brandStatus: LineItemBrandStatusEnumSchema.optional(),

  currentlyExchangeable: z.boolean().optional(),
  currentlyReturnable: z.boolean().optional(),

  returnableUntil: z.string().optional(),
  exchangeableUntil: z.string().optional(),

  refunded: z.boolean().optional(),
  refundRequest: z
    .object({
      createdBy: z.string().optional(),
      createdOn: z.string().optional(),
      lastModifiedOn: z.string().optional(),
      version: z.number().optional(),
      id: z.number().optional(),
      status: z.string().optional(),
      orderId: z.number().optional(),
      salesChannelOrderId: z.string().optional(),
      orderLineItemIds: z.array(z.number()).optional(),
      refundMode: z.string().optional(),
      refundAmount: z.number().optional(),
      creditNoteNumber: z.string().optional(),
      payoutTxnId: z.string().optional(),
      storeCreditNumber: z.string().optional(),
      refundReceiptAsset: z
        .object({
          id: z.number(),
          url: z.string(),
          uploaded: z.boolean(),
        })
        .optional(),
    })
    .optional(),
  returnEntry: z
    .object({
      createdBy: z.string().optional(),
      createdOn: z.string().optional(),
      lastModifiedOn: z.string().optional(),
      version: z.number().optional(),
      id: z.number().optional(),
      orderId: z.number().optional(),
      returnMode: z.string().optional(),
      salesChannelId: z.number().optional(),
      refundRequestId: z.number().optional(),
      orderLineItemIds: z.array(z.number()).optional(),
      status: z.string().optional(),
      returnReason: z.string().optional(),
    })
    .optional(),

  refundRequestId: z.number().optional(),
  customizationCategory: CustomizationCategorySchema.optional(),
});

export type LineItemDTOType = z.infer<typeof LineItemDTOSchema>;

export const ServiceChargeDTOSchema = z.object({
  amount: z.union([z.string(), z.number()]),
  type: z.enum(['SHIPPING', 'BRAND_CUSTOMIZATION', 'ALTERATION']),
  remarks: z.string().optional(),
});

export type ServiceChargeDTOType = z.infer<typeof ServiceChargeDTOSchema>;

export const CustomChargeDTOSchema = z.array(ServiceChargeDTOSchema);

export type CustomChargeDTOType = z.infer<typeof CustomChargeDTOSchema>;

export const DiscountDTOSchema = z.object({
  couponCode: z.string(),
});

export type DiscountDTOType = z.infer<typeof DiscountDTOSchema>;

export const CartItemDTOSchema = z.object({
  id: z.number(),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  productVariant: CartItemProductVariantDTOSchema,
  quantity: z.number(),
  fulfillmentFacilityId: z.number(),
  deleted: z.boolean(),
  totalSellingPrice: z.number(),
  totalShippingCharge: z.number().optional(),
  totalCodCharge: z.number().optional(),
  enabled: z.boolean().optional(),
  oos: z.boolean(),
  serviceable: z.boolean(),
  totalBagDiscount: z.number().optional(),
  eligibleForComputation: z.boolean(),
  sellingPrice: z.number(),
  customerDeliveryStoreId: z.number().optional(),
  skuCode: z.string(),
  customerDeliveryAddressId: z.number().optional().nullable(),
  promiseDate: z.string().optional(),
  isExpress: z.boolean().optional(),
  alterationEntry: AlterationEntryDTOSchema.optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  customCharges: CustomChargeDTOSchema.optional(),
});

export type CartItemDTOType = z.infer<typeof CartItemDTOSchema>;

export const CartStatusSchema = z.enum([
  'ACTIVE',
  'PAYMENT_PROCESSING',
  'ORDER_PLACED',
  'EXPIRED',
  'DISCARDED',
]);

export type CartStatusType = z.infer<typeof CartStatusSchema>;

export const CartDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  salesChannelId: z.number().optional(),
  salesChannelTerminalId: z.number(),
  shippingCharge: z.number().optional(),
  codCharge: z.number().optional(),
  bagDiscount: z.number().optional(),
  cartItems: z.array(CartItemDTOSchema),
  customerId: z.number().optional(),
  chargeableAmount: z.number().optional(),
  amountPaid: z.number().optional(),
  minAmountToBePaid: z.number().optional(),
  customCharges: CustomChargeDTOSchema.optional(),
  salesStaffId: z.number().optional(),
  status: CartStatusSchema.optional(),
  currency: z.string().optional(),
  salesFacilityId: z.number().optional(),
  customerPincode: z.string().optional(),
  couponEnabled: z.boolean(),
  couponCode: z.string().optional(),
  couponDiscount: z.number().optional(),
  customerBillingName: z.string().optional(),
  customerPhone: z.string().optional(),
  customerBillingAddressId: z.string().optional(),
  customerGSTIN: z.string().optional(),
  couponErrorMessage: z.string().optional(),
});

export type CartDTOType = z.infer<typeof CartDTOSchema>;
//
