import { Context, createContext, useContext } from 'react';

import { CostBreakupDTOType } from '@point-of-sale/schemas';

export interface IPaymentCollectionContext {
  packageId: number;
  salesChannelOrderId: string;
  lineItemIds: Array<number>;
  costBreakup: CostBreakupDTOType;
}

export const PaymentCollectionContext: Context<IPaymentCollectionContext> =
  createContext({} as IPaymentCollectionContext);

export function usePaymentCollectionContext() {
  const contextData = useContext(PaymentCollectionContext);
  return contextData;
}
