// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
import { callApi } from '../services/callApi';
import {
  AddCartItemDTOType,
  AddressDTOType,
  CartDTOType,
  CustomerDTOType,
  FacilityDTOType,
} from '@point-of-sale/schemas';
import { ObjectType } from '@point-of-sale/types';

export const productSearchApi = ({
  page = 1,
  size = 10,
  filters = [],
}: {
  page: number;
  size: number;
  filters: Array<IFilter>;
}) =>
  callApi('POST', `/pos/product/search?page=${page}&size=${size}`, {
    filters,
  });

export const customerSearchApi = ({
  filters = [],
}: {
  filters: Array<IFilter>;
}) =>
  callApi('POST', `/pos/identity/customer/search`, {
    filters,
  });

export const initializeCartApi = (data: Partial<CartDTOType>) =>
  callApi('PUT', `/pos/cart/init`, data);

export const clearCartApi = (cartId: string) =>
  callApi('PUT', `/pos/cart/${cartId}/clear`, {});

export const getCartByIdApi = (cartId: string) =>
  callApi('GET', `/pos/cart/${cartId}`);

export const addItemToCartApi = ({
  id,
  item,
  customerId,
}: {
  id: number;
  item: AddCartItemDTOType;
  customerId?: number;
}) =>
  callApi('PUT', `/pos/cart/add`, {
    id,
    cartItems: [item],
    ...(customerId ? { customerId } : {}),
  });

export const addUserToCartApi = ({
  userId,
  cartId,
  customerBillingName,
  customerPhone,
}: {
  userId: number;
  cartId: string;
  customerBillingName: string;
  customerPhone: string;
}) =>
  callApi('PUT', `/pos/cart/${cartId}/customer/${userId}/add`, {
    customerBillingName,
    customerPhone,
  });

export const updateCartApi = ({
  cartId,
  cartItemId,
  quantity,
  operation,
}:
  | {
      cartId: number;
      cartItemId: number;
      quantity?: undefined;
      operation: 'REMOVE' | 'SELECT' | 'DESELECT';
    }
  | {
      cartId: number;
      cartItemId: number;
      quantity: number;
      operation: 'UPDATE_QUANTITY';
    }) =>
  quantity
    ? callApi('PUT', `/pos/cart/${cartId}/item/${cartItemId}/${operation}`, {
        quantity,
      })
    : callApi('PUT', `/pos/cart/${cartId}/item/${cartItemId}/${operation}`, {});

// TODO: merge this with the above function
export const updateCartItemSkuApi = ({
  cartId,
  cartItemId,
  data,
}: {
  cartId: number;
  cartItemId: number;
  data: ObjectType;
}) =>
  callApi('PUT', `/pos/cart/${cartId}/item/${cartItemId}/REPLACE_SKU`, data);

export const addPersonalizationToCartItemApi = ({
  cartItemId,
  quantity,
}: {
  cartItemId: number;
  quantity: number;
}) =>
  callApi('PUT', `/pos/cart/item/${cartItemId}/UPDATE_QUANTITY`, {
    quantity,
  });

export const getServiceabilityApi = ({
  skuCodes,
  destinationPincodes,
  sourceFacilities,
}: {
  skuCodes: Array<string>;
  destinationPincodes: Array<string>;
  sourceFacilities: Array<FacilityDTOType>;
}) =>
  callApi('POST', `/pos/serviceability`, {
    skuCodes,
    destinationPincodes,
    sourceFacilities,
  });

export const upsertPersonalizationApi = ({
  cartId,
  itemId,
  data,
}: {
  cartId: number;
  itemId: number;
  data: ObjectType;
}) => callApi('PUT', `/pos/cart/${cartId}/item/${itemId}/alteration`, data);

export const cartRefreshApi = (data: CartDTOType) =>
  callApi('PUT', `/pos/cart/refresh`, data);

export const getPinCodeDetailsApi = (pinCode: string) =>
  callApi('GET', `/pos/pincode/${pinCode}`);

export const addressSearchApi = (customerId: number) =>
  callApi('POST', `/pos/address/search?page=1&size=10&sort=isDefault,asc`, {
    filters: [
      { filterType: 'EQ', field: 'customerId', value: customerId },
      // {"filterType": "EQ", "field": "deleted", "value": "false"}
    ],
    orderBy: 'lastModifiedOn',
    orderDirection: 'DESC',
  });

export const addAddressApi = (address: Partial<AddressDTOType>) =>
  callApi('POST', `/pos/address/`, address);

export const updateAddressApi = (
  addressId: number,
  address: Partial<AddressDTOType>
) => callApi('PUT', `/pos/address/${addressId}`, address);

export const createCustomerApi = (data: ObjectType) =>
  callApi('POST', `/pos/identity/customer/`, data);

export const updateCustomerApi = ({
  customerId,
  data,
}: {
  customerId: number;
  data: Partial<CustomerDTOType>;
}) => callApi('PUT', `/pos/identity/customer/${customerId}`, data);

export const addInvoiceToOrderApi = ({
  orderId,
  asset,
}: {
  orderId: number;
  asset: {
    id: number;
    url: string;
  };
}) => callApi('PUT', `/pos/order/${orderId}/invoice-receipt`, asset);

export const getGstDetailsApi = (gstNumber: string) =>
  callApi('POST', `/pos/gstin/`, {
    gstin: gstNumber,
  });
