// eslint-disable-next-line @nx/enforce-module-boundaries
import { createDescribedState } from '@point-of-sale/utils';
import { CommonActionsTypes } from './constants';
import { CommonActionsType, ICommonState } from './types';

const INITIAL_STATE: ICommonState = {
  storeStaff: createDescribedState({
    ids: [],
    records: {},
  }),
  salesChannels: createDescribedState({
    ids: [],
    records: {},
  }),
  facilities: createDescribedState({
    ids: [],
    records: {},
  }),
  analyticsDashboard: createDescribedState({
    url: '',
  }),
} as const;

export const commonReducer = (
  state = INITIAL_STATE,
  action: CommonActionsType
): ICommonState => {
  switch (action.type) {
    case CommonActionsTypes.SET_STORE_STAFF: {
      return {
        ...state,
        storeStaff: {
          ...state.storeStaff,
          ...action.payload,
        },
      };
    }

    case CommonActionsTypes.SET_SALES_CHANNELS: {
      return {
        ...state,
        salesChannels: {
          ...state.salesChannels,
          ...action.payload,
        },
      };
    }
    case CommonActionsTypes.SET_FACILITIES: {
      return {
        ...state,
        facilities: {
          ...state.facilities,
          ...action.payload,
        },
      };
    }

    case CommonActionsTypes.SET_ANALYTICS_DASHBOARD: {
      return {
        ...state,
        analyticsDashboard: {
          ...state.analyticsDashboard,
          ...action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
