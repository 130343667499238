import { useEffect } from 'react';
import { Text } from '@point-of-sale/components';
import {
  getAnalyticsDashboardUrl,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import * as Styles from './styles';

function getTimeOfDay(date = new Date()) {
  const currentHour = date.getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Afternoon';
  } else if (currentHour >= 17 && currentHour < 21) {
    return 'Evening';
  } else {
    return 'Evening';
  }
}

const Home = () => {
  const selectedSalesChannel = useAppSelector(
    store => store.identity.selectedSalesChannel
  );
  const analyticsDashboard = useAppSelector(
    store => store.common.analyticsDashboard
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAnalyticsDashboardUrl());
  }, []);

  return (
    <Styles.Wrapper>
      <Text fontSize={28}>
        Good {getTimeOfDay()}, {selectedSalesChannel?.name}
      </Text>
      {analyticsDashboard.data.url && (
        <Styles.IframeContainer>
          <iframe
            title={selectedSalesChannel?.name}
            src={analyticsDashboard.data.url}
            height="100%"
            width="100%"
            // Date.now for cache busting in iframe ref: https://stackoverflow.com/a/26191196/8589359
            name={`${Date.now()}`}
          />
        </Styles.IframeContainer>
      )}
    </Styles.Wrapper>
  );
};

export default Home;
