import { ReactNode } from 'react';
import * as Styles from './styles';
import { Text } from '@point-of-sale/components';

interface IBoxProps {
  header: string;
  children: ReactNode;
  hasPadding?: boolean;
  flexOne?: boolean;
}

const Box = ({
  header,
  children,
  hasPadding = true,
  flexOne = false,
}: IBoxProps) => (
  <Styles.Wrapper $flexOne={flexOne}>
    <Styles.Header>
      <Text fontSize={16} weight="semibold" color="var(--cod-gray)">
        {header}
      </Text>
    </Styles.Header>
    <Styles.Content $flexOne={flexOne} $hasPadding={hasPadding}>
      {children}
    </Styles.Content>
  </Styles.Wrapper>
);

export default Box;
