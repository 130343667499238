import { z } from 'zod';

import {
  APIResponseType,
  ApiResponseEnum,
  ApiResponseHandlerType,
} from '@point-of-sale/types';
import { ENV } from '@point-of-sale/env';
import toast from 'react-hot-toast';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SentryService } from '@point-of-sale/services';

export async function apiResponseHandler<T>(
  apiPromise: Promise<APIResponseType<T>>,
  schema?: z.ZodType<T>
): Promise<ApiResponseHandlerType<T>> {
  try {
    const response = await apiPromise;

    if (response?.success) {
      return {
        type: ApiResponseEnum.Success,
        meta: response.status,
        data: schema && ENV.DEV ? schema.parse(response.data) : response.data,
      };
    }

    SentryService.catchError(new Error("API didn't return success"));

    return {
      type: ApiResponseEnum.Failure,
      meta: response.status,
      source: 'response',
    };
  } catch (error) {
    if (ENV.DEV) {
      if (error instanceof z.ZodError) {
        SentryService.catchError(error);
        toast.error('API CONTRACT BREACH');
        console.group('API CONTRACT BREACH');
        console.trace(error.issues);
        console.groupEnd();
      } else {
        console.log('NETWORK ERROR');
        console.log(error);
      }
    }

    SentryService.catchError(error as Error);

    return {
      type: ApiResponseEnum.Failure,
      error: error as Error,
      source: 'network',
    };
  }
}
