import { IconPropsType } from '../types';

const EditIcon = ({
  stroke = 'var(--cod-gray)',
  size = 24,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.624 4.25 2.125 2.125M3.54 11.333l-.708 2.834 2.833-.709 8.207-8.206a1.417 1.417 0 0 0 0-2.003l-.122-.122a1.417 1.417 0 0 0-2.003 0z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default EditIcon;
