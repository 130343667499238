import React, { useEffect, useState } from 'react';
// import Skeleton from '../Skeleton';
import { NoImageIcon } from '../icons';
import * as Styles from './styles';

type ImagePropsType = React.ImgHTMLAttributes<HTMLImageElement> & {
  width: string | number;
  height: string | number;
  duration?: number;
  iconSize?: number;
};

const Image = ({
  width,
  height,
  src,
  duration = 0.2,
  iconSize = 24,
  ...props
}: ImagePropsType) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = () => {
    setHasError(true);
    // setIsLoading(false);
  };

  const handleLoad = () => {
    // setIsLoading(false);
  };

  useEffect(() => {
    // setIsLoading(true);
    // setHasError(false);
  }, [src]);

  return (
    <Styles.MotionImageWrapper
      initial={{ width, height }}
      animate={{ width, height }}
      transition={{ duration: duration }}
    >
      {/* {src && isLoading && <Skeleton height={height} width={width} />} */}
      {hasError || !src ? (
        <Styles.IconWrapper
          initial={{ width, height }}
          animate={{ width, height }}
          style={{ height, width }}
          transition={{ duration: duration }}
        >
          <NoImageIcon size={iconSize} />
        </Styles.IconWrapper>
      ) : (
        // Use key based on src to force re-render when src changes
        <Styles.Image
          {...props}
          src={src}
          onError={handleError}
          // onLoad={handleLoad}
          $isLoading={false}
        />
      )}
    </Styles.MotionImageWrapper>
  );
};

export default Image;
