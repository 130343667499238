import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-color: var(--alabaster);
  }
  100% {
    background-color: rgba(232, 234, 239, 0.7);
  }
`;

export const StyledSkeleton = styled.div<{
  height: string | number;
  width: string | number;
  border?: string | number;
  marginBottom?: string | number;
  marginRight?: string | number;
}>`
  display: inline-block;
  height: ${props => props.height}px;
  width: ${props => `${props.width}`}px;
  border-radius: ${props => props.border ?? 1}px;
  margin-bottom: ${props => props.marginBottom ?? 0}px;
  margin-right: ${props => props.marginRight ?? 0}px;
  animation: ${shimmer} 1s linear infinite alternate;
`;

interface IBoneProps {
  height: string | number;
  width: string | number;
  border?: string | number;
  marginBottom?: string | number;
  marginRight?: string | number;
}

const Skeleton = ({
  height,
  width,
  border,
  marginBottom,
  marginRight,
}: IBoneProps) => (
  <StyledSkeleton
    height={height}
    width={width}
    border={border}
    marginBottom={marginBottom}
    marginRight={marginRight}
  />
);

export default Skeleton;
