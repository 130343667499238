import type { CheckoutActionsType, ICheckoutState } from './types';
import { CheckoutActionsTypes } from './constants';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { createDescribedState } from '@point-of-sale/utils';
import {
  CardMetaDataDTOType,
  OrderDTOType,
  UpiMetaDataDTOType,
} from '@point-of-sale/schemas';

const INITIAL_STATE: ICheckoutState = {
  salesPoc: null,
  paymentOptions: createDescribedState({
    ids: [],
    records: {},
  }),
  paymentTerminals: createDescribedState({
    ids: [],
    records: {},
  }),
  cartTransactions: createDescribedState({
    ids: [],
    records: {},
  }),
  cartMinAmountToBePaid: 0,
  cartTotalAmountToBePaid: 0,
  cartTotalAmountPaid: 0,

  processedOrder: createDescribedState({} as OrderDTOType),

  upiMetadata: createDescribedState({} as UpiMetaDataDTOType),
  cardMetadata: createDescribedState({} as CardMetaDataDTOType),
} as const;

export const checkoutReducer = (
  state = INITIAL_STATE,
  action: CheckoutActionsType
): ICheckoutState => {
  switch (action.type) {
    case CheckoutActionsTypes.SET_SALES_POC: {
      return {
        ...state,
        salesPoc: action.payload,
      };
    }

    case CheckoutActionsTypes.SET_PAYMENT_OPTIONS: {
      return {
        ...state,
        paymentOptions: {
          ...state.paymentOptions,
          ...action.payload,
        },
      };
    }

    case CheckoutActionsTypes.SET_CART_TRANSACTIONS: {
      return {
        ...state,
        cartTransactions: {
          ...state.cartTransactions,
          ...action.payload,
        },
      };
    }

    case CheckoutActionsTypes.SET_CART_MIN_AMOUNT_TO_BE_PAID: {
      return {
        ...state,
        cartMinAmountToBePaid: action.payload,
      };
    }

    case CheckoutActionsTypes.SET_CART_TOTAL_AMOUNT_PAID: {
      return {
        ...state,
        cartTotalAmountPaid: action.payload,
      };
    }

    case CheckoutActionsTypes.SET_CART_TOTAL_AMOUNT_TO_BE_PAID: {
      return {
        ...state,
        cartTotalAmountToBePaid: action.payload,
      };
    }

    case CheckoutActionsTypes.SET_PROCESSED_ORDER: {
      return {
        ...state,
        processedOrder: {
          ...state.processedOrder,
          ...action.payload,
        },
      };
    }
    case CheckoutActionsTypes.RESET: {
      return INITIAL_STATE;
    }

    default: {
      return state;
    }
  }
};
