import { Text } from '@point-of-sale/components';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import {
  formatNumberToLocale,
  getDiscountPercentage,
} from '@point-of-sale/utils';
import useProductContext from '../context/useProductContext';
import styled from 'styled-components';
import { IProductListItem } from '@point-of-sale/store';

const PriceText = styled.div`
  width: unset;
  /* margin-bottom: 4px; */

  display: flex;
  flex-direction: column;
  align-items: end;
  flex-wrap: wrap;

  p,
  span {
    text-align: right;
    white-space: nowrap;
  }
`;

interface IPriceProps {
  isExpanded: boolean;
  data: IProductListItem;
  selectedVariant: IProductListItem['variants'][number] | null;
}

const Price = ({ isExpanded, data, selectedVariant }: IPriceProps) => {
  const prices = data.variants.map(variant => ({
    sellingPrice: variant.sellingPrice,
    currency: variant.currency,
    mrp: variant.mrp,
  }));

  const determinedVariant = selectedVariant
    ? { sellingPrice: selectedVariant.sellingPrice, mrp: selectedVariant.mrp }
    : prices.reduce((minVariant, currentVariant) =>
        currentVariant.sellingPrice < minVariant.sellingPrice
          ? currentVariant
          : minVariant
      );

  // * This is done assuming that the currency will be the same for all the variants.
  const currency = selectedVariant?.currency || prices[0].currency;

  const mrp = determinedVariant.mrp;
  const sellingPrice = determinedVariant.sellingPrice;
  const discount = getDiscountPercentage(mrp, sellingPrice);
  const currencySymbol =
    CURRENCY_SYMBOLS[(currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']
      ?.code;

  return (
    <PriceText>
      {!selectedVariant && (
        <Text as="span" fontSize={12} color="var(--dove-gray)">
          Starting at
          <br />
        </Text>
      )}
      {discount > 0 && (
        <Text
          as="span"
          fontSize={isExpanded ? 14 : 10}
          weight="semibold"
          color="var(--persian-red)"
        >
          {`${discount}% off`}
          &nbsp;&nbsp;
          <Text
            as="span"
            fontSize={isExpanded ? 16 : 12}
            color="var(--alto)"
            textDecorationLine="line-through"
          >
            {currencySymbol}
            &nbsp;
            {formatNumberToLocale(determinedVariant.mrp)}
          </Text>{' '}
        </Text>
      )}
      <Text
        fontSize={isExpanded ? 24 : 14}
        lineHeight={isExpanded ? 32 : 20}
        textAlign="right"
        weight={isExpanded ? 'bold' : 'semibold'}
        color="var(--cod-gray)"
      >
        {currencySymbol}
        &nbsp;
        {formatNumberToLocale(determinedVariant.sellingPrice)}
      </Text>
    </PriceText>
  );
};

export default Price;
