/**
 * Returns the initials of a name.
 * @param name - The name to get the initials from.
 * @returns The initials of the name.
 */
export const getNameInitials = (name = '') => {
  name = name ? name.trim() : '';
  const parts = name.split(' ');
  let initials = '';

  if (name.length === 0) {
    initials = 'AU';
  } else {
    parts.length > 1
      ? (initials =
          parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase())
      : (initials = parts[0][0].toUpperCase());
  }
  return initials;
};

/**
 * Converts a string to title case.
 * @param string - The string to convert.
 * @returns The title case version of the string.
 */
export const titleCase = (string = '') => {
  const newString = string.toLowerCase().split(' ');
  if (newString.length) {
    return newString
      .map(function (word) {
        if (!word) return '';
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }
  return '';
};

/**
 * Removes underscores from a string and capitalizes the first letter of each word.
 * @param str - The string to process.
 * @returns The processed string.
 */
export const removeUnderscoresAndCapitalize = (str: string | number) =>
  titleCase(String(str)).replaceAll('_', ' ');

/**
 * Concatenates an array of strings with commas.
 * @param strings - The array of strings to concatenate.
 * @returns The concatenated string with commas.
 */
export function concatenateWithCommas(
  strings: Array<string | undefined>
): string {
  // Filter out empty values
  const filteredStrings = strings
    .filter(Boolean)
    .filter(str => str?.trim() !== '');

  // Join the remaining strings with commas
  return filteredStrings.join(', ');
}

export function truncate(
  value: string,
  maxLength: number,
  suffix = '...'
): string {
  if (value.length <= maxLength) {
    return value;
  }

  return `${value.substring(0, maxLength)}${suffix}`;
}

export function unCamelCase(str: string): string {
  return (
    str
      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export function convertToNumber(str: string): number {
  // Remove commas from the string
  const cleanedStr = str.replace(/,/g, '');

  // Convert the cleaned string to a number
  const number = parseFloat(cleanedStr);

  return number;
}
