import { CommonActionsTypes } from './constants';
import { ICommonState } from './types';

export const setStoreStaff = (
  payload: Partial<ICommonState['storeStaff']>
) => ({
  type: CommonActionsTypes.SET_STORE_STAFF,
  payload,
});

export const setSalesChannels = (
  payload: Partial<ICommonState['salesChannels']>
) => ({
  type: CommonActionsTypes.SET_SALES_CHANNELS,
  payload,
});

export const setFacilities = (
  payload: Partial<ICommonState['facilities']>
) => ({
  type: CommonActionsTypes.SET_FACILITIES,
  payload,
});

export const setAnalyticsDashboard = (
  payload: Partial<ICommonState['analyticsDashboard']>
) => ({
  type: CommonActionsTypes.SET_ANALYTICS_DASHBOARD,
  payload,
});
