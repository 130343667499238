import { ThemeType } from '../themes/types';
import { FontSize } from '../tokens/typography';
import { InputPropertyType } from './types';

export const INPUT_HEIGHTS: InputPropertyType<number> = {
  extraSmall: 30,
  small: 45,
  medium: 59,
  xl: 80,
} as const;

export const FONT_SIZES: InputPropertyType<keyof FontSize> = {
  extraSmall: 12,
  small: 14,
  medium: 16,
  xl: 24,
} as const;

export const FONT_WEIGHTS: InputPropertyType<
  keyof ThemeType['typography']['fonts']['weight']
> = {
  extraSmall: 'regular',
  small: 'regular',
  medium: 'regular',
  xl: 'bold',
} as const;

export const ICON_PADDINGS: InputPropertyType<string> = {
  extraSmall: '4px 0 4px 4px',
  small: '12px 0 12px 16px',
  medium: '20px 0 20px 20px',
  xl: '20px 0 20px 20px',
} as const;

export const ICON_DIMENSIONS: InputPropertyType<number> = {
  extraSmall: 16,
  small: 24,
  medium: 24,
  xl: 24,
} as const;
