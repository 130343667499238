import { Input } from '@point-of-sale/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 16px;
  height: calc(100vh - 52px);

  display: flex;
  flex-direction: column;
  gap: 12px;

  overflow: hidden;
`;

export const Search = styled(Input)``;

export const Content = styled.div`
  overflow: hidden;

  display: flex;
  gap: 12px;

  flex: 1;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;

  border-radius: 8px;

  height: 100%;
  overflow: auto;
`;

export const Right = styled.div`
  width: 40%;

  flex: 1;
  border-radius: 8px;
`;
