import { useEffect } from 'react';
import { EmptyCart } from '@runway/illustrations';
import { Button, Skeleton, Text } from '@point-of-sale/components';
import {
  clearCartById,
  getCurrentStoreAndWarehouseFacilityIds,
  updateCartItemEditCache,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { formatNumberToLocale, openInSameTab } from '@point-of-sale/utils';
import CartItem from './CartItem';
import CheckoutButton from './CheckoutButton';
import useGetServiceability from '../Product/useGetServiceability';
import { areAllValuesEmptyStrings } from './helpers';
import RecoverCartButton from './RecoverCartButton';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import { CartUsageType } from './types';
import * as Styles from './styles';

interface ICartProps {
  usage: CartUsageType;
}

const Cart = ({ usage }: ICartProps) => {
  const mode = useAppSelector(state => state.pointOfSale.mode);

  const facilityRecords = useAppSelector(
    state => state.common.facilities.data.records
  );

  const isCartLoading = useAppSelector(
    state => state.pointOfSale.cart.isLoading
  );
  const cartId = useAppSelector(state => state.pointOfSale.cart.data.id);
  const cartCurrency = useAppSelector(
    store => store.pointOfSale.cart.data.currency
  );

  const customerAddress = useAppSelector(
    store =>
      store.pointOfSale.addresses.data.records[
        store.pointOfSale.selectedAddressId
      ]
  );

  const allCartItemIds = useAppSelector(
    state => state.pointOfSale.cart.data.cartItems.ids
  );
  const cartItemsRecords = useAppSelector(
    state => state.pointOfSale.cart.data.cartItems.records
  );
  const chargeableAmount = useAppSelector(
    state => state.pointOfSale.cart.data.chargeableAmount
  );

  const { currentStoreAndWarehousesFacilityIds } =
    getCurrentStoreAndWarehouseFacilityIds();

  const storePurchaseItemsWithoutAlteration = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(
      id =>
        state.pointOfSale.cart.data.cartItems.records[id].deliveryMode ===
          'STORE_PURCHASE' &&
        state.pointOfSale.cart.data.cartItems.records[id].fulfillmentMode ===
          'ONHAND'
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const storePurchaseItemsWithoutAlterationQuantity =
    storePurchaseItemsWithoutAlteration.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);

  const storePurchaseItemsWithAlteration = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(
      id =>
        currentStoreAndWarehousesFacilityIds.includes(
          state.pointOfSale.cart.data.cartItems.records[id]
            .fulfillmentFacilityId
        ) && !areAllValuesEmptyStrings(cartItemsRecords[id].alterationEntry)
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const storePurchaseItemsWithAlterationQuantity =
    storePurchaseItemsWithAlteration.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);

  const readyMadeItemsFromOtherStoreOrWarehouse = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(
      id =>
        state.pointOfSale.cart.data.cartItems.records[id].deliveryMode !==
          'STORE_PURCHASE' &&
        cartItemsRecords[id].fulfillmentMode === 'ONHAND' &&
        !currentStoreAndWarehousesFacilityIds.includes(
          state.pointOfSale.cart.data.cartItems.records[id]
            .fulfillmentFacilityId
        )
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const readyMadeItemsFromOtherStoreOrWarehouseQuantity =
    readyMadeItemsFromOtherStoreOrWarehouse.reduce((acc, id) => {
      return acc + cartItemsRecords[id].quantity;
    }, 0);

  const jitOrMtoOrMtcItems = useAppSelector(state =>
    state.pointOfSale.cart.data.cartItems.ids.filter(id =>
      ['JIT', 'MTO', 'MTC'].includes(
        state.pointOfSale.cart.data.cartItems.records[id].fulfillmentMode
      )
    )
  ).sort((a, b) => (a > b ? 1 : -1));

  const jitOrMtoOrMtcItemsQuantity = jitOrMtoOrMtcItems.reduce((acc, id) => {
    return acc + cartItemsRecords[id].quantity;
  }, 0);

  const dispatch = useAppDispatch();

  const { serviceabilityData, fetchServiceabilityData } =
    useGetServiceability();

  // TODO: Revisit this
  useEffect(() => {
    if (usage !== 'ADD_PRODUCTS') {
      return;
    }

    if (allCartItemIds.length > 0) {
      const skuCodes = Object.values(cartItemsRecords).map(
        item => item.skuCode
      );
      const destinationPincodes = Object.values(facilityRecords)
        .map(facility => {
          if (facility.facilityType !== 'STORE') {
            return null;
          }

          return facility.address.pincode;
        })
        .filter(item => item !== null);

      if (customerAddress) {
        destinationPincodes.push(customerAddress.pincode);
      }

      fetchServiceabilityData(
        skuCodes,
        // @ts-expect-error destinationPincodes will not have undefined
        Array.from(new Set(destinationPincodes)),
        Object.values(facilityRecords)
      );
    }
  }, [allCartItemIds, customerAddress]);

  const itemCount = allCartItemIds.reduce((acc, id) => {
    return acc + cartItemsRecords[id].quantity;
  }, 0);

  const currency =
    CURRENCY_SYMBOLS[(cartCurrency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR']
      ?.code;

  return (
    <Styles.Wrapper>
      <Styles.Header>
        <Styles.HeaderLeft>
          {isCartLoading ? (
            <>
              <Skeleton height={18} width={200} />
              <Skeleton height={22} width={250} />
            </>
          ) : (
            <>
              <Text fontSize={14} color="var(--dove-gray)">
                {cartId === -1 ? <>...</> : <>Cart #{cartId}</>}
              </Text>
              <Text fontSize={16} color="var(--cod-gray)" weight="semibold">
                Total Cart Value {currency}{' '}
                {formatNumberToLocale(chargeableAmount ?? 0)}
              </Text>
            </>
          )}
        </Styles.HeaderLeft>
        <Styles.HeaderRight>
          {usage === 'ADD_PRODUCTS' && (
            <Button
              onClick={() => {
                dispatch(clearCartById(String(cartId)));
                dispatch(
                  updateCartItemEditCache({
                    id: -1,
                    isPopulated: false,
                  })
                );
              }}
              variant="ghost"
              colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
              sizeVariant="xs"
            >
              CLEAR
            </Button>
          )}

          {usage === 'CHECKOUT' && (
            <Button
              variant="ghost"
              sizeVariant="xs"
              onClick={() => {
                openInSameTab(`/point-of-sale/cart?id=${cartId}`);
              }}
            >
              ADD MORE
            </Button>
          )}

          <Text fontSize={14} weight="bold" color="var(--dove-gray)">
            No of Items: {itemCount}
          </Text>
        </Styles.HeaderRight>
      </Styles.Header>
      <Styles.Content>
        {allCartItemIds.length === 0 && (
          <EmptyCart
            text={
              usage === 'RECOVER'
                ? cartId === -1
                  ? 'Please Select a cart'
                  : 'Cart has no items'
                : 'Your cart is empty'
            }
          />
        )}

        {storePurchaseItemsWithoutAlteration.length > 0 && (
          <Styles.CartSection>
            <Styles.CartSectionHeading fontSize={14} weight="semibold">
              Store Purchase Items (
              {storePurchaseItemsWithoutAlterationQuantity})
            </Styles.CartSectionHeading>
            {storePurchaseItemsWithoutAlteration.map(id => (
              <CartItem
                key={id}
                data={cartItemsRecords[id]}
                currency={cartCurrency ?? ''}
                serviceabilityData={
                  serviceabilityData[cartItemsRecords[id].skuCode]
                }
                customerAddress={customerAddress}
                usage={usage}
              />
            ))}
          </Styles.CartSection>
        )}
        {storePurchaseItemsWithAlteration.length > 0 && (
          <Styles.CartSection>
            <Styles.CartSectionHeading fontSize={14} weight="semibold">
              Store Purchase Items With Alterations (
              {storePurchaseItemsWithAlterationQuantity})
            </Styles.CartSectionHeading>
            {storePurchaseItemsWithAlteration.map(id => (
              <CartItem
                key={id}
                data={cartItemsRecords[id]}
                currency={cartCurrency ?? ''}
                serviceabilityData={
                  serviceabilityData[cartItemsRecords[id].skuCode]
                }
                customerAddress={customerAddress}
                usage={usage}
              />
            ))}
          </Styles.CartSection>
        )}
        {readyMadeItemsFromOtherStoreOrWarehouse.length > 0 && (
          <Styles.CartSection>
            <Styles.CartSectionHeading fontSize={14} weight="semibold">
              Other Locations ({readyMadeItemsFromOtherStoreOrWarehouseQuantity}
              )
            </Styles.CartSectionHeading>
            {readyMadeItemsFromOtherStoreOrWarehouse.map(id => (
              <CartItem
                key={id}
                data={cartItemsRecords[id]}
                currency={cartCurrency ?? ''}
                destinationGroupIds={readyMadeItemsFromOtherStoreOrWarehouse}
                serviceabilityData={
                  serviceabilityData[cartItemsRecords[id].skuCode]
                }
                customerAddress={customerAddress}
                usage={usage}
              />
            ))}
          </Styles.CartSection>
        )}
        {jitOrMtoOrMtcItems.length > 0 && (
          <Styles.CartSection>
            <Styles.CartSectionHeading fontSize={14} weight="semibold">
              Made To Order ({jitOrMtoOrMtcItemsQuantity})
            </Styles.CartSectionHeading>
            {jitOrMtoOrMtcItems.map(id => (
              <CartItem
                key={id}
                data={cartItemsRecords[id]}
                currency={cartCurrency ?? ''}
                destinationGroupIds={jitOrMtoOrMtcItems}
                serviceabilityData={
                  serviceabilityData[cartItemsRecords[id].skuCode]
                }
                customerAddress={customerAddress}
                usage={usage}
              />
            ))}
          </Styles.CartSection>
        )}
      </Styles.Content>
      {usage !== 'CHECKOUT' && (
        <Styles.Footer>
          <Styles.FooterBottom>
            {usage === 'ADD_PRODUCTS' && (
              <CheckoutButton
                chargeableAmount={`${currency} ${formatNumberToLocale(
                  chargeableAmount ?? 0
                )}`}
              />
            )}
            {usage === 'RECOVER' && <RecoverCartButton />}
          </Styles.FooterBottom>
        </Styles.Footer>
      )}
    </Styles.Wrapper>
  );
};

export default Cart;
