import { IconPropsType } from '../types';

const DoubleChevronUp = ({
  stroke = 'var(--cod-gray)',
  size = 24,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.95 3.765-2.99-2.74-2.932 2.79M7 6.948 4.01 4.21 1.078 7"
      stroke={stroke}
      strokeWidth={0.826}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DoubleChevronUp;
