import { CheckBox } from '@point-of-sale/components';
import useProductContext from '../../Product/context/useProductContext';
import { TableDataType } from './useTableData';
import * as Styles from './styles';
import { ISource } from '..';

interface ITableProps {
  data: TableDataType;
  source: ISource;
  setSource: (source: ISource) => void;
}

const Table = ({ data, setSource, source }: ITableProps) => {
  const { setFulfillmentFacilityId } = useProductContext();

  return (
    <Styles.Wrapper>
      <Styles.Table>
        <Styles.TableHead>
          <Styles.TableRow $isDisabled={false}>
            <Styles.TableHeader>Location</Styles.TableHeader>
            <Styles.TableHeader>Inventory</Styles.TableHeader>
            <Styles.TableHeader>Expected Delivery Date</Styles.TableHeader>
          </Styles.TableRow>
        </Styles.TableHead>
        <Styles.TableBody>
          {data.map(item => {
            const isSelected = item.id === source.fulfillmentFacility.id;

            return (
              <Styles.TableRow
                $isDisabled={item.isDisabled}
                key={item.id}
                onClick={() => {
                  if (item.isDisabled) {
                    return;
                  }

                  setSource({
                    fulfillmentFacility: {
                      id: item.id,
                      city: item.cityDisplayName,
                      name: item.name,
                      pincode: item.pincode,
                    },
                    inventory: item.inventory,
                    promiseDate: item.expectedDeliveryDate,
                  });

                  setFulfillmentFacilityId(item.id);
                }}
              >
                <Styles.TableData>
                  <CheckBox
                    shape="round"
                    isChecked={isSelected}
                    name={item.name}
                    label={
                      <>
                        {item.name}, {item.cityDisplayName}
                      </>
                    }
                  />
                </Styles.TableData>
                <Styles.TableData>{item.inventory}</Styles.TableData>
                <Styles.TableData>{item.expectedDeliveryDate}</Styles.TableData>
              </Styles.TableRow>
            );
          })}
        </Styles.TableBody>
      </Styles.Table>
    </Styles.Wrapper>
  );
};

export default Table;
