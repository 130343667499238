import { AlterationEntryDTOType } from '@point-of-sale/schemas';
import { titleCase, unCamelCase } from '@point-of-sale/utils';

export function getStringifiedAlterationDetails(
  data?: AlterationEntryDTOType
): string {
  if (!data) return '';

  // Retrieve, flatten, and filter objects
  const alterationEntries = Object.values(data ?? {})
    .flat()
    .filter(val => val !== null)
    .filter(val => typeof val === 'object');

  // Transform and stringify the filtered entries
  let stringifiedAlterationEntries = alterationEntries
    .map(obj =>
      Object.entries(obj)
        .filter(([, val]) => typeof val === 'string' && val.trim() !== '')
        .map(([key, val]) => `${titleCase(unCamelCase(key))}: ${val}`)
        .join(', ')
    )
    .join(', ');

  if (data.remarks) {
    stringifiedAlterationEntries += `, Remarks: ${data.remarks}.`;
  }

  return stringifiedAlterationEntries;
}
