import { IdentityActionsTypes } from './constants';
import { ActionsType, IAuthState } from './types';

const INITIAL_STATE: IAuthState = {
  user: {
    id: null,
    email: null,
  },
  token: null,
  refreshToken: null,
  erpUser: null,
  selectedSalesChannel: null,
} as const;

export const identityReducer = (
  state = INITIAL_STATE,
  action: ActionsType
): IAuthState => {
  switch (action.type) {
    case IdentityActionsTypes.SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case IdentityActionsTypes.SET_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case IdentityActionsTypes.SET_AUTHENTICATION_INFO: {
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        erpUser: action.payload.erpUser,
      };
    }

    case IdentityActionsTypes.SET_SELECTED_SALES_CHANNEL: {
      return {
        ...state,
        selectedSalesChannel: action.payload,
      };
    }

    case IdentityActionsTypes.RESET_STATE: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};
