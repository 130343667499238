import styled from 'styled-components';
import { OrderDTOType } from '@point-of-sale/schemas';
import {
  formatNumberToLocale,
  formatToReadableDate,
  removeUnderscoresAndCapitalize,
} from '@point-of-sale/utils';
import { useAppSelector } from '@point-of-sale/store';

export const StyledTable = styled.table`
  width: 100%;
  /* table-layout: fixed; */
`;

export const TBody = styled.tbody`
  word-break: break-word;
  hyphens: auto;
`;

export const Th = styled.th`
  font-size: 12px;
  font-weight: 700;
  padding: 2px 0;
  line-height: 16px;
  padding: 4px 0;
  border-bottom: 1px dashed var(--dusty-gray);
  /* text-align: left; */
`;

export const Td = styled.td`
  font-size: 12px;
  line-height: 16px;

  word-wrap: break-word;
  white-space: normal;

  padding: 4px 4px 8px 0;
`;

export const Tr = styled.tr``;

interface ITableProps {
  data: OrderDTOType['lineItems'];
  currency: string;
  showTax?: boolean;
}

const LineItems = ({ data, currency, showTax }: ITableProps) => {
  const facilities = useAppSelector(
    store => store.common.facilities.data.records
  );

  return (
    <StyledTable>
      <thead>
        <Th align="left">
          HSN
          <br />
          Item
        </Th>
        <Th align="left">MRP</Th>
        <Th align="left">QTY</Th>
        <Th align="left">Disc</Th>
        {showTax && <Th align="left">Tax %</Th>}
        <Th align="right">Total</Th>
      </thead>
      <TBody lang="de">
        {data.map(item => {
          const discount =
            (item.costBreakup.mrp ?? 0) -
            (item.costBreakup.sellingPrice ?? 0) +
            (item.costBreakup.couponDiscount ?? 0);

          return (
            <>
              <Tr key={item.id}>
                <Td width="25%">
                  {item.hsnCode} <br />
                  {item.productName}
                </Td>
                <Td width="20%">
                  {currency} {formatNumberToLocale(item.costBreakup.mrp ?? 0)}
                </Td>
                <Td width="10%">1</Td>
                <Td width={showTax ? '15%' : '20%'}>
                  {currency} {formatNumberToLocale(discount)}
                </Td>
                {showTax && <Td width="5%">{item.taxClassPerc}%</Td>}
                <Td width="20%" align="right">
                  {currency}{' '}
                  {formatNumberToLocale(
                    item.costBreakup.chargeableAmountExCustomCharges ?? 0
                  )}
                </Td>
              </Tr>
              {item.costBreakup.customCharges &&
                item.costBreakup.customCharges.map(charge => (
                  <Tr>
                    <Td colSpan={showTax ? 4 : 4}>
                      {removeUnderscoresAndCapitalize(charge.type)} Charge
                    </Td>
                    {showTax && (
                      <Td colSpan={1} align="right">
                        {charge.taxClassPerc}%
                      </Td>
                    )}
                    <Td colSpan={1} align="right">
                      {currency}
                      {formatNumberToLocale(charge.amount)}
                    </Td>
                  </Tr>
                ))}
              {/* {item.alterationEntry && (
                <Tr>
                  <Td width="100%" colSpan={3}>
                    {item.fulfillmentMode === 'ONHAND'
                      ? 'Alterations'
                      : 'Customizations'}{' '}
                    Added
                  </Td>
                  <Td width="100%" colSpan={3} style={{ fontWeight: 'bold' }}>
                    {removeUnderscoresAndCapitalize(item.deliveryMode)}
                  </Td>
                </Tr>
              )} */}
              {item.deliveryMode === 'STORE_PICKUP' && (
                <Tr>
                  <Td width="100%" colSpan={2}>
                    {item.deliveryMode === 'STORE_PICKUP'
                      ? 'Pick Up Timeline'
                      : 'Delivery Timeline'}{' '}
                    <br />
                    {item.estimatedDeliveryDate
                      ? formatToReadableDate(item.estimatedDeliveryDate)
                      : ''}
                  </Td>
                  <Td
                    width="100%"
                    colSpan={3}
                    align="right"
                    style={{ fontWeight: 'bold' }}
                  >
                    {item.deliveryMode === 'STORE_PICKUP' &&
                      item.customerDeliveryStoreId &&
                      facilities[item.customerDeliveryStoreId]?.name}
                  </Td>
                </Tr>
              )}
            </>
          );
        })}
      </TBody>
    </StyledTable>
  );
};

export default LineItems;
