import { useEffect, useState } from 'react';
import {
  LargeGreenCheckIcon,
  SpinnerIcon,
  Text,
} from '@point-of-sale/components';
import {
  CartTransactionDTOSchema,
  CartTransactionDTOType,
} from '@point-of-sale/schemas';
import {
  getTransactionApiStatusApi,
  setProcessedOrder,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { ApiResponseEnum } from '@point-of-sale/types';
import { apiResponseHandler } from '@point-of-sale/utils';
import { POLLING_INTERVAL, POLLING_LIMIT } from './constants';
import * as Styles from './styles';
import toast from 'react-hot-toast';

type PaymentStatus = 'PENDING' | 'SUCCESS' | 'FAILED';

interface IPaymentStatusIndicatorProps {
  isOpen: boolean;
  onClose: () => void;
  transactionId?: number;
}

const PaymentStatusIndicator = ({
  isOpen,
  onClose,
  transactionId,
}: IPaymentStatusIndicatorProps) => {
  const cartId = useAppSelector(store => store.pointOfSale.cart.data.id);

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>('PENDING');
  const [count, setCount] = useState(1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const interval = setInterval(async () => {
      if (paymentStatus === 'SUCCESS') {
        clearInterval(interval);
        return;
      }

      if (!transactionId) {
        clearInterval(interval);
        onClose();
        return;
      }

      if (count === POLLING_LIMIT) {
        setPaymentStatus('FAILED');
        clearInterval(interval);
        return;
      }

      setPaymentStatus('PENDING');
      //   const promise = getTransactionApiStatusApi(57, 56);
      const promise = getTransactionApiStatusApi(cartId, transactionId);
      const response = await apiResponseHandler<CartTransactionDTOType>(
        promise,
        CartTransactionDTOSchema
      );

      setCount(prev => prev + 1);

      if (response.type === ApiResponseEnum.Failure) {
        toast.error(response.meta?.message ?? 'Something went wrong');
        //   setPaymentStatus('FAILED');
        return;
      }

      if (response.data.status === 'VERIFIED') {
        setPaymentStatus('SUCCESS');

        if (response.data.order) {
          clearInterval(interval);
          dispatch(
            setProcessedOrder({
              isLoading: false,
              isSuccess: true,
              isError: false,
              data: response.data.order,
            })
          );
        }
      }
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, cartId, transactionId]);

  if (!transactionId) {
    return null;
  }

  return (
    <Styles.Wrapper>
      {paymentStatus === 'PENDING' && (
        <>
          <SpinnerIcon stroke="var(--tulip-tree)" />
          <Text
            fontSize={14}
            weight="bold"
            textAlign="center"
            color="var(--tulip-tree)"
          >
            Your payment is being verified
          </Text>
        </>
      )}
      {paymentStatus === 'SUCCESS' && (
        <>
          <LargeGreenCheckIcon />
          <Text fontSize={14} weight="bold" textAlign="center">
            Your Payment is successful
          </Text>
          <Text fontSize={14} textAlign="center">
            Thank you for shopping with us
          </Text>
        </>
      )}
      {paymentStatus === 'FAILED' && <Text>Your transaction failed</Text>}
    </Styles.Wrapper>
  );
};

export default PaymentStatusIndicator;
