import { useState } from 'react';
import { format } from 'date-fns';
import { Button, Input, Text } from '@point-of-sale/components';
import {
  collectPendingPayment,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import * as Styles from './styles';
import { PropsWithSalesPoc } from '../types';
import { usePaymentCollectionContext } from '../context';
import * as TableStyles from '../../../components/Table/styles';

const CreditNote = ({ salesPoc }: PropsWithSalesPoc) => {
  const creditNotes = useAppSelector(
    store =>
      Object.values(store.checkout.paymentOptions.data.records).find(
        item => item.gateway === 'CREDIT_NOTE'
      )?.options
  );

  const [newCreditNote, setNewCreditNote] = useState('');

  const { salesChannelOrderId, lineItemIds, packageId } =
    usePaymentCollectionContext();

  const dispatch = useAppDispatch();

  const onApplyAutomaticCreditNote = () => {
    dispatch(
      collectPendingPayment(
        salesChannelOrderId,
        {
          paymentGateway: 'CREDIT_NOTE',
          paymentMode: 'CREDIT_NOTE',
          lineItemIds,
          packageId: packageId,
          salesStaffId: salesPoc?.id,
          // send meta data to manual addition only,
          metadata: {},
        },
        {}
      )
    );
  };

  const onApplyManualCreditNote = (newCode: string) => {
    dispatch(
      collectPendingPayment(
        salesChannelOrderId,
        {
          paymentGateway: 'CREDIT_NOTE',
          paymentMode: 'CREDIT_NOTE',
          lineItemIds,
          packageId: packageId,
          salesStaffId: salesPoc?.id,
          // send meta data to manual addition only,
          metadata: {
            CREDIT_NOTE_REF: newCode,
          },
        },
        {}
      )
    );
  };

  const totalCredit = creditNotes?.reduce(
    (acc, item) => acc + (item.meta?.AVAILABLE_CREDIT as number),
    0
  );

  const doesCreditNoteExist = creditNotes && creditNotes?.length > 0;

  return (
    <Styles.Wrapper>
      {/* {creditNotes?.map(item => {
        const expiryDate = new Date(item.meta?.EXPIRY_DATE as number);
        const isExpired = item.meta?.EXPIRED;

        return (
          <Styles.CreditNote key={item.id}>
            <Styles.InputWrapper>
              <Input
                variant="outline"
                sizeVariant="small"
                disabled
                value={item.name}
              />
              <Button
                variant="outline"
                isDisabled={isExpired}
                onClick={() =>
                  onApplyCreditNote(
                    item.meta?.AVAILABLE_CREDIT as number,
                    item.name
                  )
                }
              >
                {isExpired ? 'Expired' : 'Apply'}
              </Button>
            </Styles.InputWrapper>
            <Text fontSize={14}>
              Available credit: <b>Rs. {item.meta?.AVAILABLE_CREDIT}</b>
            </Text>
            <Text fontSize={14}>
              Available till : &nbsp;
              <b>{format(expiryDate, 'dd MMM yyyy')}</b>
            </Text>
          </Styles.CreditNote>
        );
      })}

      <Styles.CreditNote>
        <Styles.InputWrapper>
          <Input
            variant="outline"
            sizeVariant="small"
            value={newCreditNote}
            onChange={e => {
              if (Number(e.target.value) < 0) {
                return;
              }

              setNewCreditNote(e.target.value);
            }}
          />
          <Button variant="outline" onClick={() => onAddNewCreditNote()}>
            Add
          </Button>
        </Styles.InputWrapper>
      </Styles.CreditNote> */}

      {doesCreditNoteExist && (
        <TableStyles.Wrapper
          style={{
            width: '100%',
          }}
        >
          <TableStyles.Table>
            <TableStyles.Thead>
              <TableStyles.TableHeadingRow>
                <TableStyles.TableHeader>Code</TableStyles.TableHeader>
                <TableStyles.TableHeader>
                  Available credit
                </TableStyles.TableHeader>
                <TableStyles.TableHeader>Valid till</TableStyles.TableHeader>
              </TableStyles.TableHeadingRow>
            </TableStyles.Thead>
            <tbody>
              {creditNotes?.map((data, index) => {
                const expiryDate = new Date(data.meta?.EXPIRY_DATE as number);

                return (
                  <TableStyles.TableRow key={data.id} $isSelected={false}>
                    <TableStyles.TableData>{data.name}</TableStyles.TableData>
                    <TableStyles.TableData>
                      Rs. {data.meta?.AVAILABLE_CREDIT}
                    </TableStyles.TableData>
                    <TableStyles.TableData>
                      {format(expiryDate, 'dd MMM yyyy')}
                    </TableStyles.TableData>
                  </TableStyles.TableRow>
                );
              })}
              <TableStyles.TableRow $isSelected={false}>
                <TableStyles.TableData></TableStyles.TableData>
                <TableStyles.TableData>
                  <b>Total: Rs. {totalCredit}</b>
                </TableStyles.TableData>
                <TableStyles.TableData></TableStyles.TableData>
              </TableStyles.TableRow>
            </tbody>
          </TableStyles.Table>
        </TableStyles.Wrapper>
      )}
      <Styles.Row>
        {doesCreditNoteExist && (
          <>
            <Button
              variant="outline"
              sizeVariant="xs"
              onClick={() => {
                onApplyAutomaticCreditNote();
              }}
            >
              Use Credit Note
            </Button>

            <Text>Or</Text>
          </>
        )}
        <Styles.InputWrapper>
          <Input
            variant="outline"
            sizeVariant="extraSmall"
            placeholder="Enter Code"
            value={newCreditNote}
            onKeyDown={e =>
              (e.key === 'e' || e.key === 'E') && e.preventDefault()
            }
            onChange={e => {
              if (Number(e.target.value) < 0) {
                return;
              }

              setNewCreditNote(e.target.value);
            }}
          />
          <Button
            variant="outline"
            sizeVariant="xs"
            onClick={() => {
              onApplyManualCreditNote(newCreditNote);
            }}
          >
            Add Manually
          </Button>
        </Styles.InputWrapper>
      </Styles.Row>
    </Styles.Wrapper>
  );
};

export default CreditNote;
