import { Text } from '@point-of-sale/components';
import useProductContext from '../context/useProductContext';
import Availability from './Availability';
import Price from './Price';
import Actionables from './Actionables';
import * as Styles from './styles';
import { INormalizedServiceabilityData } from '@point-of-sale/schemas';

interface INomenclatureProps {
  serviceabilityData: INormalizedServiceabilityData;
  isFetchingServiceability: boolean;
}

const Nomenclature = ({
  serviceabilityData,
  isFetchingServiceability,
}: INomenclatureProps) => {
  const { isExpanded, data, selectedVariant } = useProductContext();

  return (
    <Styles.Title>
      <Styles.TitleLeft>
        <Text fontSize={12} lineHeight={16} color="var(--dove-gray)">
          {data.brand.name}
        </Text>
        <Text fontSize={20} lineHeight={28} color="var(--cod-gray)">
          {data.name}
        </Text>
        {data.components && (
          <Styles.TitleLeftBottom>
            <Text
              as="span"
              fontSize={12}
              lineHeight={16}
              color="var(--dove-gray)"
            >
              Pack Contains :
            </Text>
            <Text
              as="span"
              fontSize={12}
              lineHeight={16}
              weight="bold"
              color="var(--cod-gray)"
            >
              {data.components}
            </Text>
          </Styles.TitleLeftBottom>
        )}
        {isExpanded && (
          <Styles.TitleLeftBottom>
            <Text
              as="span"
              fontSize={12}
              lineHeight={16}
              color="var(--dove-gray)"
            >
              SKU Code :
            </Text>
            <Text
              as="span"
              fontSize={12}
              lineHeight={16}
              weight="bold"
              color="var(--cod-gray)"
            >
              {selectedVariant?.skuCode ?? 'Choose a variant'}
            </Text>
          </Styles.TitleLeftBottom>
        )}
      </Styles.TitleLeft>

      <Availability
        serviceabilityData={serviceabilityData}
        isFetchingServiceability={isFetchingServiceability}
      />

      <Styles.PriceAndActions>
        <Price
          isExpanded={isExpanded}
          data={data}
          selectedVariant={selectedVariant}
        />
        {isExpanded && <Actionables />}
      </Styles.PriceAndActions>
    </Styles.Title>
  );
};

export default Nomenclature;
