import useProductContext from '../context/useProductContext';
import { addItemToCart, useAppDispatch } from '@point-of-sale/store';
import toast from 'react-hot-toast';
import { Button } from '@point-of-sale/components';
import {
  FacilityDTOType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import { useBoolean } from '@point-of-sale/hooks';
import { addDays, getUnixTime } from 'date-fns';

interface IBuyFromAnotherStoreProps {
  anotherStore: FacilityDTOType;
  serviceabilityData: INormalizedServiceabilityData;
  isFetchingServiceability: boolean;
}

const BuyFromAnotherStore = ({
  anotherStore,
  serviceabilityData,
  isFetchingServiceability,
}: IBuyFromAnotherStoreProps) => {
  const { quantity, selectedVariant, fulfillmentFacilityId } =
    useProductContext();

  const [isLoading, loadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  function addToCart() {
    if (!selectedVariant) {
      toast.error('Please select a variant');
      return;
    }

    if (fulfillmentFacilityId) {
      loadingActions.on();

      const serviceability = serviceabilityData[selectedVariant.skuCode].find(
        item =>
          String(item.from.facilityId) === String(fulfillmentFacilityId) &&
          item.to === anotherStore.address.pincode
      );

      dispatch(
        addItemToCart(
          {
            skuId: selectedVariant.skuId,
            skuCode: selectedVariant.skuCode,
            deliveryMode: 'STORE_PICKUP',
            fulfillmentMode: 'ONHAND',
            fulfillmentFacilityId: anotherStore.id, // another store's id
            customerDeliveryStoreId: fulfillmentFacilityId, // current store's id
            fulfillmentFacilityGroupId: anotherStore.facilityGroupId,
            fulfillmentFacilityType: anotherStore.facilityType,
            quantity,
            promiseDate: getUnixTime(
              addDays(new Date(), serviceability?.info.bestTat ?? 1)
            ).toString(),
          },
          loadingActions.off
        )
      );
    }
  }

  return (
    <Button
      sizeVariant="xs"
      variant="outline"
      onClick={addToCart}
      isLoading={isLoading || isFetchingServiceability}
    >
      Buy from {anotherStore.name}
    </Button>
  );
};

export default BuyFromAnotherStore;
