import { IconPropsType } from '../types';

const CopyIcon = ({
  size = 12,
  stroke = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.667.646h-5v5.57"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.334 2.358h5v5.143a.87.87 0 0 1-.245.606.82.82 0 0 1-.589.251H4.167a.82.82 0 0 1-.59-.25.87.87 0 0 1-.244-.607z"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopyIcon;
