import { addDays } from 'date-fns';
import {
  AddressDTOType,
  FacilityDTOType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import {
  getCurrentStoreAndWarehouseFacilityIds,
  useAppSelector,
} from '@point-of-sale/store';
import {
  formatNumberToLocale,
  formatToReadableDate,
} from '@point-of-sale/utils';
import useProductContext from '../../Product/context/useProductContext';

import { IDestination } from '../index';

interface IUseTableDataProps {
  serviceabilityData: INormalizedServiceabilityData[string];
  customerAddress: AddressDTOType;
  destination: IDestination;
  demandedPincode: string;
}

const useTableData = ({
  serviceabilityData,
  customerAddress,
  destination,
  demandedPincode,
}: IUseTableDataProps) => {
  const facilities = useAppSelector(store => store.common.facilities.data);

  const currentStoreFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const { selectedVariant } = useProductContext();

  const { currentStoreAndWarehousesFacilityIds } =
    getCurrentStoreAndWarehouseFacilityIds();

  let deliverToPincode: FacilityDTOType['address']['pincode'] =
    '' as FacilityDTOType['address']['pincode'];

  // TODO: Modularize this logic
  if (destination.deliveryMode === 'HOME_DELIVERY') {
    if (customerAddress) {
      deliverToPincode = customerAddress.pincode;
    } else if (demandedPincode) {
      deliverToPincode = demandedPincode;
    }
  } else if (
    destination.deliveryMode === 'STORE_PICKUP' &&
    destination.customerDeliveryStoreId
  ) {
    deliverToPincode =
      facilities.records[destination.customerDeliveryStoreId].address?.pincode;
  } else if (
    destination.deliveryMode === 'STORE_PURCHASE' &&
    currentStoreFacilityId
  ) {
    // current store facility id or current warehouse
    deliverToPincode =
      facilities.records[currentStoreFacilityId].address?.pincode;
  }

  const data = facilities.ids.map(id => {
    const record = facilities.records[id];

    let estimate = '';

    const serviceability = serviceabilityData.find(
      item =>
        String(item.from.facilityId) === String(id) &&
        item.to === deliverToPincode
    );

    console.log('serviceability', serviceability);

    if (serviceability?.info?.express) {
      estimate = 'EXPRESS';
    } else {
      estimate = serviceability?.info.bestTat
        ? formatToReadableDate(
            addDays(new Date(), serviceability?.info.bestTat)
          )
        : '';
    }
    const inventory =
      selectedVariant?.facilityInventory?.[id]?.availableInventory ?? 0;

    const isDisabled =
      inventory === 0
        ? !currentStoreAndWarehousesFacilityIds.includes(id)
        : false;

    console.log({ estimate });

    return {
      id,
      name: record.name,
      cityDisplayName: record.address.cityDisplayName ?? '',
      inventory: inventory,
      expectedDeliveryDate: inventory > 0 ? estimate : '',
      pincode: record.address.pincode ?? '',
      isDisabled: isDisabled,
    };
  });

  return data;
};

export default useTableData;

export type TableDataType = ReturnType<typeof useTableData>;
