import { useStep } from '@point-of-sale/hooks';
import { Text } from '@point-of-sale/components';
import PhoneOrEmail from './PhoneOrEmail';
import OtherDetails from './OtherDetails';
import * as Styles from './styles';
import { useEffect } from 'react';
import {
  resetCheckoutState,
  resetPointOfSaleState,
  useAppDispatch,
} from '@point-of-sale/store';

const Customer = () => {
  const [currentStep, { setStep }] = useStep(2);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetPointOfSaleState());
    dispatch(resetCheckoutState());
  }, []);

  return (
    <Styles.Wrapper>
      <Styles.Content>
        <Styles.Heading>
          <Text fontSize={20} weight="bold">
            {currentStep === 1 && <>Enter Customer Details</>}
            {currentStep === 2 && <>Customer Details</>}
          </Text>
        </Styles.Heading>
        {currentStep === 1 && <PhoneOrEmail setStep={setStep} />}
        {currentStep === 2 && <OtherDetails />}
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default Customer;
