import { IconPropsType } from '../types';

const GiftVoucherIcon = ({
  stroke = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.096 11v8c0 .53.212 1.04.589 1.414.377.375.89.586 1.423.586H18.18c.533 0 1.045-.21 1.422-.586.378-.375.59-.884.59-1.414v-8"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.144 5.5a3.5 3.5 0 0 0-1.031-2.475A3.53 3.53 0 0 0 8.623 2c-.667 0-1.307.263-1.779.732a2.49 2.49 0 0 0 0 3.536A2.52 2.52 0 0 0 8.623 7h3.52m0-1.5V7m0-1.5a3.5 3.5 0 0 1 1.032-2.475A3.53 3.53 0 0 1 15.665 2c.667 0 1.307.263 1.779.732a2.492 2.492 0 0 1-.816 4.078c-.306.125-.633.19-.963.19h-3.521"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M12.144 11v10M3.09 7h18.109v4H3.089z"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default GiftVoucherIcon;
