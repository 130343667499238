import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  Button,
  LargeGreenCheckIcon,
  Modal,
  Text,
} from '@point-of-sale/components';
import { addRefundReceiptToOrder, useAppDispatch } from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';

import { AssetUploaderService } from '@point-of-sale/services';

import { LineItemDTOType, OrderDTOType } from '@point-of-sale/schemas';
import * as Styles from './styles';
import ReturnPrintable from '../../../pages/Checkout/PrintableInvoice/ReturnPrintable';

interface IRefundReceiptGenerationModalProps {
  orderData: OrderDTOType;
  isOpen: boolean;
  onClose: () => void;
  pdfData: Array<LineItemDTOType>;
}

const RefundReceiptGenerationModal = ({
  orderData,
  isOpen,
  onClose,
  pdfData,
}: IRefundReceiptGenerationModalProps) => {
  const dispatch = useAppDispatch();

  const componentRef = useRef<HTMLDivElement>(null);

  const [isPdfLoading, pdfLoadingActions] = useBoolean();
  const [isFetchingData, fetchingDataActions] = useBoolean();

  const triggerPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `COYU-${orderData.salesChannelOrderId}`,
  });

  const generatePDF = async () => {
    const first = pdfData[0];

    const refundId = first.refundRequestId;

    const content = componentRef.current;

    if (!content) {
      return;
    }

    pdfLoadingActions.on();

    // Convert the content to canvas using html2canvas
    const canvas = await html2canvas(content);

    // Convert 80mm to points (1mm = 2.83465 points)
    const pdfWidthMM = 80;
    const pdfWidthPoints = pdfWidthMM * 2.83465;

    // Calculate the height based on the canvas aspect ratio
    const aspectRatio = canvas.height / canvas.width;
    const pdfHeightPoints = pdfWidthPoints * aspectRatio;

    // Create jsPDF instance with the specified width of 80mm and dynamic height
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt', // Use points for precise sizing
      format: [pdfWidthPoints, pdfHeightPoints], // Set custom width and height
    });

    // Convert canvas to image data
    const imgData = canvas.toDataURL('image/png');

    // Add the image to the PDF
    pdf.addImage(
      imgData,
      'PNG',
      0, // X position (no margin)
      0, // Y position (no margin)
      pdfWidthPoints, // Set width exactly to 80mm (converted to points)
      pdfHeightPoints // Proportional height based on the aspect ratio
    );
    const pdfBlob = pdf.output('blob');

    const assetUploader = new AssetUploaderService();

    await assetUploader.uploadFile({
      file: pdfBlob,
      onError: (error: string) => {
        console.error('Error uploading file', error);
      },
      onSuccess: (uploadedFile: any) => {
        console.log('Uploaded File', uploadedFile);

        dispatch(
          addRefundReceiptToOrder(
            refundId,
            { id: uploadedFile.id, url: uploadedFile.url },
            () => {
              pdfLoadingActions.off();
              // triggerPrint();
            }
          )
        );
      },
      usage: 'INVOICE',
    });

    return pdfBlob;
  };

  useEffect(() => {
    async function doAllWork() {
      try {
        generatePDF();
      } catch (error) {
        console.error('Error in processing:', error);
      }
    }

    if (isOpen && pdfData.length >= 1) {
      doAllWork();
      // if (!orderData.purchaseReceipt?.url) {
      // doAllWork();
      // }
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} size="regular">
      <Styles.Wrapper>
        <>
          <LargeGreenCheckIcon />
          <Text fontSize={28} weight="bold" textAlign="center">
            Return successful
          </Text>
          <Text fontSize={20} textAlign="center">
            Thank you for your purchase.
          </Text>
        </>

        {pdfData.length >= 1 && (
          <Button
            onClick={() => {
              triggerPrint();
            }}
          >
            PRINT RETURN BILL
          </Button>
        )}

        {pdfData.length >= 1 && (
          <Text fontSize={14} textAlign="center">
            OR
          </Text>
        )}

        <Button variant="outline" onClick={onClose}>
          BACK TO ORDER STATUS
        </Button>
        {/* {isPdfLoading && (
          <>
            <SpinnerIcon size={56} stroke="var(--tulip-tree)" />
            <Text
              fontSize={20}
              weight="bold"
              textAlign="center"
              color="var(--tulip-tree)"
            >
              Bill for Order {orderData.salesChannelOrderId} is being
              generated
            </Text>
          </>
        )} */}
        {/* {isSuccess && (

        )} */}
        {/* {isError && (
          <>
            <LargeRedExclamationIcon />
            <Text fontSize={28} weight="bold" textAlign="center">
              Your payment was not successful
            </Text>
          </>
        )} */}
        {/* {isSuccess && (
          <Button
            isLoading={cartTransactions.isLoading}
            onClick={() => {
              triggerPrint();
            }}
          >
            PRINT BILL
          </Button>
        )} */}
        {/* <Button
          onClick={() => {
            dispatch(resetPointOfSaleState());
            dispatch(resetCheckoutState());
            navigate('/point-of-sale/add-customer');
          }}
        >
          START A NEW BILL
        </Button> */}
      </Styles.Wrapper>

      {pdfData.length >= 1 && (
        <Styles.PrintableContent style={{ width: '80mm' }} ref={componentRef}>
          <ReturnPrintable
            sellerInfo={orderData.sellerInfo}
            lineItems={pdfData}
            customerData={{
              customerEmail: orderData.customerEmail,
              customerName: orderData.customerName,
              customerPhone: orderData.customerPhone,
            }}
          />
        </Styles.PrintableContent>
      )}

      {/* {Object.keys(orderData).length > 0 && (
        <div ref={componentRef} style={{ width: '80mm' }}>
          <PrintableInvoice
            data={orderData}
            type="INVOICE"
            customerGSTIN={customerGSTIN}
            cartTransactions={cartTransactions.data.records}
          />
        </div>
      )} */}
    </Modal>
  );
};

export default RefundReceiptGenerationModal;
