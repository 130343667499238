import React, { ChangeEvent, useEffect, useState } from 'react';
import { IOption, MultiSelect } from '@point-of-sale/components';
import { OmsFilterNames, PosFilterNames } from '@point-of-sale/store';
import * as Styles from './styles';

export interface IFilter {
  name: string;
  placeholder: string;
  options: Array<IOption>;
  values: Array<string> | Array<number>;
}

interface IDiscoveryProps {
  searchQuery: string;
  onSearchChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (search: string) => void;
  filters: IFilter[];
  onFilterChange: (filterName: any, selectedOptions: IOption[]) => void;
  onFilterClear: (filterName: string) => void;
  renderSelectedFilters?: () => React.ReactNode;
  placeholder?: string;
  isLoading?: boolean;
}

const Discovery = ({
  searchQuery,
  onSearchChange,
  onPressEnter,
  filters,
  onFilterChange,
  onFilterClear,
  renderSelectedFilters,
  isLoading = false,
  placeholder = 'Search...',
}: IDiscoveryProps) => {
  const [search, setSearch] = useState('');

  // function handleChange(e){
  //   setValue(e.target.value);
  // }

  useEffect(() => {
    setSearch(searchQuery);
  }, [searchQuery]);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Styles.SearchInput
          placeholder={placeholder}
          value={search}
          autoFocus
          type="search"
          onChange={e => setSearch(e.target.value)}
          // onChange={onSearchChange}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              onPressEnter?.(search);
            }
          }}
        />

        {filters.map(filter => (
          <MultiSelect
            isLoading={isLoading}
            key={filter.name}
            isSearchable
            placeholder={filter.placeholder}
            label={filter.placeholder}
            options={filter.options}
            values={filter.values}
            onChange={selectedOptions =>
              onFilterChange(filter.name as PosFilterNames, selectedOptions)
            }
            onClear={() => onFilterClear(filter.name)}
          />
        ))}
      </Styles.Row>
      {renderSelectedFilters && renderSelectedFilters()}
    </Styles.Wrapper>
  );
};

export default Discovery;
