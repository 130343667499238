import { useBoolean } from '@point-of-sale/hooks';
import { LineItemDTOType } from '@point-of-sale/schemas';
import styled from 'styled-components';
import PersonalizationRead from '../../../Cart/Personalization/PersonalizationRead';
import { Button, Text } from '@point-of-sale/components';
import {
  setLineItemDetailsById,
  updateLineItemAlterationEntry,
  useAppDispatch,
} from '@point-of-sale/store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IRemarksAndCustomizationsProps {
  data: LineItemDTOType;
}

const RemarksAndCustomizations = ({ data }: IRemarksAndCustomizationsProps) => {
  const [isAlterationModalVisible, alterationModalVisibleAction] = useBoolean();

  const [isLoading, loadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  if (!data.alterationEntry && !data.customizationCategory) {
    return null;
  }

  // console.log(data);

  return (
    <Wrapper>
      <Text color="var(--dove-gray)" fontSize={14}>
        Remarks &{' '}
        {data.fulfillmentMode === 'ONHAND' ? 'Alterations' : 'Customizations'}
      </Text>

      <Button
        variant="ghost"
        rightIcon="LinkIcon"
        onClick={alterationModalVisibleAction.on}
        sizeVariant="xs"
      >
        {data.alterationEntry ? 'View' : 'Add'} Alterations
      </Button>

      <PersonalizationRead
        isOpen={isAlterationModalVisible}
        onClose={alterationModalVisibleAction.off}
        onChangeRemarks={formData => {
          loadingActions.on();
          dispatch(
            updateLineItemAlterationEntry(
              data.id,
              formData,
              newLineItemData => {
                // personalizationModalOpenActions.off();
                loadingActions.off();

                if (newLineItemData) {
                  dispatch(
                    setLineItemDetailsById({
                      id: String(data.id),
                      data: newLineItemData,
                    })
                  );
                }

                // setCustomizationData(null);
                // setSelectedLineItemId(null);
                // setSkuInfo({
                //   color: '',
                //   size: '',
                // });
              }
            )
          );
        }}
        isLoading={isLoading}
        mode={
          data.fulfillmentMode === 'ONHAND' ? 'ALTERATION' : 'CUSTOMIZATION'
        }
        color={data.color}
        size={data.size}
        alterationEntry={
          data.alterationEntry
            ? data.alterationEntry
            : {
                customizationCategory: data.customizationCategory,
                referenceColor: data.color,
                referenceSize: data.size,
              }
        }
      />
    </Wrapper>
  );
};

export default RemarksAndCustomizations;
