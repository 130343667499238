// **************************************************************************
// **************************************************************************
// ************************                        **************************
// ************************       Icon Button      **************************
// ************************                        **************************
// **************************************************************************
// **************************************************************************

import { MouseEventHandler } from 'react';
import {
  IconButtonColorVariantType,
  IconButtonShapeVariantType,
  IconButtonSizeVariantType,
} from './types';
import { iconButtonIconSizes, IconButtonIconStyles } from './constants';
import iconMap from '../icons/iconMap';
import { IconographyNameType } from '../icons/types';
import * as Styles from './styles';

export interface IIconButtonProps {
  icon: Exclude<IconographyNameType, 'CoyuLogo' | 'HamBurgerIcon'>;
  sizeVariant?: IconButtonSizeVariantType;
  colorVariant?: IconButtonColorVariantType;
  shapeVariant?: IconButtonShapeVariantType;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  className?: string;
}

const IconButton = ({
  onClick,
  isDisabled,
  icon,
  className = '',
  colorVariant = 'neutral',
  shapeVariant = 'square',
  sizeVariant = 'medium',
}: IIconButtonProps) => {
  const Icon = iconMap[icon];

  return (
    <Styles.IconButton
      className={className}
      onClick={onClick}
      $colorVariant={colorVariant}
      $shapeVariant={shapeVariant}
      $sizeVariant={sizeVariant}
      disabled={isDisabled}
    >
      <Icon
        size={iconButtonIconSizes[sizeVariant]}
        {...(isDisabled
          ? IconButtonIconStyles[colorVariant].disabled
          : IconButtonIconStyles[colorVariant].enabled)}
      />
    </Styles.IconButton>
  );
};

export default IconButton;
