import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from '../Navigation';
import * as Styles from './styles';

interface IBasicLayoutProps {
  header?: ReactNode;
}

const BasicLayout = ({ header }: IBasicLayoutProps) => {
  return (
    <Styles.Wrapper>
      <Styles.Left>
        <Navigation />
      </Styles.Left>
      <Styles.Right>
        {header}
        <Outlet />
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default BasicLayout;
