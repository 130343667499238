import * as React from 'react';
import { IconPropsType } from '../types';
const PointOfSaleIcon = ({
  stroke = 'var(--dove-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.832 2.75v3.667c0 .506.41.916.917.916h3.666"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M15.582 19.25H6.415a1.833 1.833 0 0 1-1.833-1.833V4.583c0-1.012.82-1.833 1.833-1.833h6.417l4.583 4.583v10.084c0 1.012-.82 1.833-1.833 1.833"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 8.25h.917m-.917 3.666h5.5m-5.5 3.667h5.5"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PointOfSaleIcon;
