import styled from 'styled-components';
import * as Styles from './styles';
import { COYU_LOGO } from './CoyuBase64';

const HeaderWrapper = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;
  padding: 24px 0;

  flex-direction: column;

  gap: 8px;
`;

export const LogoWrapper = styled.div`
  width: 160px;

  img {
    height: 100%;
    width: 100%;
  }
`;

interface IHeaderProps {
  src: string;
}

const Header = ({ src }: IHeaderProps) => (
  <HeaderWrapper>
    <LogoWrapper>
      <img src={COYU_LOGO} alt="COYU" />
    </LogoWrapper>

    <Styles.Text align="center" weight={700}>
      Thank you for your purchase!
    </Styles.Text>

    <Styles.Text align="center" weight={700}>
      We hope you’re thrilled with your new purchase and look forward to seeing
      you again soon.
    </Styles.Text>
  </HeaderWrapper>
);

export default Header;
