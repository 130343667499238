import { PosFilterNames, useAppSelector } from '@point-of-sale/store';
import { IconButton, IOption, Text } from '@point-of-sale/components';
import * as Styles from './styles';
import { IFilter } from '@point-of-sale/services';
import { AnimatePresence, MotionProps } from 'framer-motion';

// const animations: MotionProps = {
//   initial: { scale: 0, opacity: 0 },
//   animate: { scale: 1, opacity: 1 },
//   exit: { scale: 0 }, //opacity: 0
//   transition: { type: 'spring', stiffness: 900, damping: 40, duration: 2 },
// };

interface ISelectedFiltersProps {
  onChangeFilter: (field: PosFilterNames, options: Array<IOption>) => void;
}

const SelectedFilters = ({ onChangeFilter }: ISelectedFiltersProps) => {
  const facets = useAppSelector(store => store.pointOfSale.facets);
  const filters = useAppSelector(store => store.pointOfSale.filters);
  const facilities = useAppSelector(
    store => store.common.facilities.data.records
  );

  function getDisplayableValue(filter: IFilter, filterValue: string) {
    const filterName = filter.field as PosFilterNames;

    if (filterName === 'variants.color' || filterName === 'variants.size') {
      return filterValue;
    }

    if (filterName === 'brandName') {
      return facets
        .find(facet => facet.key.key === filterName)
        ?.values.find(value => value.id === filterValue)?.value;
    }

    return Object.values(facilities).find(
      facility => String(facility.id) === String(filterValue)
    )?.name;
  }

  if (Object.values(filters).length === 0) {
    return null;
  }

  return (
    <Styles.Wrapper>
      <AnimatePresence mode="popLayout">
        {Object.values(filters).map(filter =>
          String(filter.value)
            .split(',')
            .map(value => {
              const displayableValue = getDisplayableValue(filter, value);

              if (!displayableValue || displayableValue?.length === 0) {
                return null;
              }

              return (
                <Styles.Filter
                  layout
                  key={value}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                  }}
                  exit={{
                    scale: 0,
                    opacity: 0,
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 900,
                    damping: 40,
                    duration: 0.3,
                  }}
                >
                  <Text fontSize={12} color="var(--cod-gray)">
                    {displayableValue}
                  </Text>
                  <IconButton
                    icon="CrossIcon"
                    onClick={() => {
                      onChangeFilter(
                        filter.field,
                        String(filter.value)
                          .split(',')
                          .filter(v => v !== value)
                          .map(v => ({ label: '', value: v }))
                      );
                    }}
                    shapeVariant="circle"
                    sizeVariant="small"
                  />
                </Styles.Filter>
              );
            })
        )}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

export default SelectedFilters;
