import React, { useEffect } from 'react';
import {
  Button,
  LargeGreenCheckIcon,
  LargeRedExclamationIcon,
  Modal,
  SpinnerIcon,
  Text,
} from '@point-of-sale/components';
import * as Styles from './styles';
import { useAppSelector } from '@point-of-sale/store';

interface IManualPaymentStatusIndicatorProps {
  isOpen: boolean;
  status: 'PENDING' | 'SUCCESS' | 'FAILED';
  onClose: CallableFunction;
  paymentMode: 'Card' | 'UPI';
  requestValue?: string;
}

const ManualPaymentStatusIndicator = ({
  isOpen,
  onClose,
  status,
  requestValue,
  paymentMode,
}: IManualPaymentStatusIndicatorProps) => {
  const { isLoading, data } = useAppSelector(
    store => store.checkout.processedOrder
  );

  useEffect(() => {
    if (Object.keys(data).length > 0 && !isLoading) {
      onClose();
    } else if (isLoading && !data) {
      onClose();
    }
  }, [data, isLoading]);

  return (
    <Modal isOpen={isOpen} size="regular">
      <Styles.Wrapper>
        {status === 'PENDING' && (
          <>
            <SpinnerIcon size={48} stroke="var(--tulip-tree)" />
            <Text
              fontSize={24}
              weight="bold"
              textAlign="center"
              color="var(--tulip-tree)"
            >
              Your manual {paymentMode} payment of Rs.{requestValue} is in
              progress
            </Text>
          </>
        )}
        {status === 'SUCCESS' && (
          <>
            <LargeGreenCheckIcon />
            <Text
              fontSize={24}
              weight="bold"
              color="var(--chateau-green)"
              textAlign="center"
            >
              Your manual {paymentMode} payment of Rs.{requestValue} is
              successful
            </Text>
            <Button
              colorScheme="dark"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </>
        )}
        {status === 'FAILED' && (
          <>
            <LargeRedExclamationIcon />
            <Text
              fontSize={24}
              textAlign="center"
              color="var(--alizarin-crimson)"
            >
              Your manual {paymentMode} payment of Rs.{requestValue} failed
            </Text>
            <Button
              colorScheme="dark"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </Button>
          </>
        )}
      </Styles.Wrapper>
    </Modal>
  );
};

export default ManualPaymentStatusIndicator;
