import { IconPropsType } from '../types';

const LinkIcon = ({
  size = 24,
  fill = 'var(--royal-blue)',
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.515 4.833a.5.5 0 0 1 .5-.5h3.152a.5.5 0 0 1 .5.5v3.153a.5.5 0 0 1-1 0V6.04l-3.48 3.48a.5.5 0 1 1-.707-.707l3.48-3.48H8.015a.5.5 0 0 1-.5-.5M4.105 3.5A2.17 2.17 0 0 1 6.167 2h5.666A2.167 2.167 0 0 1 14 4.167v5.725a2.17 2.17 0 0 1-1.333 2v.091c0 .7-.354 1.23-.873 1.563-.5.32-1.145.457-1.795.453h-.002l-2.718-.002H4.667c-.772 0-1.443-.242-1.927-.697-.485-.457-.74-1.093-.74-1.8V5.667c0-.577.144-1.122.49-1.531.355-.422.873-.636 1.48-.636zM4 4.5h-.03c-.362 0-.58.12-.717.28-.146.175-.253.463-.253.887V11.5c0 .46.161.823.427 1.072.266.251.678.425 1.24.425H7.28L10 13h.003c.525.003.963-.11 1.252-.296.248-.159.388-.367.409-.645H6.167A2.167 2.167 0 0 1 4 9.892zM6.167 3A1.167 1.167 0 0 0 5 4.167v5.725c0 .645.523 1.167 1.167 1.167h5.666A1.167 1.167 0 0 0 13 9.892V4.167A1.167 1.167 0 0 0 11.833 3z"
      fill={fill}
    />
  </svg>
);
export default LinkIcon;
