import { MutableRefObject } from 'react';
import Address from './Address';
import Contact from './Contact';
import Header from './Header';
import SectionHeading from './SectionHeading';
import Section from './Section';
import ReturnedLineItems from './ReturnedLineItems';
import { LineItemDTOType, OrderDTOType } from '@point-of-sale/schemas';
import { CURRENCY_SYMBOLS, PLACEHOLDERS } from '@point-of-sale/constants';
import { format } from 'date-fns';
import { useAppSelector } from '@point-of-sale/store';
import * as Styles from './styles';

interface IReturnPrintableProps {
  componentRef?: MutableRefObject<HTMLDivElement | null>;
  lineItems: Array<LineItemDTOType>;
  sellerInfo: OrderDTOType['sellerInfo'];
  customerData: {
    customerName?: string;
    customerPhone?: string;
    customerEmail?: string;
  };
}

const ReturnPrintable = ({
  componentRef,
  lineItems,
  sellerInfo,
  customerData,
}: IReturnPrintableProps) => {
  const first = lineItems[0];

  const salesperson = useAppSelector(
    store => store.common.storeStaff.data.records[first.salesStaffId]?.name
  );

  const currency =
    CURRENCY_SYMBOLS[
      (first.costBreakup?.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR'
    ]?.code;

  return (
    <Styles.Wrapper ref={componentRef}>
      <Styles.PrintableWrapper>
        <Header src={sellerInfo?.logo.url ?? ''} />
        <Address data={sellerInfo} />
        <Styles.LineBreak />
        <Contact data={sellerInfo} fashionConsultant={salesperson ?? ''} />
        {/* <SectionHeading heading="Credit Note" /> */}
        {customerData.customerName && (
          <Section
            heading="Customer Details"
            data={[
              {
                key: 'Customer',
                value: customerData.customerName,
              },
              {
                key: 'Contact',
                value: customerData.customerPhone,
              },
              {
                key: 'Email',
                value: customerData.customerEmail || PLACEHOLDERS.NOT_AVAILABLE,
              },
            ]}
          />
        )}
        <SectionHeading heading="Return Invoice" />
        <Section
          heading=""
          data={[
            {
              key: 'Order No.',
              value: first.salesChannelOrderId,
            },
            {
              key: 'Date',
              value: format(new Date(first.createdOn), 'Pp'),
            },
          ]}
        />
        {first.refundRequest?.storeCreditNumber && (
          <Section
            heading=""
            data={[
              {
                key: (
                  <b
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Store Credit Number
                  </b>
                ),
                value: (
                  <b
                    style={{
                      borderBottom: '1px dashed var(--dusty-gray)',
                    }}
                  >
                    {first.refundRequest?.storeCreditNumber}
                  </b>
                ),
              },
            ]}
          />
        )}
        <SectionHeading heading="Tender Details" />
        <ReturnedLineItems data={lineItems} currency={currency} />
        <Section
          heading="Tax Summary"
          data={[
            {
              key: 'CGST',
              value: first.costBreakup.cgst,
            },
            {
              key: 'SGST',
              value: first.costBreakup.sgst,
            },
            {
              key: 'Taxable Amount',
              value: first.costBreakup.chargeableAmount,
            },
          ]}
        />
      </Styles.PrintableWrapper>
    </Styles.Wrapper>
  );
};

export default ReturnPrintable;
