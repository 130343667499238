import { ObjectType } from '@point-of-sale/types';

export function convertArrayToObject<T extends ObjectType>(
  arrayElements: Array<T>,
  idElement: keyof T
): Record<string, T> {
  return arrayElements.reduce(
    (accumulator, eachElement) => ({
      ...accumulator,
      [eachElement[idElement] as string]: eachElement,
    }),
    {} as Record<string, T>
  );
}

export const normalizeArrayToIdsAndRecords = <T extends { id: number }>(
  array: Array<T>
) => {
  const ids = array.map(cartItem => cartItem.id);
  const records = convertArrayToObject(array, 'id');

  return {
    ids,
    records,
  };
};

export function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  // Check if the arrays are of the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Compare each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function updateArrayByKey<T extends Record<string, any>>(
  originalArray: T[],
  replacementArray: T[],
  key: keyof T
): T[] {
  const array2Map = new Map(replacementArray.map(item => [item[key], item]));
  return originalArray.map(item => array2Map.get(item[key]) || item);
}
