import { z } from 'zod';

export const ServiceabilityDTOSchema = z.object({
  id: z.string(),
  skuCode: z.string(),
  mtoTat: z.number().optional(),
  mtcTat: z.number().optional(),
  destinationPincode: z.string().optional(),
  facilityIdToFacilityTatMap: z
    .record(
      z.string(),
      z.object({
        facilityPincode: z.string(),
        facilityName: z.string(),
        bestTat: z.number(),
        jit: z.boolean(),
        express: z.boolean(),
      })
    )
    .optional(),
});

export type ServiceabilityDTOType = z.infer<typeof ServiceabilityDTOSchema>;

type SkuCode = string;

export type INormalizedServiceabilityData = Record<
  SkuCode,
  Array<{
    from: { facilityId: string; pincode: string };
    to: string;
    info: NonNullable<
      ServiceabilityDTOType['facilityIdToFacilityTatMap']
    >[string] & {
      mtoTat: number | undefined;
      mtcTat: number | undefined;
    };
  }>
>;
