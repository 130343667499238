import { useEffect } from 'react';
import {
  searchProducts,
  setProducts,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useFetchOnListEnd } from '@point-of-sale/hooks';
import { SpinnerIcon } from '@point-of-sale/components';
import { NoResults } from '@runway/illustrations';
import Product from '../Product';
import CartItemEdit from '../CartItemEdit';
import * as Styles from './styles';

const ProductList = () => {
  const cartItemEditCache = useAppSelector(
    store => store.pointOfSale.cartItemEditCache
  );

  const {
    data: { ids, records },
    isLoading,
  } = useAppSelector(store => store.pointOfSale.products);

  const dispatch = useAppDispatch();

  function fetchMore() {
    if (isLoading) {
      return;
    }

    dispatch(searchProducts('POS'));
  }

  const { lastItemRef } = useFetchOnListEnd(fetchMore);

  useEffect(() => {
    return () => {
      dispatch(
        setProducts({
          data: {
            ids: [],
            records: {},
          },
        })
      );
    };
  }, []);

  if (cartItemEditCache.isPopulated) {
    return (
      <Styles.ProductListWrapper>
        <CartItemEdit />
      </Styles.ProductListWrapper>
    );
  }

  const renderedItemIds = ids;

  return (
    <Styles.ProductListWrapper>
      {!isLoading && renderedItemIds.length === 0 && <NoResults />}
      {isLoading && renderedItemIds.length === 0 && (
        <Styles.LoaderWrapper>
          <SpinnerIcon size={48} />
        </Styles.LoaderWrapper>
      )}

      {renderedItemIds.map((id, index) => (
        <Product
          key={id}
          data={structuredClone(records[id])}
          // ! 2 is very important here,
          // ! DO NOT CHANGE THIS VALUE
          // ! otherwise POS will explode (literally)
          {...(index === ids.length - 2 ? { componentRef: lastItemRef } : {})}
        />
      ))}
    </Styles.ProductListWrapper>
  );
};

export default ProductList;
