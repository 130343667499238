import { IconPropsType } from '../types';

const CartIcon = ({
  fill = 'var(--cod-gray)',
  size = 20,
  ...props
}: IconPropsType) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.5 1.5A.5.5 0 0 1 1 1h1.5a.5.5 0 0 1 .485.379L3.39 3H15a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13.5 12h-9a.5.5 0 0 1-.491-.408L2.51 3.607 2.11 2H1a.5.5 0 0 1-.5-.5M3.602 4l1.313 7h8.17l1.313-7zM5.5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"
      fill={fill}
    />
  </svg>
);
export default CartIcon;
