import { IconPropsType } from '../types';

const ChevronDownIcon = ({
  fill = 'var(--brown-pod)',
  ...props
}: IconPropsType) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.135 6.158a.5.5 0 0 1 .707-.023L7.5 9.565l3.658-3.43a.5.5 0 0 1 .684.73l-4 3.75a.5.5 0 0 1-.684 0l-4-3.75a.5.5 0 0 1-.023-.707"
      fill={fill}
    />
  </svg>
);
export default ChevronDownIcon;
