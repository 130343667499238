import {
  DeliveryModeType,
  FulfillmentModeType,
  LineItemBrandStatusEnumType,
  LineItemDTOType,
} from '@point-of-sale/schemas';
import { IStepItem } from '@point-of-sale/components';
import { formatToReadableDate, isEmptyObject } from '@point-of-sale/utils';
import { RootStateType, store } from '@point-of-sale/store';

// export const getSteps = (
//   deliveryMode: DeliveryModeType,
//   fulfillmentMode: FulfillmentModeType,
//   lineItemBrandStatus?: LineItemBrandStatusEnumType
// ): Array<string> => {
//   const getBrandSteps = (status: typeof lineItemBrandStatus) => {
//     const allBrandSteps = {
//       SHARED: ['PO sent to brand'],
//       ACCEPTED: ['PO sent to brand', 'PO accepted by brand'],
//       DECLINED: ['PO sent to brand', 'PO rejected by brand'],
//       DISPATCHED: ['PO sent to brand', 'PO accepted by brand', 'Dispatched'],
//       QC_FAILED: [
//         'PO sent to brand',
//         'PO accepted by brand',
//         'Dispatched',
//         'QC failed',
//       ],
//       QC_PASSED: [
//         'PO sent to brand',
//         'PO accepted by brand',
//         'Dispatched',
//         'QC passed',
//       ],
//     };
//     return allBrandSteps[status as keyof typeof allBrandSteps] || [];
//   };

//   const brandSteps: Array<string> = [];

//   switch (deliveryMode) {
//     case 'STORE_PURCHASE': {
//       if (fulfillmentMode === 'ONHAND') {
//         return ['DELIVERED']; // Direct purchase, only one step
//       }
//       break;
//     }

//     case 'STORE_PICKUP': {
//       if (fulfillmentMode === 'ONHAND') {
//         return ['CREATED', 'DISPATCHED', 'DELIVERED']; // Store pickup with on-hand fulfillment
//       }

//       if (fulfillmentMode === 'MTO' || fulfillmentMode === 'MTC') {
//         // Include all brand steps based on the brand status
//         if (lineItemBrandStatus) {
//           brandSteps.push(...getBrandSteps(lineItemBrandStatus));
//         }

//         return ['CREATED', ...brandSteps, 'DISPATCHED', 'DELIVERED'];
//       }
//       break;
//     }

//     case 'HOME_DELIVERY': {
//       if (fulfillmentMode === 'ONHAND') {
//         return ['CREATED', 'DISPATCHED', 'DELIVERED']; // Home delivery with on-hand fulfillment
//       }

//       if (fulfillmentMode === 'MTO' || fulfillmentMode === 'MTC') {
//         // Include all brand steps based on the brand status
//         if (lineItemBrandStatus) {
//           brandSteps.push(...getBrandSteps(lineItemBrandStatus));
//         }

//         return ['CREATED', ...brandSteps, 'DISPATCHED', 'DELIVERED'];
//       }
//       break;
//     }

//     default:
//       return [];
//   }

//   return [];
// };

const getStorePurchaseSteps = (lineItem: LineItemDTOType): Array<IStepItem> => {
  return [
    {
      status: 'COMPLETED',
      title: 'Store purchase complete',
      subTitle: formatToReadableDate(lineItem.createdOn),
    },
  ];
};

const getStepsFromHistory = (lineItem: LineItemDTOType): Array<IStepItem> => {
  const history = lineItem.history || [];

  return history.map(item => ({
    status: 'COMPLETED',
    title: item.status,
    subTitle: formatToReadableDate(item.eventDate),
  }));
};

const getAlterationSteps = (lineItem: LineItemDTOType): Array<IStepItem> => {
  const status = lineItem.status;

  const isSameStorePurchase =
    lineItem.fulfillmentFacilityId === lineItem.customerDeliveryStoreId;

  // * SAME STORE PICKUP
  if (
    lineItem.deliveryMode === 'STORE_PICKUP' &&
    // lineItem.isSameStorePurchase
    isSameStorePurchase
  ) {
    const pickupFacility = (store.getState() as RootStateType).common.facilities
      .data.records[lineItem.fulfillmentFacilityId];
    /**
     * Created -> Alteration pending -> Alteration complete ->
     *  Ready for store pick up (Store name) -> Store pick up complete
     */

    if (lineItem.onhold) {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'IN_PROGRESS',
        },
        {
          title: 'Alteration complete',
          status: 'INCIPIENT',
        },
        {
          title: `Ready for store pick up ${
            pickupFacility?.name ? `(${pickupFacility.name})` : ''
          }`,
          status: 'INCIPIENT',
        },
        {
          title: 'Store pick up complete',
          status: 'INCIPIENT',
        },
      ];
    }

    if (status === 'READY_FOR_PICKUP') {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'COMPLETED',
        },
        {
          title: 'Alteration complete',
          status: 'COMPLETED',
        },
        {
          title: `Ready for store pick up ${
            pickupFacility?.name ? `(${pickupFacility.name})` : ''
          }`,
          status: 'IN_PROGRESS',
        },
        {
          title: 'Store pick up complete',
          status: 'INCIPIENT',
        },
      ];
    }

    return [
      { title: 'Created', status: 'COMPLETED' },
      {
        title: 'Alteration pending',
        status: 'COMPLETED',
      },
      {
        title: 'Alteration complete',
        status: 'COMPLETED',
      },
      {
        title: `Ready for store pick up ${
          pickupFacility?.name ? `(${pickupFacility.name})` : ''
        }`,
        status: 'COMPLETED',
      },
      {
        title: 'Store pick up complete',
        status: 'COMPLETED',
      },
    ];
  }

  // * ANOTHER STORE PICKUP
  if (
    lineItem.deliveryMode === 'STORE_PICKUP' &&
    // !lineItem.isSameStorePurchase
    !isSameStorePurchase
  ) {
    /**
     * Created -> Alteration pending -> Alteration complete ->
     *  Ready for store pick up (Store name) -> Store pick up complete
     */
    const pickupFacility = (store.getState() as RootStateType).common.facilities
      .data.records[lineItem.customerDeliveryStoreId];

    if (lineItem.onhold) {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'IN_PROGRESS',
        },
        {
          title: 'Alteration complete',
          status: 'INCIPIENT',
        },
        {
          title: `Ready for store pick up ${
            pickupFacility?.name ? `(${pickupFacility.name})` : ''
          }`,
          status: 'INCIPIENT',
        },
        {
          title: 'Store pick up complete',
          status: 'INCIPIENT',
        },
      ];
    }

    if (status === 'READY_FOR_PICKUP') {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'COMPLETED',
        },
        {
          title: 'Alteration complete',
          status: 'COMPLETED',
        },
        {
          title: `Ready for store pick up ${
            pickupFacility?.name ? `(${pickupFacility.name})` : ''
          }`,
          status: 'IN_PROGRESS',
        },
        {
          title: 'Store pick up complete',
          status: 'INCIPIENT',
        },
      ];
    }

    return [
      { title: 'Created', status: 'COMPLETED' },
      {
        title: 'Alteration pending',
        status: 'COMPLETED',
      },
      {
        title: 'Alteration complete',
        status: 'COMPLETED',
      },
      {
        title: `Ready for store pick up ${
          pickupFacility?.name ? `(${pickupFacility.name})` : ''
        }`,
        status: 'COMPLETED',
      },
      {
        title: 'Store pick up complete',
        status: 'COMPLETED',
      },
    ];
  }

  // * HOME DELIVERY
  if (lineItem.deliveryMode === 'HOME_DELIVERY') {
    /**
     * Created -> Alteration pending -> Alteration complete ->
     * Shipped to customer -> Home delivery complete
     */

    if (lineItem.onhold) {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'IN_PROGRESS',
        },
        {
          title: 'Alteration complete',
          status: 'INCIPIENT',
        },
        {
          title: 'Shipped to customer',
          status: 'INCIPIENT',
        },
        {
          title: 'Home delivery complete',
          status: 'INCIPIENT',
        },
      ];
    }

    if (status === 'WIP') {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'COMPLETED',
        },
        {
          title: 'Alteration complete',
          status: 'COMPLETED',
        },
        {
          title: 'Shipped to customer',
          status: 'IN_PROGRESS',
        },
        {
          title: 'Home delivery complete',
          status: 'INCIPIENT',
        },
      ];
    }

    if (status === 'DISPATCHED') {
      return [
        { title: 'Created', status: 'COMPLETED' },
        {
          title: 'Alteration pending',
          status: 'COMPLETED',
        },
        {
          title: 'Alteration complete',
          status: 'COMPLETED',
        },
        {
          title: 'Shipped to customer',
          status: 'COMPLETED',
        },
        {
          title: 'Home delivery complete',
          status: 'INCIPIENT',
        },
      ];
    }

    return [
      { title: 'Created', status: 'COMPLETED' },
      {
        title: 'Alteration pending',
        status: 'COMPLETED',
      },
      {
        title: 'Alteration complete',
        status: 'COMPLETED',
      },
      {
        title: 'Shipped to customer',
        status: 'COMPLETED',
      },
      {
        title: 'Home delivery complete',
        status: 'COMPLETED',
      },
    ];
  }

  return [];
};

const getBrandSteps = (
  status: LineItemDTOType['brandStatus']
): Array<IStepItem> => {
  if (!status) return [];

  // Common steps for every status
  const commonSteps: Array<IStepItem> = [
    {
      title: 'PO sent to brand',
      status: 'INCIPIENT',
    },
    {
      title: 'PO accepted by brand',
      status: 'INCIPIENT',
    },
    {
      title: 'Dispatched',
      status: 'INCIPIENT',
    },
  ];

  // Special steps for certain statuses
  const qcFailedStep: IStepItem = {
    title: 'QC failed',
    status: 'INCIPIENT',
  };

  const qcPassedStep: IStepItem = {
    title: 'QC passed',
    status: 'INCIPIENT',
  };

  // Status-specific steps, initially all marked as 'INCIPIENT'
  const allBrandSteps: Record<typeof status, Array<IStepItem>> = {
    ADDED: [...commonSteps],
    INTERNAL_REVIEW_DONE: [...commonSteps],
    SHARED: [...commonSteps],
    ACCEPTED: [...commonSteps],
    DECLINED: [
      ...commonSteps,
      {
        title: 'PO rejected by brand',
        status: 'INCIPIENT',
      },
    ],
    DISPATCHED: [...commonSteps],
    RECEIVED_AT_WAREHOUSE: [...commonSteps],
    QC_FAILED: [...commonSteps, qcFailedStep],
    QC_PASSED: [...commonSteps, qcPassedStep],
  };

  // Map status to the step where the status changes from 'COMPLETED' to 'INCIPIENT'
  const statusOrder: Record<typeof status, number> = {
    ADDED: 1, // Show 'PO sent to brand' as completed, the rest as incipient
    INTERNAL_REVIEW_DONE: 1, // Similar to 'ADDED'
    SHARED: 1, // Similar to 'ADDED'
    ACCEPTED: 2, // Show up to 'PO accepted by brand' as completed
    DECLINED: 2, // Show 'PO rejected by brand' as completed
    DISPATCHED: 3, // Show up to 'Dispatched' as completed
    RECEIVED_AT_WAREHOUSE: 3, // Show up to 'Dispatched'
    QC_FAILED: 3, // Show up to 'Dispatched' as completed, then QC failed
    QC_PASSED: 3, // Show up to 'Dispatched' as completed, then QC passed
  };

  // Get the current steps based on status
  const steps = allBrandSteps[status as keyof typeof allBrandSteps] || [];

  // Determine which steps should be marked as completed and which as incipient
  const currentOrder = statusOrder[status as keyof typeof statusOrder] || 0;
  const processedSteps = steps.map((step, index) => ({
    ...step,
    status: index < currentOrder ? 'COMPLETED' : 'INCIPIENT',
  }));

  return processedSteps as Array<IStepItem>;
};

const getMadeToStorePickupSteps = (
  lineItem: LineItemDTOType
): Array<IStepItem> => {
  return [
    {
      title: 'Created',
      status: 'COMPLETED',
    },
    ...getBrandSteps(lineItem.brandStatus),
    {
      title: 'Ready for store pick up',
      status:
        lineItem.status === 'READY_FOR_PICKUP'
          ? 'IN_PROGRESS'
          : lineItem.status === 'DELIVERED'
          ? 'COMPLETED'
          : 'INCIPIENT',
    },
    {
      title: 'Store pick up complete',
      status: lineItem.status === 'DELIVERED' ? 'COMPLETED' : 'INCIPIENT',
    },
  ];
};

const getMadeToHomeDeliverySteps = (
  lineItem: LineItemDTOType
): Array<IStepItem> => {
  return [
    {
      title: 'Created',
      status: 'COMPLETED',
    },
    ...getBrandSteps(lineItem.brandStatus),
    {
      title: 'Shipped to customer',
      status:
        lineItem.status === 'DISPATCHED'
          ? 'IN_PROGRESS'
          : lineItem.status === 'DELIVERED'
          ? 'COMPLETED'
          : 'INCIPIENT',
    },
    {
      title: 'Home delivery complete',
      status: lineItem.status === 'DELIVERED' ? 'COMPLETED' : 'INCIPIENT',
    },
  ];
};

export const getStepperItems = (lineItem: LineItemDTOType) => {
  if (
    lineItem.deliveryMode === 'STORE_PURCHASE' &&
    lineItem.status === 'DELIVERED'
  ) {
    return getStorePurchaseSteps(lineItem);
  }

  const hasAlterations =
    Object.prototype.hasOwnProperty.call(lineItem, 'alterationEntry') &&
    !isEmptyObject(lineItem.alterationEntry);

  if (lineItem.fulfillmentMode === 'ONHAND' && hasAlterations) {
    return getAlterationSteps(lineItem);
  }

  if (lineItem.fulfillmentMode === 'ONHAND' && !hasAlterations) {
    return getStepsFromHistory(lineItem);
    // return [];
    // return getAlterationSteps(lineItem);
  }

  if (
    (lineItem.fulfillmentMode === 'MTO' ||
      lineItem.fulfillmentMode === 'MTC') &&
    lineItem.deliveryMode === 'STORE_PICKUP'
  ) {
    return getMadeToStorePickupSteps(lineItem);
  }

  if (
    (lineItem.fulfillmentMode === 'MTO' ||
      lineItem.fulfillmentMode === 'MTC') &&
    lineItem.deliveryMode === 'HOME_DELIVERY'
  ) {
    return getMadeToHomeDeliverySteps(lineItem);
  }

  return [];
};
