import { IconPropsType } from '../types';

const CreditNoteIcon = ({
  stroke = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={25}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.218 2c-1.12 0-2.029 2.686-2.029 6h2.029c.978 0 1.465 0 1.768-.335.302-.336.25-.778.145-1.661C21.851 3.67 21.101 2 20.218 2"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.189 8.054v10.592c0 1.511 0 2.267-.465 2.565-.76.486-1.934-.534-2.524-.904-.488-.306-.732-.458-1.002-.467-.293-.01-.542.137-1.069.467l-1.922 1.205c-.52.325-.778.488-1.067.488s-.548-.163-1.066-.488l-1.922-1.205c-.489-.306-.732-.458-1.003-.467-.293-.01-.541.137-1.068.467-.59.37-1.765 1.39-2.525.904-.464-.298-.464-1.053-.464-2.565V8.054c0-2.854 0-4.28.884-5.167S5.283 2 8.128 2h12.073M6.116 6h8.049m-6.037 4H6.116"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.656 10.875c-.833 0-1.51.588-1.51 1.313 0 .724.677 1.312 1.51 1.312s1.509.588 1.509 1.313c0 .724-.676 1.312-1.51 1.312m0-5.25c.658 0 1.217.365 1.424.875m-1.423-.875V10m0 6.125c-.657 0-1.217-.365-1.424-.875m1.424.875V17"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default CreditNoteIcon;
