import React from 'react';
import Product from '../Product';
import { useAppSelector } from '@point-of-sale/store';
import { SpinnerIcon } from '@point-of-sale/components';

const CartItemEdit = () => {
  const { data, isLoading } = useAppSelector(
    store => store.pointOfSale.products
  );

  if (isLoading || data.ids.length === 0) {
    return <SpinnerIcon size={28} />;
  }

  const product = structuredClone(data.records[data.ids[0]]);

  return <Product data={product} />;
};

export default CartItemEdit;
