import { motion } from 'framer-motion';
import { IconPropsType } from '../types';

const SpinnerIcon = ({
  stroke = 'var(--royal-blue)',
  size = 20,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <motion.path
      d="M2.5 10A7.5 7.5 0 1 0 10 2.5"
      stroke={stroke}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      animate={{ rotate: [0, 360] }}
      transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
    />
    <motion.path
      d="M14.166 10A4.166 4.166 0 1 0 10 14.167"
      stroke={stroke}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      animate={{ rotate: [0, -360] }}
      transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
    />
  </svg>
);

export default SpinnerIcon;
