import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)<{ $isEditMode: boolean }>`
  border: 1px solid
    ${({ $isEditMode }) => ($isEditMode ? 'var(--alto)' : 'var(--alto)')};
  padding: 12px;
  background: ${({ $isEditMode }) =>
    $isEditMode ? 'var(--white)' : 'var(--white)'};
`;

export const EditMode = styled.div<{ $isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  cursor: ${({ $isLoading }) => ($isLoading ? 'progress' : 'auto')};
`;

export const EditModeCTAs = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding-top: 8px;
`;

export const ViewMode = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ViewModeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;

  align-items: flex-end;

  div.half {
    width: 50%;
  }
`;
