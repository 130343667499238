import { Button, Input, Text } from '@point-of-sale/components';
import * as Styles from './styles';
import { useEffect, useState } from 'react';
import {
  getGstDetails,
  updateCartGstNumber,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import toast from 'react-hot-toast';
import Address from '../Address';
import BillingAddress from './BillingAddress';
import { useBoolean } from '@point-of-sale/hooks';

interface IGstDetailsProps {
  customerId: number;
}

const GstDetails = ({ customerId }: IGstDetailsProps) => {
  const { isLoading, data, isError } = useAppSelector(
    store => store.pointOfSale.customerGstDetails
  );

  // const customerBillingAddressId = useAppSelector(
  //   store => store.pointOfSale.cart.data.customerBillingAddressId
  // );

  // const customerGstManualDetails = useAppSelector(
  //   store => store.pointOfSale.customerGstManualDetails
  // );

  const [billingAddressMode, setBillingAddressMode] = useState<
    'READ' | 'WRITE' | 'NONE'
  >('NONE');

  const [gst, setGst] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data.gstin && !gst) {
      setGst(data.gstin);
      setBillingAddressMode('READ');
    }
  }, [data]);

  return (
    <Styles.Wrapper>
      <Styles.Row>
        <Input
          isFullWidth
          label="GST Number (optional)"
          placeholder="Enter Customer's GST"
          sizeVariant="extraSmall"
          variant="outline"
          name="gst"
          value={gst}
          onChange={e => setGst(e.target.value)}
        />
        <Button
          isLoading={isLoading}
          sizeVariant="xs"
          colorScheme="dark"
          variant="outline"
          onClick={() => {
            if (!gst) {
              toast.error('Enter a GST number to search');
              return;
            }

            dispatch(
              getGstDetails(gst, (verifiedGstNumber, isVerified) => {
                dispatch(updateCartGstNumber(verifiedGstNumber));

                if (isVerified) {
                  setBillingAddressMode('READ');
                } else {
                  setBillingAddressMode('WRITE');
                }
              })
            );
          }}
        >
          Verify
        </Button>
      </Styles.Row>
      {/* {data.tradeName && (
        <Styles.Row>
          <Text fontSize={12} color="var(--silver-tree)">
            {data.tradeName}
          </Text>
        </Styles.Row>
      )} */}
      {isError && (
        <Styles.Row>
          <Text fontSize={12} color="var(--web-orange)">
            Could not verify
          </Text>
        </Styles.Row>
      )}
      {/* {
        isError && <Styles.Section>
        
        </Styles.Section>
      } */}
      {billingAddressMode === 'READ' && (
        <Styles.Section>
          <Text fontSize={12} color="var(--dove-gray)">
            Billing Address
          </Text>
          <Styles.Row>
            <Text fontSize={14}>{data.legalName}</Text>
          </Styles.Row>
          <Styles.Row>
            <Text fontSize={14}>
              {data.principlePlaceOfBusiness?.address.buildingName}
              {data.principlePlaceOfBusiness?.address.buildingNumber},
              {data.principlePlaceOfBusiness?.address.pinCode},
              {data.principlePlaceOfBusiness?.address.stateCode}
            </Text>
          </Styles.Row>
        </Styles.Section>
      )}
      {billingAddressMode === 'WRITE' && (
        <Styles.Section>
          {/* <Input
              label="Name"
              sizeVariant="extraSmall"
              variant="outline"
              className="half"
              value={formData.name}
              onChange={event => {
                setFormData({
                  ...formData,
                  name: event.target.value,
                });
              }}
            /> */}
        </Styles.Section>
      )}
      {/* {isError && <Address isAddMode customerId={customerId} isFirst />} */}
      {/* {
        isError ?  : 
        <BillingAddress customerId={customerId} />
      } */}
    </Styles.Wrapper>
  );
};

export default GstDetails;
