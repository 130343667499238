import styled, { css } from 'styled-components';
import { ICapsuleProps } from './index';
import { ComplexionType } from '../types';
import { COMPLEXION_VARIANTS } from '../constants/complexion';

export function getSize(size: ICapsuleProps['size']) {
  if (size === 'small') {
    return css`
      height: 24px;
      padding: 0 12px;
      /* border-radius: 12px; */
      border-radius: 1px;
    `;
  }

  return css`
    height: 32px;
    padding: 0 12px;
    border-radius: 1px;
    /* border-radius: 20px; */
  `;
}

function getStyles(
  complexion: ComplexionType,
  essence: ICapsuleProps['essence']
) {
  const config = COMPLEXION_VARIANTS[complexion];

  if (essence === 'light') {
    return css`
      color: ${config.primaryColor};
      background-color: ${config.tertiaryColor};
    `;
  }

  return css`
    color: var(--white);
    background-color: ${config.primaryColor};
  `;
}

export const Wrapper = styled.div<{
  $size: ICapsuleProps['size'];
  $complexion: ComplexionType;
  $essence: ICapsuleProps['essence'];
  $isHoverEnabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: inherit;
  }

  border: 1px solid transparent;

  width: fit-content;

  ${props => getSize(props.$size)}

  ${props => getStyles(props.$complexion, props.$essence)}


  ${props =>
    css`
      border-color: ${COMPLEXION_VARIANTS[props.$complexion].quaternaryColor};
    `};

  /* ${props =>
    props.$isHoverEnabled &&
    css`
      &:hover {
        border-color: ${COMPLEXION_VARIANTS[props.$complexion].quaternaryColor};
      }
    `} */
`;
