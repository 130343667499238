import { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { Text } from '@point-of-sale/components';
import { useAppDispatch, verifyOtp } from '@point-of-sale/store';
import * as Styles from './styles';

interface IInputOtpProps {
  setStep: (step: number) => void;
}

const InputOtp = ({ setStep }: IInputOtpProps) => {
  const [otp, setOtp] = useState('');

  const [error, setError] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (otp.length < 4) {
      return;
    }

    function successCallback() {
      setStep(3);
    }

    dispatch(
      verifyOtp(otp, {
        successCallback,
        failureCallback: (message?: string) => {
          if (message) {
            setError(`Cannot Proceed; Reason: ${message}`);
          }
        },
      })
    );
  }, [otp]);

  return (
    <Styles.FormWrapper>
      <Text>Enter Your OTP</Text>

      <OTPInput
        inputType="number"
        value={otp}
        onChange={value => setOtp(value)}
        renderInput={props => <Styles.StyledOtpInput {...props} />}
        shouldAutoFocus
      />
      {error && (
        <Text color="var(--alizarin-crimson)" wordBreak="break-all">
          {error}
        </Text>
      )}
    </Styles.FormWrapper>
  );
};

export default InputOtp;
