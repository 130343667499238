import { Text } from '@point-of-sale/components';
import MagnifyingGlass from '../../svgs/MagnifyingGlass';
import * as Styles from './styles';
import { FlavorType } from '../../types';

interface INoResultsProps {
  text?: string;
  flavor?: FlavorType;
}

const NoResults = ({
  text = 'No Results',
  flavor = 'blueberry',
}: INoResultsProps) => {
  return (
    <Styles.Wrapper>
      <MagnifyingGlass flavor={flavor} />
      <Text
        fontSize={16}
        color="var(--dove-gray)"
        weight="semibold"
        textAlign="center"
      >
        {text}
      </Text>
    </Styles.Wrapper>
  );
};

export default NoResults;
