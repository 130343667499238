import { IconPropsType } from '../types';

const BASE_HEIGHT = 821 / 10;
const BASE_WIDTH = 222 / 10;

const CoyuLogoVertical = ({
  fill = 'var(--cod-gray)',
  size = 4,
  ...props
}: IconPropsType) => {
  const width = BASE_WIDTH * Number(size);
  const height = BASE_HEIGHT * Number(size);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 222 821"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M145.686 405.537L145.924 405.645C178.525 415.971 195.721 446.305 195.721 472.768C195.721 487.397 191.181 502.135 181.869 514.612C172.076 527.843 157.508 537.525 142.939 546.775C136.491 550.864 129.923 554.951 124.43 559.791C115.834 567.321 111.294 577.54 104.607 585.715C97.323 594.213 86.4567 600.774 73.9175 600.774C62.4543 600.774 52.3032 595.288 44.4224 588.724C26.3909 573.88 15.8815 551.721 15.8815 529.885C15.8404 521.95 17.2526 514.063 20.0612 506.542C23.7628 496.216 28.6573 486.212 36.3005 477.929C48.0038 465.774 62.5717 455.878 78.2156 447.918C87.7688 443.077 96.4861 436.946 101.382 428.125C105.68 421.348 106.875 412.421 112.607 407.364C116.905 403.922 122.519 402.524 128.37 402.524C134.221 402.524 140.431 403.922 145.685 405.536L145.686 405.537ZM55.7653 410.378L55.5263 410.593C50.7501 416.617 48.4814 423.931 43.9428 430.168C41.6713 433.599 39.1165 436.871 36.3001 439.956C15.4053 462.01 -5.3981e-06 485.891 -4.20857e-06 513.105C-3.8701e-06 520.848 0.597376 528.917 3.10473 537.415C6.10009 548.332 10.467 558.906 16.121 568.932C21.1362 577.968 28.4211 586.681 36.1827 593.781C46.5719 603.462 58.6329 609.378 72.7237 609.378C74.6375 609.413 76.5519 609.341 78.4556 609.161C100.429 607.655 119.653 599.587 140.193 593.886C154.523 589.797 169.21 584.205 178.525 573.233C184.973 565.811 190.108 557.529 196.2 549.891C209.693 532.572 221.993 511.919 221.993 490.836C222.021 484.077 220.686 477.371 218.052 471.042C209.693 450.927 197.274 432.21 180.674 416.828C161.806 399.079 144.252 392.517 123.474 392.517C112.727 392.517 101.263 394.239 88.3657 397.036C77.0208 399.725 63.1691 401.338 55.7653 410.374L55.7653 410.378Z"
        fill={fill}
      />
      <path
        d="M120.976 -0.000996984L2.87499 -0.000991821L2.87499 27.7531L133.156 27.7531C163.487 27.7531 212.327 35.3907 212.327 87.2386C212.327 145.755 155.606 149.735 130.529 149.735L2.875 149.735L2.875 178.779L119.304 178.779C190.356 178.779 222 146.616 222 88.6368C222 32.9163 190.953 0.000754718 120.976 0.000757777L120.976 -0.000996984Z"
        fill={fill}
      />
      <path
        d="M2.87548 396.912L150.83 315.806L219.613 316.021L219.613 288.16L150.472 288.16L2.87499 202.967L2.87499 215.552L137.336 291.495L2.87548 364.104L2.87548 396.912Z"
        fill={fill}
      />
      <path
        d="M156.473 627.506L157.747 654.775C182.139 670.897 193.367 693.042 194.825 722.363C197.004 766.184 162.129 810.001 112.747 811.252L112.707 811.339C63.8005 811.707 27.0731 769.771 26.8751 726.195C26.7418 696.844 36.7557 674.23 60.2452 657.048L60.1293 631.567C21.2784 647.088 0.0132898 682.214 0.189559 721.348C0.35279 756.941 22.1714 789.376 56.433 806.852C93.5801 825.801 144.182 826.503 179.111 803.087C207.841 783.828 221.596 751.714 221.453 720.099C221.356 699.262 214.961 678.795 202.535 661.822C191.573 646.511 176.035 634.305 156.473 627.506Z"
        fill={fill}
      />
    </svg>
  );
};

export default CoyuLogoVertical;
