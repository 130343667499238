import { ProductVariantDTOType } from '@point-of-sale/schemas';
import { useAppSelector } from '@point-of-sale/store';
import * as Styles from './styles';
import { omit } from '@point-of-sale/utils';

interface IInventoryTableProps {
  inventory: ProductVariantDTOType['facilityInventory'];
}

const InventoryTable = ({ inventory }: IInventoryTableProps) => {
  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const facilities = useAppSelector(store => store.common.facilities.data);

  const inventoryToShow = omit(inventory ?? {}, [
    currentFacilityId as unknown as string,
  ]);

  return (
    <Styles.Wrapper>
      <Styles.Table>
        <thead>
          <Styles.TableRow>
            <Styles.TableHeader>Facility ID</Styles.TableHeader>
            <Styles.TableHeader>Inventory</Styles.TableHeader>
          </Styles.TableRow>
        </thead>
        <tbody>
          {inventoryToShow &&
            Object.entries(inventoryToShow).map(([facilityId, data]) => {
              const facility =
                facilities.records[facilityId as unknown as number];

              if (!facility) {
                return null;
              }

              return (
                <Styles.TableRow key={facilityId}>
                  <Styles.TableData>
                    {facilities.records[facilityId as unknown as number]?.name},
                    &nbsp;
                    {facility?.address?.cityDisplayName}
                  </Styles.TableData>
                  <Styles.TableData>
                    {data?.availableInventory}
                  </Styles.TableData>
                </Styles.TableRow>
              );
            })}
        </tbody>
      </Styles.Table>
    </Styles.Wrapper>
  );
};

export default InventoryTable;
