import { callApi } from '../services/callApi';
import { ObjectType } from '@point-of-sale/types';

export const getPaymentOptionsApi = (cartId: number) =>
  callApi('GET', `/pos/payment/cart/${cartId}/options`);

export const getPaymentTerminalsApi = () =>
  callApi('GET', `/pos/payment/terminals`);

export const getCartTransactionsApi = (cartId: number) =>
  callApi('GET', `/pos/payment/cart/${cartId}/transaction/`);

export const addCartTransactionsApi = (cartId: number, data: ObjectType) =>
  callApi('POST', `/pos/payment/cart/${cartId}/transaction/add`, data);

export const getTransactionApiStatusApi = (
  cartId: number,
  transactionId: number
) => callApi('GET', `/pos/payment/cart/${cartId}/transaction/${transactionId}`);

export const processTransactionApi = (cartId: string) =>
  callApi('PUT', `/pos/payment/cart/${cartId}/transaction/process`);

export const getPaymentsApi = (salesChannelOrderId: string) =>
  callApi('PUT', `/pos/payment/search?page=1&size=100`, {
    filters: [
      {
        filterType: 'EQ',
        field: 'salesChannelOrderId',
        value: salesChannelOrderId,
      },
      {
        filterType: 'EQ',
        field: 'status',
        value: 'VERIFIED',
      },
    ],
  });
