import { IconPropsType } from '../types';

const DoubleChevronDown = ({
  stroke = 'var(--cod-gray)',
  size = 24,
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.02 4.455 3 3 3-3M1 1l3 3 3-3"
      stroke={stroke}
      strokeWidth={0.826}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DoubleChevronDown;
