import { ENV } from '@point-of-sale/env';
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const isSentryEnabled = ENV.VITE_ENVIRONMENT !== 'local' && ENV.VITE_SENTRY_DSN;

export interface ISentryErrorInfo {
  componentStack: string;
  [key: string]: string;
}

class SentryService {
  static init() {
    if (isSentryEnabled) {
      Sentry.init({
        dsn: ENV.VITE_SENTRY_DSN,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
        ],
        environment: ENV.VITE_ENVIRONMENT,
        tracesSampleRate: 1.0,
      });
    }
  }

  static setUser(user: { id: number | null; email: string | null }) {
    if (!isSentryEnabled) {
      return;
    }
    if (user.id === null || user.email === null) {
      return;
    }

    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
  }

  static unsetUser() {
    if (!isSentryEnabled) {
      return;
    }
    const scope = Sentry.getCurrentScope();
    scope.setUser(null);
  }

  static catchError(error: Error, errorInfo?: ISentryErrorInfo) {
    if (!isSentryEnabled) {
      return;
    }
    Sentry.withScope(scope => {
      if (errorInfo) {
        scope.setExtras(errorInfo);
      }
      Sentry.captureException(error);
    });
  }
}

export { SentryService };

//
