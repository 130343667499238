import { IconPropsType } from '../types';

const BASE_HEIGHT = 241 / 10;
const BASE_WIDTH = 59 / 10;

const CompactPath = ({ fill }: { fill: IconPropsType['fill'] }) => (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M29.374 11.146c.385 1.627.626 3.07.626 4.429 0 2.619-.909 4.836-3.364 7.211-2.13 2.095-4.718 3.66-7.499 4.714-.908.348-1.83.5-2.738.5-2.919 0-5.777-1.553-8.172-3.253C7.174 23.98 6.024 23.328 5 22.518c-1.523-1.175-2.293-3.027-2.859-4.836C1.354 15.093.235 12.669.03 9.898 0 9.654 0 9.41 0 9.173c0-1.779.818-3.301 2.154-4.61.987-.975 2.191-1.895 3.443-2.529A20.3 20.3 0 0 1 9.954.39C11.127.073 12.247 0 13.318 0c3.767 0 7.071 1.943 10.116 4.58.422.348.879.676 1.354.963.867.572 1.878.859 2.709 1.462l.03.03c1.251.932 1.474 2.68 1.847 4.111m-.758 5.044c0-.737-.193-1.444-.668-1.986-.704-.725-1.938-.877-2.877-1.42-1.215-.614-2.064-1.717-2.738-2.917-1.101-1.973-2.467-3.813-4.146-5.287-1.15-.968-2.534-1.583-3.96-2.052a9.9 9.9 0 0 0-3.232-.524c-3.021 0-6.084 1.322-8.136 3.6-.91.992-1.667 2.272-1.667 3.721 0 1.578.908 2.948 2.082 3.868 1.131.847 2.545 1.42 3.587 2.503.668.689 1.233 1.517 1.799 2.333 1.282 1.84 2.618 3.673 4.447 4.91 1.728 1.175 3.768 1.747 5.79 1.747 3.665 0 7.86-2.168 9.286-6.28l.018-.03c.222-.664.415-1.45.415-2.186"
    fill={fill}
  />
);

const LongPath = ({ fill }: { fill: IconPropsType['fill'] }) => (
  <path
    d="m365.819 116.202-.095.189c-9.095 25.865-35.812 39.508-59.12 39.508-12.884 0-25.865-3.602-36.855-10.99-11.653-7.769-20.18-19.327-28.327-30.886-3.602-5.116-7.201-10.327-11.464-14.686-6.632-6.82-15.633-10.421-22.833-15.726-7.485-5.78-13.264-14.4-13.264-24.35 0-9.094 4.832-17.148 10.613-23.4 13.074-14.306 32.591-22.644 51.824-22.644a63.6 63.6 0 0 1 20.559 3.316c9.095 2.936 17.906 6.82 25.202 12.884 10.705 9.285 19.422 20.843 26.432 33.255 4.264 7.58 9.664 14.495 17.434 18.38 5.968 3.41 13.831 4.358 18.285 8.906 3.032 3.41 4.263 7.863 4.263 12.506s-1.231 9.569-2.653 13.737zm-4.264-71.342-.189-.19c-5.306-3.789-11.748-5.59-17.241-9.19a70 70 0 0 1-8.621-6.064C316.079 12.84 295.046.616 271.077.616c-6.82 0-13.927.474-21.411 2.464a130.4 130.4 0 0 0-27.76 10.327c-7.958 3.979-15.632 9.758-21.885 15.916-8.527 8.243-13.738 17.812-13.738 28.992a36 36 0 0 0 .192 4.547c1.326 17.433 8.431 32.686 13.453 48.982 3.601 11.369 8.527 23.022 18.19 30.412 6.538 5.116 13.832 9.19 20.559 14.023 15.254 10.706 33.444 20.464 52.014 20.464a49.1 49.1 0 0 0 17.433-3.126c17.717-6.632 34.202-16.486 47.751-29.656 15.632-14.969 21.411-28.897 21.411-45.381 0-8.527-1.516-17.623-3.979-27.855-2.369-9.001-3.79-19.991-11.748-25.865zM723 96.59V2.89h-24.445v103.364c0 24.064-6.727 62.814-52.393 62.814-51.539 0-55.045-45.003-55.045-64.898V2.89h-25.58v92.374c0 56.372 28.328 81.478 79.394 81.478 49.077 0 78.068-24.633 78.068-80.152zM373.413 2.89l71.435 117.386-.189 54.571h24.538v-54.856L544.233 2.89h-11.085L466.26 109.57 402.309 2.89zM170.315 124.763l-24.018 1.011c-14.2 19.353-33.704 28.261-59.53 29.418-38.595 1.728-77.188-25.941-78.29-65.12l-.076-.032c-.324-38.802 36.61-67.941 74.992-68.098 25.851-.106 45.769 7.839 60.902 26.475l22.442-.092C153.068 17.501 122.13.63 87.662.77c-31.35.129-59.917 17.44-75.31 44.622-16.689 29.473-17.307 69.619 3.316 97.332 16.964 22.794 45.248 33.707 73.094 33.594 18.352-.077 36.379-5.151 51.328-15.009 13.486-8.698 24.236-21.026 30.225-36.546"
    fill={fill}
  />
);

interface ICoyuLogoProps extends IconPropsType {
  mode?: 'COMPACT' | 'LONG';
}

const CoyuLogo = ({
  fill = 'var(--cod-gray)',
  size = 4,
  mode = 'LONG',
  ...props
}: ICoyuLogoProps) => (
  <svg
    width={mode === 'LONG' ? 130 : 30}
    height={28}
    viewBox={mode === 'LONG' ? '0 0 723 177' : '0 0 30 28'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {mode === 'COMPACT' ? (
      <CompactPath fill={fill} />
    ) : (
      <LongPath fill={fill} />
    )}
  </svg>
);
export default CoyuLogo;
