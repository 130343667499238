import { ReactNode } from 'react';
import Text from '../Typography/Text';
import { CheckMarkIcon, CrossIcon } from '../icons';
import * as Styles from './styles';

export type StepperStatusType =
  | 'INCIPIENT'
  | 'IN_PROGRESS'
  | 'COMPLETED'
  | 'CANCELLED';

export interface IStepItem {
  title: ReactNode;
  status: StepperStatusType;
  subTitle?: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
}

export interface IStepperProps {
  className?: string;
  current?: number;
  direction?: 'horizontal' | 'vertical';
  initial?: number;
  percent?: number;
  progressDot?:
    | boolean
    | ((
        iconDot: ReactNode,
        { index, status, title, description }: any
      ) => ReactNode);
  responsive?: boolean;
  size?: 'default' | 'small';

  type?: 'default' | 'navigation' | 'inline';
  onChange?: (current: number) => void;
  items: Array<IStepItem>;
}

const Stepper = ({
  className,
  current = 0,
  direction = 'horizontal',
  initial = 0,
  percent,
  progressDot = false,
  responsive = true,
  size = 'default',
  type = 'default',
  onChange,
  items,
}: IStepperProps) => {
  const handleStepClick = (index: number) => {
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <Styles.StepperContainer
      className={className}
      $direction={direction}
      $size={size}
      $type={type}
    >
      {items.map((item, index) => (
        <Styles.Step
          key={index}
          onClick={() => handleStepClick(index)}
          disabled={item.disabled}
        >
          <Styles.StepIndicator>
            <Styles.StepIcon $status={item.status}>
              {item.status === 'COMPLETED' && (
                <CheckMarkIcon stroke="var(--white)" size={14} />
              )}
              {item.status === 'CANCELLED' && (
                <CrossIcon fill="var(--white)" size={14} />
              )}
            </Styles.StepIcon>
            {index < items.length - 1 && (
              <Styles.StepConnector $status={items[index + 1].status} />
            )}
          </Styles.StepIndicator>
          <Styles.StepContent>
            <Text fontSize={12} color="var(--cod-gray)">
              {item.title}
            </Text>
            {item.subTitle && (
              <Text fontSize={10} color="var(--dove-gray)">
                {item.subTitle}
              </Text>
            )}
            {item.description && (
              <Text fontSize={10} color="var(--dove-gray)">
                {item.description}
              </Text>
            )}
          </Styles.StepContent>
        </Styles.Step>
      ))}
    </Styles.StepperContainer>
  );
};

export default Stepper;
