// import { FallbackProps } from 'react-error-boundary';
// import styled from 'styled-components';
// // import PageNotFound from '../PageNotFound';

// const StyledErrorBoundary = styled.div`
//   width: 100%;
// `;

// function handleReset() {
//   window.location.reload();
// }

// interface IErrorFallbackProps extends FallbackProps {}

// const ErrorFallback = ({
//   resetErrorBoundary = handleReset,
//   error,
// }: IErrorFallbackProps) => {
//   console.log({ error });

//   return (
//     <StyledErrorBoundary>
//       <h1>{error.message}</h1>
//       <p>{error.stack}</p>
//     </StyledErrorBoundary>
//   );
// };

// export default ErrorFallback;

import React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { getRandomMessage } from './messages';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const formatError = (error: Error) => {
  const formattedMessage = `Error: ${error.message}`;
  const stackTrace = error.stack
    ?.split('\n')
    .map((line, index) => <StackLine key={index}>{line}</StackLine>);

  return (
    <>
      <Message>{formattedMessage}</Message>
      <StackTrace>{stackTrace}</StackTrace>
    </>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  return (
    <Container>
      {/* <Title>{getRandomMessage()}</Title> */}
      <ErrorDetails>{formatError(error)}</ErrorDetails>
      <Button onClick={resetErrorBoundary}>Try Again</Button>
    </Container>
  );
};

export default ErrorFallback;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ErrorDetails = styled.div`
  background: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 100%;
`;

const Message = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const StackTrace = styled.div`
  margin-top: 10px;
`;

const StackLine = styled.div`
  font-family: monospace;
  margin-left: 10px;
`;
