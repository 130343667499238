import { v4 as uuidv4 } from 'uuid';
import { Button, Input, RadioGroup } from '@point-of-sale/components';
import {
  collectPendingPayment,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useState } from 'react';
import { useBoolean } from '@point-of-sale/hooks';
import PaymentStatusIndicator from '../PaymentStatusIndicator';
import { emptyFunction } from '@point-of-sale/utils';
import { usePaymentCollectionContext } from '../context';
import { PropsWithSalesPoc } from '../types';
import * as Styles from './styles';

const CreditCard = ({ salesPoc, remainingAmount }: PropsWithSalesPoc) => {
  const paymentOptions = useAppSelector(
    store => store.pendingTasks.paymentOptions.data
  );

  const isLoading = useAppSelector(
    store => store.checkout.cartTransactions.isLoading
  );

  const { salesChannelOrderId, lineItemIds, packageId } =
    usePaymentCollectionContext();

  const [isStatusModalOpen, statusModalOpenActions] = useBoolean();
  const [transactionId, setTransactionId] = useState<number>();

  const [requestValue, setRequestValue] = useState<string>(
    String(remainingAmount)
  );
  const [paymentTerminal, setPaymentTerminal] = useState('');

  const paymentTerminals = Object.values(paymentOptions.records).find(
    item => item.gateway === 'PINE_LABS'
  )?.paymentTerminals;

  const dispatch = useAppDispatch();

  // TODO: make a hook for this
  const onRequestMoney = () => {
    const payload =
      paymentTerminal === 'MANUAL'
        ? {
            paymentGateway: 'MANUAL',
            amount: Number(requestValue),
            paymentMode: 'CREDIT_CARD',
            lineItemIds: lineItemIds,
            packageId: packageId,
          }
        : {
            paymentGateway: 'PINE_LABS',
            amount: Number(requestValue),
            paymentTerminalId: Number(paymentTerminal),
            paymentMode: 'CREDIT_CARD',
            lineItemIds: lineItemIds,
            packageId: packageId,
          };

    const successCallback =
      paymentTerminal === 'MANUAL'
        ? emptyFunction
        : (newTransactionId: number) => {
            setTransactionId(newTransactionId);
            // setPaymentTerminal('');
            statusModalOpenActions.on();
          };

    dispatch(
      collectPendingPayment(salesChannelOrderId, payload, { successCallback })
    );
  };

  const paymentTerminalOptions = [
    ...(paymentTerminals?.map(item => ({
      label: item.displayName,
      value: String(item.id),
    })) ?? []),
    {
      value: 'MANUAL',
      label: 'Manual',
    },
  ];

  return (
    <Styles.Wrapper>
      <RadioGroup
        name="options"
        options={paymentTerminalOptions}
        selectedValue={paymentTerminal}
        onChange={selectedValue => {
          if (selectedValue === 'MANUAL') {
            setPaymentTerminal(selectedValue);
            return;
          }

          const newPaymentTerminal = paymentTerminalOptions.find(
            item => String(item.value) === String(selectedValue)
          );

          if (newPaymentTerminal) {
            setPaymentTerminal(String(newPaymentTerminal.value));
          }
        }}
      />

      <Input
        variant="outline"
        sizeVariant="small"
        type="number"
        placeholder={
          paymentTerminal === 'MANUAL' ? 'Add Amount' : 'Enter request amount'
        }
        isFullWidth
        value={requestValue}
        onKeyDown={e => (e.key === 'e' || e.key === 'E') && e.preventDefault()}
        onChange={e => {
          if (Number(e.target.value) < 0) {
            return;
          }

          if (Number(e.target.value) > remainingAmount) {
            return;
          }

          setRequestValue(e.target.value);
        }}
      />
      <Styles.ButtonGroup>
        <Button
          variant="outline"
          isLoading={isLoading}
          onClick={onRequestMoney}
          isDisabled={!requestValue || !paymentTerminal || !salesPoc}
        >
          {paymentTerminal === 'MANUAL' ? 'Add Amount' : 'Request'}
        </Button>
        <PaymentStatusIndicator
          transactionId={transactionId}
          isOpen={isStatusModalOpen}
          onClose={statusModalOpenActions.off}
        />
      </Styles.ButtonGroup>
    </Styles.Wrapper>
  );
};

export default CreditCard;
