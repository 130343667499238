import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  Button,
  LargeGreenCheckIcon,
  LargeRedExclamationIcon,
  Modal,
  SpinnerIcon,
  Text,
} from '@point-of-sale/components';
import {
  addInvoiceToOrder,
  getCartTransactions,
  resetCheckoutState,
  resetPointOfSaleState,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { useBoolean } from '@point-of-sale/hooks';
import PrintableInvoice from '../../PrintableInvoice';
import { AssetUploaderService } from '@point-of-sale/services';
import * as Styles from './styles';

/**
 * use in checkout page and
 * in order details page to regenerate invoice
 */
interface IPaymentStatusModalProps {
  //
}

const PaymentStatusModal = ({}: IPaymentStatusModalProps) => {
  const { isLoading, isSuccess, isError, data } = useAppSelector(
    store => store.checkout.processedOrder
  );

  const cartTransactions = useAppSelector(
    state => state.checkout.cartTransactions
  );

  const customerGSTIN = useAppSelector(
    state => state.pointOfSale.cart.data.customerGSTIN
  );

  const [isModalOpen, modalOpenActions] = useBoolean();

  useEffect(() => {
    if (Object.keys(data).length > 0 && !isLoading) {
      modalOpenActions.on();
    } else if (isLoading && !data) {
      modalOpenActions.on();
    }
  }, [data, isLoading]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const componentRef = useRef<HTMLDivElement>(null);

  const [isPdfLoading, pdfLoadingActions] = useBoolean();

  const triggerPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `COYU-${data.salesChannelOrderId}`,
  });

  const generatePDF = async () => {
    const content = componentRef.current;

    if (!content) {
      return;
    }

    pdfLoadingActions.on();

    // Convert the content to canvas using html2canvas
    const canvas = await html2canvas(content);

    // Convert 80mm to points (1mm = 2.83465 points)
    const pdfWidthMM = 80;
    const pdfWidthPoints = pdfWidthMM * 2.83465;

    // Calculate the height based on the canvas aspect ratio
    const aspectRatio = canvas.height / canvas.width;
    const pdfHeightPoints = pdfWidthPoints * aspectRatio;

    // Create jsPDF instance with the specified width of 80mm and dynamic height
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'pt', // Use points for precise sizing
      format: [pdfWidthPoints, pdfHeightPoints], // Set custom width and height
    });

    // Convert canvas to image data
    const imgData = canvas.toDataURL('image/png');

    // Add the image to the PDF
    pdf.addImage(
      imgData,
      'PNG',
      0, // X position (no margin)
      0, // Y position (no margin)
      pdfWidthPoints, // Set width exactly to 80mm (converted to points)
      pdfHeightPoints // Proportional height based on the aspect ratio
    );

    const pdfBlob = pdf.output('blob');

    const assetUploader = new AssetUploaderService();

    await assetUploader.uploadFile({
      file: pdfBlob,
      onError: (error: string) => {
        console.error('Error uploading file', error);
      },
      onSuccess: (uploadedFile: any) => {
        console.log('Uploaded File', uploadedFile);

        dispatch(
          addInvoiceToOrder(
            data.id,
            { id: uploadedFile.id, url: uploadedFile.url },
            () => {
              pdfLoadingActions.off();
            }
          )
        );
      },
      usage: 'INVOICE',
    });

    return pdfBlob;
  };

  useEffect(() => {
    if (isModalOpen) {
      dispatch(
        getCartTransactions(data.cartId, () => {
          generatePDF();
        })
      );
    }
  }, [isModalOpen]);

  return (
    <Modal isOpen={isModalOpen} size="regular">
      <Styles.Wrapper>
        {isLoading && (
          <>
            <SpinnerIcon size={80} stroke="var(--tulip-tree)" />
            <Text
              fontSize={28}
              weight="bold"
              textAlign="center"
              color="var(--tulip-tree)"
            >
              Your payment is being verified
            </Text>
            <Text
              fontSize={20}
              textAlign="center"
              weight="bold"
              color="var(--alizarin-crimson)"
            >
              Please don't close this page or press any button!
            </Text>
          </>
        )}
        {isSuccess && (
          <>
            <LargeGreenCheckIcon />
            <Text fontSize={28} weight="bold" textAlign="center">
              Your Payment is successful
            </Text>
            <Text fontSize={20} textAlign="center">
              Thank you for your purchase.
            </Text>
          </>
        )}
        {isError && (
          <>
            <LargeRedExclamationIcon />
            <Text fontSize={28} weight="bold" textAlign="center">
              Your payment was not successful
            </Text>
          </>
        )}
        {isSuccess && (
          <Button
            isLoading={cartTransactions.isLoading}
            onClick={() => {
              triggerPrint();
            }}
          >
            PRINT BILL
          </Button>
        )}
        <Button
          onClick={() => {
            dispatch(resetPointOfSaleState());
            dispatch(resetCheckoutState());
            navigate('/point-of-sale/add-customer');
          }}
        >
          START A NEW BILL
        </Button>

        {isPdfLoading && (
          <Text
            textAlign="center"
            weight="semibold"
            color="var(--alizarin-crimson)"
          >
            Please do not close this window, the bill is being saved.
          </Text>
        )}
      </Styles.Wrapper>

      {Object.keys(data).length > 0 && (
        <div ref={componentRef} style={{ width: '80mm' }}>
          <PrintableInvoice
            data={data}
            type="INVOICE"
            customerGSTIN={customerGSTIN}
            cartTransactions={cartTransactions.data.records}
          />
        </div>
      )}
    </Modal>
  );
};

export default PaymentStatusModal;
