import { IProductListItem, useAppSelector } from '@point-of-sale/store';

const useGetQuantityForSizeByColor = (data: IProductListItem) => {
  // TODO: Review
  const selectedSalesChannelFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );
  const facilities = useAppSelector(
    store => store.common.facilities.data.records
  );

  function getQuantityForSizeByColor(size: string, dataOverride?: any) {
    const selectedColor = data
      ? data.selectedColor
      : dataOverride?.selectedColor;

    // TODO: Fix insider trading
    if (!selectedColor) {
      return {
        store: null,
        warehouse: null,
      };
    }

    const variant = (data ?? dataOverride).variants.find(
      variant => variant.size === size && variant.color === selectedColor
    );

    const facilityGroupId = Object.values(facilities).find(
      item => item.id === selectedSalesChannelFacilityId
    )?.facilityGroupId;

    const correspondingWarehouses = Object.values(facilities).filter(
      item =>
        item.facilityType === 'WAREHOUSE' &&
        item.facilityGroupId === facilityGroupId
    );

    const allWarehouseInventory = correspondingWarehouses.reduce(
      (acc, warehouse) => {
        const warehouseInventory =
          variant?.facilityInventory?.[warehouse.id]?.availableInventory ?? 0;
        return acc + warehouseInventory;
      },
      0
    );

    if (variant?.facilityInventory && selectedSalesChannelFacilityId) {
      return {
        store:
          variant.facilityInventory[selectedSalesChannelFacilityId]
            ?.availableInventory ?? 0,
        warehouse: allWarehouseInventory ?? 0,
      };
    }

    return {
      store: null,
      warehouse: null,
    };
  }

  return { getQuantityForSizeByColor };
};

export default useGetQuantityForSizeByColor;
