import { IconPropsType } from '../types';

const CustomerIcon = ({
  fill = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={15}
    height={14}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 14a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1V9a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-3 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
      fill={fill}
    />
    <path
      d="M6.756 12a4.5 4.5 0 0 1-.229-1.004H1.5c.001-.246.154-.986.832-1.664C2.984 8.68 4.211 8 6.5 8q.39 0 .74.025c.226-.341.496-.65.804-.918Q7.344 7.002 6.5 7c-5 0-6 3-6 4s1 1 1 1z"
      fill={fill}
    />
  </svg>
);
export default CustomerIcon;
