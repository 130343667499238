// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
import { POSActionsTypes } from './constants';
import { PosFilterNames, IPointOfSaleState, ModeType } from './types';
import { CartItemDTOType, FacetsDTOType } from '@point-of-sale/schemas';

export const updatePosFilters = (payload: {
  name: PosFilterNames;
  filter: IFilter;
}) => ({
  type: POSActionsTypes.UPDATE_FILTER,
  payload,
});

export const deletePosFilter = (payload: PosFilterNames) => ({
  type: POSActionsTypes.DELETE_FILTER,
  payload,
});

export const setSearch = (payload: Partial<IPointOfSaleState['search']>) => ({
  type: POSActionsTypes.SET_SEARCH,
  payload,
});

export const setIsBarcodish = (payload: boolean) => ({
  type: POSActionsTypes.SET_IS_BARCODISH,
  payload,
});

export const setProducts = (
  payload: Partial<IPointOfSaleState['products']>
) => ({
  type: POSActionsTypes.SET_PRODUCTS,
  payload,
});

export const setSelectedColor = (payload: {
  id: number;
  value: string | null;
}) => ({
  type: POSActionsTypes.SET_SELECTED_COLOR,
  payload,
});

export const setSelectedSize = (payload: {
  id: number;
  value: string | null;
}) => ({
  type: POSActionsTypes.SET_SELECTED_SIZE,
  payload,
});

export const setMode = (payload: ModeType) => ({
  type: POSActionsTypes.SET_MODE,
  payload,
});

export const setFacets = (payload: FacetsDTOType) => ({
  type: POSActionsTypes.SET_FACETS,
  payload,
});

export const setCustomerDetails = (
  payload: Partial<IPointOfSaleState['customer']>
) => ({
  type: POSActionsTypes.SET_CUSTOMER_DETAILS,
  payload,
});

// TODO: MAYBE refactor this
export const setCartDetails = (
  payload: Partial<IPointOfSaleState['cart']>
) => ({
  type: POSActionsTypes.SET_CART_DETAILS,
  payload,
});

export const resetCartState = () => ({
  type: POSActionsTypes.RESET_CART_STATE,
});

export const setCartItemQuantity = (payload: {
  cartItemId: number;
  quantity: number;
}) => ({
  type: POSActionsTypes.SET_CART_ITEM_QUANTITY,
  payload,
});

export const deleteCartItem = (payload: { cartItemId: number }) => ({
  type: POSActionsTypes.DELETE_CART_ITEM,
  payload,
});

// TODO: handle optimistic UI Updates
export const addToCart = (payload: Array<CartItemDTOType>) => ({
  type: POSActionsTypes.ADD_TO_CART,
  payload,
});

export const setAddressesData = (
  payload: Partial<IPointOfSaleState['addresses']>
) => ({
  type: POSActionsTypes.SET_ADDRESSES_DATA,
  payload,
});

export const setSelectedAddressId = (
  payload: Partial<IPointOfSaleState['selectedAddressId']>
) => ({
  type: POSActionsTypes.SET_SELECTED_ADDRESS_ID,
  payload,
});

export const updateCartItemEditCache = (
  payload: Partial<IPointOfSaleState['cartItemEditCache']>
) => ({
  type: POSActionsTypes.UPDATE_CART_ITEM_EDIT_CACHE,
  payload,
});

export const resetPointOfSaleState = () => ({
  type: POSActionsTypes.RESET,
});

export const setIsEditCustomerInfoModalOpen = (payload: boolean) => ({
  type: POSActionsTypes.SET_ID_EDIT_CUSTOMER_INFO_MODAL_OPEN,
  payload,
});

export const setCustomerInfoModalUpdateCallback = (
  payload: undefined | (() => void)
) => ({
  type: POSActionsTypes.SET_CUSTOMER_INFO_MODAL_UPDATE_CALLBACK,
  payload,
});

export const setPosPagination = (
  payload: Partial<IPointOfSaleState['pagination']>
) => ({
  type: POSActionsTypes.SET_PAGINATION,
  payload,
});

export const setCustomerGstDetails = (
  payload: Partial<IPointOfSaleState['customerGstDetails']>
) => ({
  type: POSActionsTypes.SET_CUSTOMER_GST_DETAILS,
  payload,
});

// export const setCustomerGstManualDetails = (
//   payload: Partial<IPointOfSaleState['customerGstManualDetails']>
// ) => ({
//   type: POSActionsTypes.SET_CUSTOMER_GST_DETAILS,
//   payload,
// });

export const setBuyingWithoutInventoryAllowedForBarcode = (
  payload: string
) => ({
  type: POSActionsTypes.SET_BUYING_WITHOUT_INVENTORY_ALLOWED_FOR_BARCODE,
  payload,
});
