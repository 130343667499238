import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Select, Text } from '@point-of-sale/components';
import { useAppSelector } from '@point-of-sale/store';
import { StoreStaffDTOType } from '@point-of-sale/schemas';
import PaymentCollectionPostOrder from '../PaymentCollectionPostOrder';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';

interface ICollectPaymentProps {
  packageId: number;
  isOpen: boolean;
  onClose: () => void;
}

const CollectPayment = ({
  packageId,
  isOpen,
  onClose,
}: ICollectPaymentProps) => {
  const packageDetails = useAppSelector(
    store => store.pendingTasks.storePickups.data.records[packageId]
  );

  const { ids: storeStaffIds, records: storeStaffRecords } = useAppSelector(
    store => store.common.storeStaff.data
  );

  const [salesPoc, setSalesPoc] = useState<StoreStaffDTOType>();

  useEffect(() => {
    if (packageDetails.costBreakup.amountRemaining === 0) {
      onClose();
    }
  }, [packageDetails.costBreakup]);

  const currencySymbol =
    CURRENCY_SYMBOLS[
      (packageDetails.costBreakup.currency as keyof typeof CURRENCY_SYMBOLS) ??
        'INR'
    ]?.code;

  return (
    <Modal
      heading={
        <Text fontSize={20}>
          Collect {currencySymbol} {packageDetails.costBreakup.amountRemaining}
        </Text>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <SalesPocSelectorWrapper>
          <Text weight="bold" color="var(--black)">
            Sales POC <Text color="var(--alizarin-crimson)">*</Text>
          </Text>
          <Select
            isOutlined
            sizeVariant="regular"
            options={storeStaffIds
              .filter(id => storeStaffIds.includes(id))
              .map(id => ({ label: storeStaffRecords[id].name, value: id }))}
            value={salesPoc?.id ?? ''}
            defaultValue={salesPoc?.id}
            onChange={selectedOption => {
              const newSalesPoc = Object.values(storeStaffRecords).find(
                item => String(item.id) === String(selectedOption.value)
              );
              if (newSalesPoc) {
                setSalesPoc(newSalesPoc);
              }
            }}
          />
        </SalesPocSelectorWrapper>
        <PaymentCollectionWrapper>
          <PaymentCollectionPostOrder
            packageId={packageId}
            {...(salesPoc
              ? { isDisabled: false, salesPoc }
              : {
                  isDisabled: true,
                  disabledReason: 'Please Choose a Sales POC',
                })}
          />
        </PaymentCollectionWrapper>
      </ModalContent>
    </Modal>
  );
};

export default CollectPayment;

const ModalContent = styled.div`
  padding: 32px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SalesPocSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PaymentCollectionWrapper = styled.div`
  border: 1px solid var(--alto);
`;
