import styled, { css } from 'styled-components';

interface IWrapperProps {}

export const Wrapper = styled.div<IWrapperProps>`
  min-width: 200px;

  .react-dropdown-select {
    border: 1px solid var(--alto);
    border-radius: 1px;

    height: 34px;

    padding: 1px 8px;

    font-size: 12px;

    .react-dropdown-select-input {
      font-size: 12px;
    }
  }
`;
