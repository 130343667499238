import * as React from 'react';
import { IconPropsType } from '../types';
const PeopleIcon = ({
  stroke = 'var(--dove-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={8.249}
      cy={6.417}
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
      r={3.667}
    />
    <path
      d="M2.75 19.25v-1.833a3.667 3.667 0 0 1 3.667-3.667h3.666a3.667 3.667 0 0 1 3.667 3.667v1.833m.918-16.38a3.667 3.667 0 0 1 0 7.103m4.582 9.277v-1.833a3.67 3.67 0 0 0-2.75-3.53"
      stroke={stroke}
      strokeWidth={1.75}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PeopleIcon;
