import { Printer } from 'react-thermal-printer';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px;
  /* width: 500px; */
  background: var(--white);

  .rtp-printer {
    /* padding: 52px 16px; */
  }

  .rtp-text {
    //
  }

  .rtp-line {
  }

  .rtp-image {
  }

  .rtp-br {
  }

  .rtp-row {
  }

  .rtp-row-left {
  }

  .rtp-row-center {
  }

  .rtp-row-right {
  }

  /* border: 1px solid red; */
  /* width: 80mm; */
`;

export const PrintableWrapper = styled.div`
  /* border: 1px dashed var(--alto); */
  /* visibility: hidden; */

  * {
    font-family: 'Roboto Mono' !important;
  }
`;

interface ITextProps {
  align?: 'left' | 'center' | 'right';
  weight?: 400 | 700;
}

export const Text = styled.p<ITextProps>`
  font-family: 'Roboto Mono' !important;
  font-size: 12px;
  line-height: 16px;

  text-align: ${props => props.align ?? 'left'};

  font-weight: ${props => props.weight ?? 400};
`;

export const HorizontalRule = styled.div`
  border-bottom: 1px dashed var(--dusty-gray);
  margin: 8px 0;
`;

export const LineBreak = styled.br`
  margin: 4px 0;
`;
