import styled, { css } from 'styled-components';
import { StepperStatusType } from './index';

const getStepStatusColor = (status: StepperStatusType) => css`
  ${() => {
    switch (status) {
      case 'INCIPIENT':
        return css`
          background: var(--silver);
        `;
      case 'IN_PROGRESS':
        return css`
          background: var(--tulip-tree);
        `;
      case 'CANCELLED':
        return css`
          background: var(--flamingo);
        `;
      case 'COMPLETED':
        return css`
          background: var(--silver-tree);
        `;
      default:
        return css`
          background: var(--athens-gray);
        `;
    }
  }}
`;

interface IStepperContainerProps {
  $direction: 'horizontal' | 'vertical';
  $size: 'default' | 'small';
  $type: 'default' | 'navigation' | 'inline';
}

export const StepperContainer = styled.div<IStepperContainerProps>`
  display: flex;
  flex-direction: ${({ $direction }) =>
    $direction === 'vertical' ? 'column' : 'row'};

  min-height: 100px;

  ${({ $size }) =>
    $size === 'small' &&
    css`
      font-size: 12px;
    `}

  ${({ $type }) =>
    $type === 'navigation' &&
    css`
      background-color: #f5f5f5;
      padding: 16px;
    `}
`;

interface IStepProps {
  disabled?: boolean;
}

export const Step = styled.div<IStepProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* overflow: hidden; */
  /* padding: 0 16px; */

  min-width: 125px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

export const StepIndicator = styled.div`
  position: relative;

  height: 40%;
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;

  padding: 0 10px;

  height: 60%;
`;

interface IStepConnectorProps {
  $status: StepperStatusType;
}

export const StepConnector = styled.div<IStepConnectorProps>`
  position: absolute;
  top: 50%;

  right: 0;
  height: 1px;
  left: 0;
  transform: translate(0, -50%);

  ${({ $status }) => getStepStatusColor($status)}
`;

interface IStepIconProps {
  $status: StepperStatusType;
}

export const StepIcon = styled.div<IStepIconProps>`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;

  ${({ $status }) =>
    $status === 'IN_PROGRESS' || $status === 'INCIPIENT'
      ? css`
          height: 9px;
          width: 9px;
        `
      : css`
          height: 16px;
          width: 16px;
        `}

  ${({ $status }) => getStepStatusColor($status)}
`;
