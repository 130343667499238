import { useMemo } from 'react';
import { Button, Capsule, Text } from '@point-of-sale/components';
import { formatNumberToLocale, openInNewTab } from '@point-of-sale/utils';
import { CURRENCY_SYMBOLS } from '@point-of-sale/constants';
import { FacilityDTOType, OrderDTOType } from '@point-of-sale/schemas';
import ImageGallery from '../../ImageGallery';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import * as TableStyles from '../../Table/styles';

interface IRowProps {
  data: OrderDTOType;
  salesFacility: FacilityDTOType;
  lastItemRef?: (node: HTMLElement | null) => void;
}

const Row = ({ data, lastItemRef, salesFacility }: IRowProps) => {
  const navigate = useNavigate();

  const images = useMemo(
    () =>
      Object.values(data.lineItems ?? {}).map(item => ({
        src: item.productImage?.url,
        text: item.productName,
        subText: item.brandName,
      })),
    [data.lineItems]
  );

  return (
    <TableStyles.TableRow
      key={data.salesChannelOrderId}
      ref={lastItemRef}
      $zebra={false}
      onClick={() => navigate(`/orders/${data.id}`)}
      style={{
        cursor: 'pointer',
      }}
    >
      <TableStyles.TableData>
        <ImageGallery
          heading="Product Gallery"
          truncateAfter={3}
          images={[...images]}
        />
      </TableStyles.TableData>
      <TableStyles.TableData>
        {!data.customerId ? (
          <Text fontSize={12} weight="medium">
            GUEST
          </Text>
        ) : (
          <>
            <Text fontSize={12} weight="medium">
              {data.customerName}
            </Text>
            <br />
            <Text fontSize={12} weight="medium">
              {data.customerPhone}
            </Text>
          </>
        )}
      </TableStyles.TableData>
      <TableStyles.TableData>
        {String(data.salesChannelOrderId)}
      </TableStyles.TableData>
      <TableStyles.TableData>
        {Array.from(new Set(data.lineItems.map(item => item.fulfillmentMode)))
          .sort((a, b) => b.localeCompare(a))
          .map(mode => (
            <Capsule
              key={mode}
              essence="light"
              complexion={mode === 'ONHAND' ? 'info' : 'accent'}
              size="small"
              content={mode}
              style={{
                margin: 2,
              }}
            />
          ))}
      </TableStyles.TableData>
      <TableStyles.TableData>
        {format(data.createdOn, 'PPpp')}
      </TableStyles.TableData>
      <TableStyles.TableData>
        {salesFacility?.name},
        <br />
        {salesFacility?.address.cityDisplayName}
      </TableStyles.TableData>
      <TableStyles.TableData>{data.lineItems.length}</TableStyles.TableData>
      <TableStyles.TableData>
        {
          CURRENCY_SYMBOLS[
            (data.currency as keyof typeof CURRENCY_SYMBOLS) ?? 'INR'
          ]?.code
        }{' '}
        {formatNumberToLocale(data.costBreakup.chargeableAmount ?? 0)}
      </TableStyles.TableData>
      <TableStyles.TableData>
        {data.purchaseReceipt && (
          <Button
            leftIcon="ReceiptIcon"
            sizeVariant="xs"
            colorScheme="dark"
            variant="outline"
            onClick={() => {
              if (data.purchaseReceipt?.url) {
                openInNewTab(data.purchaseReceipt.url);
              }
            }}
          >
            Bill copy
          </Button>
        )}
      </TableStyles.TableData>
    </TableStyles.TableRow>
  );
};

export default Row;
