// eslint-disable-next-line @nx/enforce-module-boundaries
import { IFilter } from '@point-of-sale/services';
import { callApi } from '../services/callApi';

import {
  BulkCancelOrderItemDTOType,
  BulkReturnOrderItemDTOType,
} from '@point-of-sale/schemas';

export const getOrdersApi = ({
  page = 1,
  size = 10,
  filters = [],
}: {
  page: number;
  size: number;
  filters: Array<IFilter>;
}) =>
  callApi('POST', `/pos/order/search?page=${page}&size=${size}`, {
    filters,
  });

export const getOrdersDetailsByIdApi = (id: string) =>
  callApi('GET', `/pos/order/${id}`);

export const bulkCancelOrderItemsApi = (data: BulkCancelOrderItemDTOType) =>
  callApi('PUT', `/pos/order/lineitem/bulkCancel`, data);

export const bulkReturnOrderItemsApi = (data: BulkReturnOrderItemDTOType) =>
  callApi('PUT', `/pos/order/lineitem/bulkReturn`, data);

export const addRefundReceiptToOrderApi = ({
  refundId,
  asset,
}: {
  refundId: number;
  asset: {
    id: number;
    url: string;
  };
}) => callApi('PUT', `/pos/order/refund/${refundId}/refund-receipt`, asset);
// }) => callApi('PUT', `/pos/order/refund/${refundId}/refund-receipt`, asset);

// ! DOES NOT EXIST
export const addCreditNoteDocumentToOrderApi = (
  data: BulkReturnOrderItemDTOType
) => callApi('PUT', `/pos/order/lineitem/bulkReturn`, data);
