import { IconPropsType } from '../types';

const ChevronLeftIcon = ({
  fill = 'var(--brown-pod)',
  ...props
}: IconPropsType) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.842 3.135a.5.5 0 0 1 .023.707L5.435 7.5l3.43 3.658a.5.5 0 0 1-.73.684l-3.75-4a.5.5 0 0 1 0-.684l3.75-4a.5.5 0 0 1 .707-.023"
      fill={fill}
    />
  </svg>
);
export default ChevronLeftIcon;
