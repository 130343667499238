import { addDays } from 'date-fns';
import { CheckBox } from '@point-of-sale/components';
import {
  AddressDTOType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import { emptyFunction, formatToReadableDate } from '@point-of-sale/utils';
import { HOME_DELIVERY } from './constants';

import * as Styles from './styles';

interface IDeliverToHomeProps {
  onChange: (promiseDate: string) => void;
  isSelected: boolean;
  shouldShowDelivery: boolean;
  serviceabilityData: INormalizedServiceabilityData[string];
  fulfillmentFacilityId?: number;

  isMTO: boolean;
  isMTC: boolean;

  customerAddress: AddressDTOType | undefined;
  defaultTat: number;
}

const DeliverToHome = ({
  onChange,
  isSelected,
  shouldShowDelivery,
  serviceabilityData,
  isMTC,
  isMTO,
  customerAddress,
  defaultTat,
}: IDeliverToHomeProps) => {
  const defaultDate = '';

  let date = defaultDate;

  const serviceability = serviceabilityData.find(
    s => s.to === customerAddress?.pincode
  );

  if (isMTO) {
    date = serviceability?.info.mtoTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.mtoTat))
      : defaultDate;
  } else if (isMTC) {
    date = serviceability?.info.mtcTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.mtcTat))
      : defaultDate;
  } else if (serviceability?.info.express) {
    date = 'EXPRESS';
  } else {
    date = serviceability?.info.bestTat
      ? formatToReadableDate(addDays(new Date(), serviceability?.info.bestTat))
      : defaultDate;
  }

  return (
    <Styles.Tr $isSelected={isSelected} onClick={() => onChange(date)}>
      <Styles.Td>
        <CheckBox
          shape="round"
          name={HOME_DELIVERY}
          isChecked={isSelected}
          onChange={emptyFunction}
        />
      </Styles.Td>
      <Styles.Td>Deliver To Home</Styles.Td>
      <Styles.Td></Styles.Td>
      <Styles.Td>
        {shouldShowDelivery && (
          <>
            {date !== 'EXPRESS' && <>Est. Delivery: &nbsp;</>}
            {date}
          </>
        )}
      </Styles.Td>
    </Styles.Tr>
  );
};

export default DeliverToHome;
