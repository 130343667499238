import { CartIcon, CustomerIcon, ReceiptIcon } from '@point-of-sale/components';
import { RootStateType, store } from '@point-of-sale/store';
import { openInNewTab, openInSameTab } from '@point-of-sale/utils';

export const getBreadcrumbs = (cartId: number) => [
  {
    icon: CustomerIcon,
    title: 'Add Customer Details',
    identifier: 'add-customer',
    onClick: () => {
      openInNewTab('/point-of-sale/add-customer');
    },
  },
  {
    icon: CartIcon,
    title: 'Add Items',
    identifier: 'cart',
    onClick: () => {
      if (cartId === -1) {
        openInSameTab(`/point-of-sale/add-customer`);
        return;
      }

      openInSameTab(`/point-of-sale/cart?id=${cartId}`);
    },
  },
  {
    icon: ReceiptIcon,
    title: 'Complete Payment',
    identifier: 'checkout',
    onClick: () => {
      if (cartId === -1) {
        openInSameTab(`/point-of-sale/add-customer`);
        return;
      }

      const isCartEmpty =
        (store.getState() as RootStateType).pointOfSale.cart.data.cartItems.ids
          .length === 0;

      if (isCartEmpty) {
        return;
      }

      openInSameTab(`/point-of-sale/checkout?id=${cartId}`);
    },
  },
];
