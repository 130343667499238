import { z } from 'zod';
import { getUnixTime } from 'date-fns';
import toast from 'react-hot-toast';
import { ApiResponseEnum } from '@point-of-sale/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  apiResponseHandler,
  convertArrayToObject,
  getExpressPromiseDate,
  openInSameTab,
  pick,
  setValuesToEmptyString,
} from '@point-of-sale/utils';
import {
  setCustomerDetails,
  setFacets,
  setCartDetails,
  setIsBarcodish,
  setProducts,
  setSelectedColor,
  setSelectedSize,
  addToCart,
  setCartItemQuantity,
  deleteCartItem,
  setAddressesData,
  setSelectedAddressId,
  updateCartItemEditCache,
  setPosPagination,
  setCustomerGstDetails,
  setBuyingWithoutInventoryAllowedForBarcode,
  setSearch,
} from './actions';
import {
  CustomerDTOSchema,
  FacetsDTOSchema,
  CartDTOSchema,
  ProductDTOSchema,
  CartDTOType,
  AddCartItemDTOType,
  CustomerDTOType,
  CartItemDTOType,
  AlterationEntryDTOType,
  ServiceChargeDTOType,
  AddressDTOType,
  AddressDTOSchema,
  DeliveryModeType,
  OrderDTOType,
  OrderDTOSchema,
  GstDetailsDTOType,
  GstDetailsDTOSchema,
  StoreStaffDTOType,
} from '@point-of-sale/schemas';
import { ThunkActionType } from '../store';
import {
  addAddressApi,
  addInvoiceToOrderApi,
  addItemToCartApi,
  addressSearchApi,
  addUserToCartApi,
  cartRefreshApi,
  clearCartApi,
  createCustomerApi,
  customerSearchApi,
  getCartByIdApi,
  getGstDetailsApi,
  initializeCartApi,
  productSearchApi,
  updateAddressApi,
  updateCartApi,
  updateCartItemSkuApi,
  updateCustomerApi,
} from './api';
import {
  getCurrentStoreAndWarehouseFacilityIds,
  getInventoryStatus,
  getWarehouseAndStoreInventoryOfVariantByCurrentStore,
  isBarcodish,
} from './utils';
import { IProductListItem } from './types';
import { normalizeArray } from '../utils/normalizeArray';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FilterBuilder } from '@point-of-sale/services';
import { setProcessedOrder, setSalesPoc } from '../checkout/actions';

export const searchCustomer =
  (
    {
      countryCode,
      phoneNumber,
      email,
      customerId,
    }: (
      | {
          email: string;
          countryCode?: string;
          phoneNumber?: string;
        }
      | {
          email?: string;
          countryCode: string;
          phoneNumber: string;
        }
      | {
          email?: string;
          countryCode?: string;
          phoneNumber?: string;
        }
    ) & { customerId?: string },
    successCallback?: (doesCustomerExist?: boolean) => void
  ): ThunkActionType =>
  async (dispatch, getState) => {
    const filterBuilder = new FilterBuilder();

    if (countryCode && phoneNumber) {
      filterBuilder.addFilter({
        field: 'phone',
        filterType: 'EQ',
        value: `${countryCode}${phoneNumber}`,
      });
      // filterBuilder.addFilter({
      //   field: 'country', // 9637137940
      //   filterType: 'EQ',
      //   value: countryCode,
      // });
    } else if (email) {
      filterBuilder.addFilter({
        field: 'email',
        filterType: 'EQ',
        value: email,
      });
    } else if (customerId) {
      filterBuilder.addFilter({
        field: 'id',
        filterType: 'EQ',
        value: String(customerId),
      });
    } else {
      toast('Please provide either email or phone number');
      return;
    }

    dispatch(
      setCustomerDetails({
        isLoading: true,
      })
    );

    const promise = customerSearchApi({ filters: filterBuilder.build() });

    const responseSchema = z.array(CustomerDTOSchema);

    const response = await apiResponseHandler<z.infer<typeof responseSchema>>(
      promise,
      responseSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerDetails({
          data: {},
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data.length > 0) {
      const customerData = response.data[0];

      dispatch(
        setCustomerDetails({
          data: customerData,
          isLoading: false,
          isSuccess: true,
        })
      );

      dispatch(addressSearch(customerData.id));

      successCallback?.(true);

      return;
    }

    dispatch(
      setCustomerDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          phone: phoneNumber,
          email: email,
        },
      })
    );
    successCallback?.(false);
  };

export const initializeCart =
  (successCallback?: (cartId: string) => void): ThunkActionType =>
  async (dispatch, getState) => {
    const selectedSalesChannel = getState().identity.selectedSalesChannel;

    if (!selectedSalesChannel) {
      toast.error('Cannot initialize cart without a selected sales channel');
      return;
    }

    const selectedAddressId = getState().pointOfSale.selectedAddressId;
    const selectedAddress =
      getState().pointOfSale.addresses.data.records[selectedAddressId];
    const customerGstData = getState().pointOfSale.customerGstDetails.data;

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const payload: Partial<CartDTOType> = {
      salesFacilityId: selectedSalesChannel.facilityId,
      customerPincode: selectedAddress?.pincode,
    };

    if (customerGstData.gstin) {
      payload.customerGSTIN = customerGstData.gstin;
    }

    const promise = initializeCartApi(payload);

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.(String(response.data.id));
  };

export const getCartById =
  (cartId: string, shouldNavigateAway = true): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const doesCartExist = getState().pointOfSale.cart.data.id !== -1;

    const promise = getCartByIdApi(cartId);

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    if (
      shouldNavigateAway &&
      !doesCartExist &&
      response.data?.status === 'ORDER_PLACED'
    ) {
      openInSameTab('/point-of-sale/add-customer');
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
    if (response.data.customerId) {
      dispatch(
        searchCustomer({ customerId: String(response.data.customerId) })
      );
      if (response.data.customerGSTIN) {
        dispatch(getGstDetails(response.data.customerGSTIN));
      }
    }
  };

export const clearCartById =
  (cartId: string): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const promise = clearCartApi(cartId);

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
        isLoading: false,
        isSuccess: true,
      })
    );
  };

export const addItemToCart =
  (
    item: AddCartItemDTOType,
    successCallback?: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    if (item.fulfillmentMode === 'MTO') {
      const otherMtoItems = Object.values(
        getState().pointOfSale.cart.data.cartItems.records
      ).filter(cartItem => cartItem.fulfillmentMode === 'MTO');

      if (otherMtoItems.length > 0) {
        const defaultDeliveryMode = otherMtoItems[0].deliveryMode;

        if (defaultDeliveryMode === 'STORE_PICKUP') {
          item.deliveryMode = defaultDeliveryMode;
          item.customerDeliveryStoreId =
            otherMtoItems[0].customerDeliveryStoreId;
        }

        if (defaultDeliveryMode === 'HOME_DELIVERY') {
          item.deliveryMode = defaultDeliveryMode;
          item.customerDeliveryStoreId =
            otherMtoItems[0].customerDeliveryStoreId;
        }
      }
    }

    const customerId = getState().pointOfSale.customer.data.id;

    const promise = addItemToCartApi({
      id: getState().pointOfSale.cart.data.id as number,
      item,
      customerId,
    });

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      successCallback?.();
      return;
    }

    dispatch(addToCart(response.data.cartItems));

    const existingCart = getState().pointOfSale.cart.data;

    dispatch(
      setCartDetails({
        data: {
          ...existingCart,
          amountPaid: response.data.amountPaid,
          minAmountToBePaid: response.data.minAmountToBePaid,
          chargeableAmount: response.data.chargeableAmount,
        },
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.();
  };

type SearchProductsUsage = 'POS' | 'EXPLORE_PRODUCTS';

export const searchProducts =
  (usage: SearchProductsUsage = 'POS'): ThunkActionType =>
  async (dispatch, getState) => {
    const { search, filters } = getState().pointOfSale;
    const { page, size, shouldFetchMore } = getState().pointOfSale.pagination;

    if (!shouldFetchMore) {
      return;
    }

    dispatch(
      setProducts({
        isLoading: true,
      })
    );

    // * Set whether the search is barcodish
    dispatch(setIsBarcodish(false));

    dispatch(
      updateCartItemEditCache({
        id: -1,
        isPopulated: false,
      })
    );

    // * Handled in UI, when filters is empty -> don't show products
    // ! DO NOT REMOVE THIS COMMENT
    // if (search.length === 0) {
    //   dispatch(
    //     setProducts({
    //       data: {
    //         records: {},
    //         ids: [],
    //       },
    //       isLoading: false,
    //       isSuccess: true,
    //     })
    //   );
    //   return;
    // }

    const filterBuilder = new FilterBuilder();

    if (isBarcodish(search.query)) {
      // let searchForFilter = search.query;

      // if (isBarcodish(search.oldQuery)) {
      //   searchForFilter = search.query.replace(search.oldQuery, '');

      //   dispatch(
      //     setSearch({
      //       query: searchForFilter,
      //     })

      //   );
      // }

      // console.log('inside', search.query);

      filterBuilder.addFilter({
        field: 'variants.barcode',
        filterType: 'EQ',
        value: search.query,
      });

      // dispatch(
      //   setSearch({
      //     query: '',
      //   })
      // );

      // * Set whether the search is barcodish
      dispatch(setIsBarcodish(true));
    } else {
      dispatch(setBuyingWithoutInventoryAllowedForBarcode(''));

      if (search.query.length > 0) {
        filterBuilder.addFilter({
          field: 'query',
          filterType: 'QUERY',
          value: search.query,
        });
      }
    }

    const applicableFilters = [
      ...filterBuilder.build(),
      ...Object.values(filters),
    ];

    const newPage = page + 1;

    const promise = productSearchApi({
      filters: applicableFilters,
      page: newPage,
      size: size,
    });

    const responseSchema = z
      .object({
        results: z.array(ProductDTOSchema),
        facets: FacetsDTOSchema,
      })
      .strict();

    const response = await apiResponseHandler<z.infer<typeof responseSchema>>(
      promise,
      responseSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          error: response.meta?.message,
          isSuccess: false,
        })
      );
      return;
    }

    const shouldAppend = newPage !== 1;

    const normalized = normalizeArray(response.data.results);
    const rawIds = normalized.ids;
    const rawRecords = normalized.records;
    let records = {} as Record<string, IProductListItem>;
    let ids: Array<number> = [];

    // Add default selections here
    rawIds.forEach(id => {
      records[id] = {
        ...rawRecords[id],
        selectedColor: '',
        selectedSize: '',
      };
    });

    if (shouldAppend) {
      ids = [...getState().pointOfSale.products.data.ids, ...rawIds];
      records = {
        ...getState().pointOfSale.products.data.records,
        ...rawRecords,
      };
    } else {
      ids = rawIds;
      records = rawRecords as typeof records;
    }

    // DO NOT REMOVE THIS COMMENT
    // let haveOtherThanDefaultFilters = false;

    const filtersFromState = Object.values(filters);

    // for (let i = 0; i < filtersFromState.length; i++) {
    //   const filter = filtersFromState[i];

    //   if (
    //     filter.value !== DEFAULT_SEARCH_FILTER.value &&
    //     filter.filterType !== DEFAULT_SEARCH_FILTER.filterType
    //   ) {
    //     haveOtherThanDefaultFilters = true;
    //     break;
    //   }
    // }

    // haveOtherThanDefaultFilters || in the condition

    // TODO: TEST THIS
    if (search.query.length !== 0 || filtersFromState.length !== 0) {
      dispatch(
        setProducts({
          isLoading: false,
          isSuccess: true,
          data: {
            ids,
            records,
          },
          timestamp: new Date().getTime(),
        })
      );

      if (rawIds.length === 0) {
        dispatch(setPosPagination({ page: newPage, shouldFetchMore: false }));
      } else {
        dispatch(setPosPagination({ page: newPage, shouldFetchMore: true }));
      }
    } else {
      dispatch(
        setProducts({
          isLoading: false,
          isSuccess: true,
        })
      );
    }

    dispatch(
      setFacets(
        response.data.facets.map(facet => ({
          ...facet,
          values: facet.values.sort((a, b) => a.count - b.count),
        }))
      )
    );

    dispatch(handleAddingToCartViaSearch(usage === 'EXPLORE_PRODUCTS'));
  };

export const handleAutomaticVariantSelection =
  (): ThunkActionType => async (dispatch, getState) => {
    const { ids, records } = getState().pointOfSale.products.data;

    ids.forEach(id => {
      const product = records[id];

      const sizeOptions = product.options.find(
        o => o.name === 'SIZE'
      )?.optionsValues;

      const colorOptions = product.options.find(
        o => o.name === 'COLOR'
      )?.optionsValues;

      // * Select Color if only one color option is available
      if (colorOptions?.length === 1) {
        dispatch(setSelectedColor({ id, value: colorOptions[0].displayName }));
      }

      // * Select Size if only one size option is available, maybe
      if (sizeOptions?.length === 1) {
        // dispatch(setSelectedSize({ id, value: product.options[0].displayName }));
      }
    });
  };

export const handleAddingToCartViaSearch =
  (returnAfterSelectingVariant: boolean): ThunkActionType =>
  async (dispatch, getState) => {
    const mode = getState().pointOfSale.mode;
    const searchQuery = getState().pointOfSale.search.query;
    const { ids, records } = getState().pointOfSale.products.data;

    dispatch(handleAutomaticVariantSelection());

    // * check for barcodish search results here:
    if (isBarcodish(searchQuery)) {
      // * Choose variant in the state
      ids.forEach(id => {
        const variant = records[id].variants.find(
          v => v.barcode === searchQuery
        );

        if (variant) {
          // TODO: separate out variant selection logic from this
          // it might cause bugs
          dispatch(setSelectedColor({ id, value: variant.color }));
          dispatch(setSelectedSize({ id, value: variant.size }));

          const { hasInventoryInCurrentStoreOrWarehouse } =
            getInventoryStatus(variant);

          // * Do not add to cart if no inventory in current store or warehouse
          if (!hasInventoryInCurrentStoreOrWarehouse) {
            dispatch(setBuyingWithoutInventoryAllowedForBarcode(searchQuery));
            return;
          } else {
            dispatch(setBuyingWithoutInventoryAllowedForBarcode(''));
          }

          if (returnAfterSelectingVariant) {
            return;
          }

          const selectedSalesChannelId = getState().identity
            .selectedSalesChannel?.facilityId as number;

          const {
            storeInventory,
            storeFacilityId,
            combinedWarehouseInventory,
            warehouseFacilityIds,
          } = getWarehouseAndStoreInventoryOfVariantByCurrentStore(variant);

          let determinedFulfillmentFacilityId;

          if (storeInventory > 0) {
            determinedFulfillmentFacilityId = storeFacilityId;
          } else if (
            combinedWarehouseInventory > 0 &&
            warehouseFacilityIds.length > 0
          ) {
            determinedFulfillmentFacilityId = warehouseFacilityIds[0];
          }

          const fulfillmentFacilityId =
            determinedFulfillmentFacilityId ?? selectedSalesChannelId;
          const fulfillmentFacility =
            getState().common.facilities.data.records[fulfillmentFacilityId];

          // * fulfillmentFacilityId should be facility Id or warehouse id

          if (mode === 'BUY_NOW') {
            dispatch(
              addItemToCart(
                {
                  skuId: variant.skuId,
                  skuCode: variant.skuCode,
                  fulfillmentFacilityId: fulfillmentFacilityId,
                  quantity: 1,
                  deliveryMode: 'STORE_PURCHASE',
                  fulfillmentMode: 'ONHAND',
                  fulfillmentFacilityGroupId:
                    fulfillmentFacility.facilityGroupId,
                  fulfillmentFacilityType: fulfillmentFacility.facilityType,
                },
                () => {
                  dispatch(
                    setSearch({
                      query: '',
                    })
                  );
                }
              )
            );
          }
        }
      });
    }
  };

export const addCustomerToCart =
  (successCallback: (cartId: string) => void): ThunkActionType =>
  async (dispatch, getState) => {
    const customer = getState().pointOfSale.customer.data;
    const cartId = getState().pointOfSale.cart.data.id;

    if (!customer.id || !cartId || cartId === -1) {
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const promise = addUserToCartApi({
      cartId: String(cartId),
      userId: customer.id,
      customerBillingName: customer.name ?? '',
      customerPhone: customer.phone ?? '',
    });

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback(String(response.data.id));
  };

export const updateQuantityOfCart =
  ({
    cartItemId,
    quantity,
  }: {
    cartItemId: number;
    quantity: number;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cartId = getState().pointOfSale.cart.data.id;

    const promise = updateCartApi(
      quantity === 0
        ? {
            cartId,
            cartItemId,
            operation: 'REMOVE',
          }
        : {
            cartId,
            cartItemId,
            quantity,
            operation: 'UPDATE_QUANTITY',
          }
    );

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    if (quantity === 0) {
      dispatch(deleteCartItem({ cartItemId }));
    } else {
      dispatch(setCartItemQuantity({ cartItemId, quantity }));
    }

    dispatch(
      updateCartItemEditCache({
        id: -1,
        isPopulated: false,
      })
    );

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const upsertPersonalization =
  ({
    personalizationData,
    itemId,
    successCallback,
    promiseDate,
  }: {
    itemId: number;
    personalizationData: AlterationEntryDTOType;
    successCallback?: CallableFunction;
    promiseDate: string;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItem = cart.cartItems.records[itemId];

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
    };

    newCartItem.alterationEntry = personalizationData;

    // TODO: WTF
    if (newCartItem.deliveryMode === 'STORE_PURCHASE') {
      newCartItem.deliveryMode = 'STORE_PICKUP';
      newCartItem.fulfillmentMode = 'ONHAND';

      if (!newCartItem.customerDeliveryStoreId) {
        newCartItem.customerDeliveryStoreId =
          getState().identity.selectedSalesChannel?.facilityId;
      }
    } else if (newCartItem.deliveryMode === 'STORE_PICKUP') {
      if (!newCartItem.customerDeliveryStoreId) {
        newCartItem.customerDeliveryStoreId =
          getState().identity.selectedSalesChannel?.facilityId;
      }
    } else if (newCartItem.deliveryMode === 'HOME_DELIVERY') {
      // newCartItem.deliveryMode = 'STORE_PICKUP';
      // newCartItem.fulfillmentMode = 'MTC';

      if (!newCartItem.customerDeliveryAddressId) {
        const selectedAddressId = getState().pointOfSale.selectedAddressId;
        newCartItem.customerDeliveryAddressId = selectedAddressId;
      }
    }

    if (newCartItem.fulfillmentMode === 'MTO') {
      newCartItem.fulfillmentMode = 'MTC';
    }

    if (promiseDate !== 'EXPRESS') {
      newCartItem.promiseDate = getUnixTime(new Date(promiseDate)).toString();
    } else {
      newCartItem.isExpress = true;
      newCartItem.promiseDate = getExpressPromiseDate();
    }

    cartItemRecords[itemId] = newCartItem;

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };

export const deletePersonalization =
  ({
    alterationEntry,
    itemId,
  }: {
    itemId: number;
    alterationEntry: Partial<AlterationEntryDTOType>;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItem = cart.cartItems.records[itemId];

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
    };

    newCartItem.alterationEntry = setValuesToEmptyString(
      alterationEntry
    ) as AlterationEntryDTOType;

    newCartItem.alterationEntry.customizationCategory = null as any;

    if (newCartItem.fulfillmentMode === 'MTC') {
      newCartItem.fulfillmentMode = 'MTO';
    }

    if (newCartItem.deliveryMode === 'STORE_PICKUP') {
      const { currentStoreAndWarehousesFacilityIds } =
        getCurrentStoreAndWarehouseFacilityIds();

      if (
        currentStoreAndWarehousesFacilityIds.includes(
          newCartItem.fulfillmentFacilityId
        )
      ) {
        newCartItem.deliveryMode = 'STORE_PURCHASE';
        newCartItem.fulfillmentMode = 'ONHAND';
      }
    }

    cartItemRecords[itemId] = newCartItem;

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);

    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

/**
 *
 * Changes the basic details of a cart item
 * like delivery mode, fulfillment mode, etc.
 * cannot update the sku itself
 */
export const updateSingleCartItemDestinations =
  (info: {
    cartItemId: number;
    customerDeliveryStoreId?: number;
    deliveryMode?: DeliveryModeType;
    isExpress?: boolean;
    promiseDate?: string;
    customerDeliveryAddressId?: number;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItem = cart.cartItems.records[info.cartItemId];

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
    };

    // TODO: Modularize this logic
    if (info.deliveryMode) {
      newCartItem.deliveryMode = info.deliveryMode;
    }
    if (info.customerDeliveryStoreId) {
      newCartItem.customerDeliveryStoreId = info.customerDeliveryStoreId;
      newCartItem.customerDeliveryAddressId = null;
    }
    if (info.promiseDate) {
      const parsableDate =
        typeof info.promiseDate === 'number'
          ? info.promiseDate
          : Number.isNaN(Number(info.promiseDate))
          ? info.promiseDate
          : Number(info.promiseDate);

      newCartItem.promiseDate = getUnixTime(new Date(parsableDate)).toString();
    }
    if (info.isExpress) {
      newCartItem.isExpress = true;
    } else {
      newCartItem.isExpress = false;
    }
    if (info.customerDeliveryAddressId) {
      newCartItem.customerDeliveryAddressId = info.customerDeliveryAddressId;
      delete newCartItem.customerDeliveryStoreId;
    }

    cartItemRecords[info.cartItemId] = newCartItem;

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const updateMultipleCartItemDestinations =
  (info: {
    cartItemIds: Array<number>;
    customerDeliveryStoreId?: number;
    deliveryMode?: DeliveryModeType;
    isExpress?: boolean;
    promiseDate?: string;
    customerDeliveryAddressId?: number;
  }): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = structuredClone(getState().pointOfSale.cart.data);

    const newCartItems = pick(cart.cartItems.records, info.cartItemIds);

    // TODO: Modularize this logic
    Object.values(newCartItems).forEach(item => {
      if (info.deliveryMode) {
        item.deliveryMode = info.deliveryMode;
      }
      if (info.customerDeliveryStoreId) {
        item.customerDeliveryStoreId = info.customerDeliveryStoreId;
        item.customerDeliveryAddressId = null;
      }
      if (info.isExpress) {
        item.isExpress = true;
      } else {
        item.isExpress = false;
      }
      if (info.promiseDate) {
        const parsableDate =
          typeof info.promiseDate === 'number'
            ? info.promiseDate
            : Number.isNaN(Number(info.promiseDate))
            ? info.promiseDate
            : Number(info.promiseDate);

        // ! Chrome Devtools can parse String Numbers to Dates, but the Runtime Cannot
        item.promiseDate = getUnixTime(new Date(parsableDate)).toString();
      }
      if (info.customerDeliveryAddressId) {
        item.customerDeliveryAddressId = info.customerDeliveryAddressId;
        delete item.customerDeliveryStoreId;
      }
    });

    const cartItemRecords: Record<number, CartItemDTOType> = {
      ...cart.cartItems.records,
      ...newCartItems,
    };

    // TODO: define a generic way of creating DTO from redux state
    const payload = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cartItemRecords[id]),
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const modifyServiceCharge =
  (
    charge: ServiceChargeDTOType | null, // null means remove charge
    level: 'TOP' | 'ITEM',
    itemId?: string | number, // required if level is 'item'
    index?: number, // required if removing a charge
    successCallback?: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    const updatedCartItems = cart.cartItems.ids.map(id => {
      const cartItem = cart.cartItems.records[id];

      if (level === 'ITEM' && String(id) === String(itemId)) {
        return {
          ...cartItem,
          customCharges: charge
            ? [...(cartItem.customCharges || []), charge]
            : cartItem.customCharges?.filter((_, i) => i !== index),
        };
      }

      return cartItem;
    });

    const updatedCustomCharges =
      level === 'TOP'
        ? charge
          ? [...(cart.customCharges || []), charge]
          : cart.customCharges?.filter((_, i) => i !== index)
        : cart.customCharges;

    const payload: CartDTOType = {
      ...cart,
      cartItems: updatedCartItems,
      customCharges: updatedCustomCharges,
    };

    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };

export const applyUnApplyCouponCodeToCart =
  (
    couponCode: string,
    successCallback?: CallableFunction,
    failureCallback?: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      couponCode,
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    if (response.data.couponErrorMessage) {
      failureCallback?.();
    } else {
      successCallback?.();
    }
  };

export const setSalesStaffIdOfCart =
  (
    salesStaff: StoreStaffDTOType,
    successCallback?: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const cart = getState().pointOfSale.cart.data;

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      salesStaffId: salesStaff.id,
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(setSalesPoc(salesStaff));

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );

    successCallback?.();
  };

export const addressSearch =
  (customerId: number): ThunkActionType =>
  async dispatch => {
    dispatch(
      setAddressesData({
        isLoading: true,
      })
    );

    const promise = addressSearchApi(customerId);
    const response = await apiResponseHandler<Array<AddressDTOType>>(
      promise,
      z.array(AddressDTOSchema)
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setAddressesData({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setAddressesData({
        isLoading: false,
        isSuccess: true,
        data: normalizeArray(response.data),
      })
    );

    const defaultAddress = response.data.find(address => address.isDefault);
    if (defaultAddress) {
      dispatch(setSelectedAddressId(defaultAddress.id));
    }
  };

export const createNewCustomer =
  (
    data: Partial<CustomerDTOType>,
    successCallback?: VoidFunction
  ): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCustomerDetails({
        isLoading: true,
      })
    );

    const country = '+91';

    data.phone = `${country}${data.phone}`;

    const promise = createCustomerApi({ ...data, country });

    const response = await apiResponseHandler<CustomerDTOType>(
      promise,
      CustomerDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerDetails({
          data: {},
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data) {
      dispatch(
        setCustomerDetails({
          data: response.data,
        })
      );
    }

    dispatch(
      setCustomerDetails({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback?.();
  };

export const updateCustomer =
  (
    customerId: number,
    data: Partial<CustomerDTOType>,
    successCallback: VoidFunction
  ): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCustomerDetails({
        isLoading: true,
      })
    );

    const promise = updateCustomerApi({ customerId, data });

    const response = await apiResponseHandler<CustomerDTOType>(
      promise,
      CustomerDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerDetails({
          data: {},
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data) {
      dispatch(
        setCustomerDetails({
          data: response.data,
        })
      );
    }

    dispatch(
      setCustomerDetails({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback();
  };

export const addAddressToCustomer =
  (
    customerId: number,
    data: Partial<AddressDTOType>,
    successCallback: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setAddressesData({
        isLoading: true,
      })
    );

    const promise = addAddressApi({ customerId, ...data });

    const response = await apiResponseHandler<AddressDTOType>(
      promise,
      AddressDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerDetails({
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data) {
      const existingAddresses = getState().pointOfSale.addresses.data;
      dispatch(
        setAddressesData({
          data: {
            records: {
              ...existingAddresses.records,
              [response.data.id]: response.data,
            },
            ids: [...existingAddresses.ids, response.data.id],
          },
        })
      );
    }

    dispatch(assignCustomerDeliveryAddressToCart(response.data.id));

    dispatch(
      setAddressesData({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback();
  };

export const updateAddressOfCustomer =
  (
    addressId: number,
    address: Partial<AddressDTOType>,
    successCallback: VoidFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setAddressesData({
        isLoading: true,
      })
    );

    const promise = updateAddressApi(addressId, address);

    const response = await apiResponseHandler<AddressDTOType>(
      promise,
      AddressDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setAddressesData({
          isLoading: false,
          isSuccess: true,
        })
      );
      return;
    }

    if (response.data) {
      const existingAddresses = getState().pointOfSale.addresses.data;

      dispatch(
        setAddressesData({
          data: {
            records: {
              ...existingAddresses.records,
              [response.data.id]: response.data,
            },
            ids: existingAddresses.ids,
          },
        })
      );
    }

    dispatch(
      setAddressesData({
        isLoading: false,
        isSuccess: true,
      })
    );

    successCallback();
  };

export const assignCustomerDeliveryAddressToCart =
  (customerDeliveryAddressId: number): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(setSelectedAddressId(customerDeliveryAddressId));

    const cart = getState().pointOfSale.cart.data;

    if (cart.id === -1) {
      return;
    }

    const selectedAddress =
      getState().pointOfSale.addresses.data.records[customerDeliveryAddressId];

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      customerPincode: selectedAddress.pincode,
      cartItems: cart.cartItems.ids.map(id => {
        const cartItem = cart.cartItems.records[id];
        return {
          ...cartItem,
          ...(cartItem.deliveryMode === 'HOME_DELIVERY' && {
            customerDeliveryAddressId,
          }),
        };
      }),
    };

    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

export const getProductBySkuCode =
  ({ skuCode }: { skuCode: string }): ThunkActionType =>
  async dispatch => {
    dispatch(
      setProducts({
        isLoading: true,
      })
    );

    dispatch(setIsBarcodish(false));

    const filterBuilder = new FilterBuilder();

    filterBuilder.addFilter({
      field: 'variants.skuCode',
      filterType: 'EQ',
      value: skuCode,
    });

    const promise = productSearchApi({
      filters: filterBuilder.build(),
      page: 1,
      size: 10,
    });

    const responseSchema = z
      .object({
        results: z.array(ProductDTOSchema),
        facets: FacetsDTOSchema,
      })
      .strict();

    const response = await apiResponseHandler<z.infer<typeof responseSchema>>(
      promise,
      responseSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');

      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          error: response.meta?.message,
          isSuccess: false,
        })
      );
      return;
    }

    if (response.data.results.length !== 1) {
      // TODO: Add sentry
      // toast.error('Multiple products found with same SKU code');
      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          isSuccess: false,
        })
      );
      return;
    }

    const product = response.data.results[0];
    const variant = product.variants.find(v => v.skuCode === skuCode);

    if (!variant) {
      toast.error('Cannot find product');
      dispatch(
        setProducts({
          isLoading: false,
          isError: true,
          isSuccess: false,
        })
      );
      return;
    }

    // Modularize this , repetitive code
    const ids = response.data.results.map(product => product.id);
    const rawRecords = convertArrayToObject(response.data.results, 'id');
    const records = {} as Record<string, IProductListItem>;

    ids.forEach(id => {
      records[id] = {
        ...rawRecords[id],
        selectedColor: variant.color,
        selectedSize: variant.size,
      };
    });

    dispatch(
      setProducts({
        isLoading: false,
        isSuccess: true,
        data: {
          ids,
          records,
        },
      })
    );
  };

/**
 *
 *  Can update the SKU of a cart item
 * things like color, size, skuCode, quantity etc.
 */
export const updateCartItemStockKeepingUnit =
  (
    data: {
      skuCode: string;
      quantity: number;
      fulfillmentMode: string;
      deliveryMode: string;
      fulfillmentFacilityId: number | null;
      customerDeliveryStoreId: number | undefined;
    },
    turnOffLoader: CallableFunction
  ): ThunkActionType =>
  async (dispatch, getState) => {
    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    const promise = updateCartItemSkuApi({
      cartId: getState().pointOfSale.cart.data.id as number,
      cartItemId: getState().pointOfSale.cartItemEditCache.id,
      data,
    });
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      turnOffLoader();
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
    turnOffLoader();
  };

export const addInvoiceToOrder =
  (
    orderId: number,
    asset: {
      id: number;
      url: string;
    },
    callback: CallableFunction
  ): ThunkActionType =>
  async dispatch => {
    const promise = addInvoiceToOrderApi({
      orderId,
      asset,
    });
    const response = await apiResponseHandler<OrderDTOType>(
      promise,
      OrderDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );

      return;
    }

    dispatch(setProcessedOrder({ data: response.data }));

    callback();
  };

export const getGstDetails =
  (
    gstNumber: string,
    cartUpdater?: (gstNumber: string, isVerified: boolean) => void
  ): ThunkActionType =>
  async dispatch => {
    dispatch(
      setCustomerGstDetails({
        isLoading: true,
      })
    );

    const promise = getGstDetailsApi(gstNumber);
    const response = await apiResponseHandler<GstDetailsDTOType>(
      promise,
      GstDetailsDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCustomerGstDetails({
          isLoading: false,
          isError: true,
        })
      );
      cartUpdater?.(gstNumber, false);
      return;
    }

    dispatch(
      setCustomerGstDetails({
        isLoading: false,
        data: response.data,
      })
    );

    if (response.data.gstin) {
      cartUpdater?.(response.data.gstin, true);

      // create new address,
    } else {
      cartUpdater?.(gstNumber, false);
      dispatch(
        setCustomerGstDetails({
          isError: true,
          data: {
            ...response.data,
            gstin: gstNumber,
          },
        })
      );
    }
  };

export const updateCartGstNumber =
  (gstNumber: string): ThunkActionType =>
  async (dispatch, getState) => {
    const cart = structuredClone(getState().pointOfSale.cart.data);

    if (cart.id === -1) {
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: true,
      })
    );

    // TODO: define a generic way of creating DTO from redux state
    const payload: CartDTOType = {
      ...cart,
      cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
      customerGSTIN: gstNumber,
    };
    const promise = cartRefreshApi(payload);
    const response = await apiResponseHandler<CartDTOType>(
      promise,
      CartDTOSchema
    );

    if (response.type === ApiResponseEnum.Failure) {
      toast.error(response.meta?.message ?? 'Something went wrong');
      dispatch(
        setCartDetails({
          isLoading: false,
          isError: true,
        })
      );
      return;
    }

    dispatch(
      setCartDetails({
        isLoading: false,
        isSuccess: true,
        data: {
          ...response.data,
          cartItems: normalizeArray(response.data.cartItems),
        },
      })
    );
  };

// export const updateCartCustomerBillingAddressId =
//   (addressId: string): ThunkActionType =>
//   async (dispatch, getState) => {
//     dispatch(
//       setCartDetails({
//         isLoading: true,
//       })
//     );

//     const cart = structuredClone(getState().pointOfSale.cart.data);

//     // TODO: define a generic way of creating DTO from redux state
//     const payload: CartDTOType = {
//       ...cart,
//       cartItems: cart.cartItems.ids.map(id => cart.cartItems.records[id]),
//       customerBillingAddressId: addressId,
//     };
//     const promise = cartRefreshApi(payload);
//     const response = await apiResponseHandler<CartDTOType>(
//       promise,
//       CartDTOSchema
//     );

//     if (response.type === ApiResponseEnum.Failure) {
//       if (response.meta?.message) {
//         toast.error(response.meta?.message);
//       }
//       dispatch(
//         setCartDetails({
//           isLoading: false,
//           isError: true,
//         })
//       );
//       return;
//     }

//     dispatch(
//       setCartDetails({
//         isLoading: false,
//         isSuccess: true,
//         data: {
//           ...response.data,
//           cartItems: normalizeArray(response.data.cartItems),
//         },
//       })
//     );
//   };
