import { IconPropsType } from '../types';

const PercentageIcon = ({
  stroke = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.385 3.97a2.166 2.166 0 0 1 3.229 0l.758.848a2.17 2.17 0 0 0 1.735.718l1.137-.063a2.167 2.167 0 0 1 2.283 2.284l-.063 1.136a2.17 2.17 0 0 0 .718 1.733l.847.759a2.167 2.167 0 0 1 0 3.23l-.847.758a2.17 2.17 0 0 0-.718 1.734l.063 1.137a2.165 2.165 0 0 1-2.284 2.283l-1.136-.063a2.17 2.17 0 0 0-1.734.718l-.758.848a2.166 2.166 0 0 1-3.23 0l-.758-.848a2.17 2.17 0 0 0-1.734-.718l-1.138.063a2.167 2.167 0 0 1-2.282-2.284l.062-1.136a2.17 2.17 0 0 0-.718-1.733l-.847-.759a2.167 2.167 0 0 1 0-3.23l.847-.758a2.17 2.17 0 0 0 .718-1.734l-.062-1.137a2.166 2.166 0 0 1 2.283-2.282l1.137.063a2.17 2.17 0 0 0 1.733-.718z"
      stroke={stroke}
      strokeWidth={1.5}
    />
    <path
      d="M10.291 10.292h.01v.01h-.01zm5.417 5.417h.01v.01h-.01z"
      stroke={stroke}
      strokeWidth={2.25}
      strokeLinejoin="round"
    />
    <path
      d="m16.25 9.75-6.5 6.5"
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PercentageIcon;
