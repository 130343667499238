import { IconPropsType } from '../types';

const DeleteIcon = ({
  size = 16,
  stroke = 'var(--cod-gray)',
  ...props
}: IconPropsType) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.307 4.077v7.262c0 .689 0 1.033.135 1.297.118.231.306.42.537.538.263.134.608.134 1.296.134h3.45c.688 0 1.032 0 1.294-.134.232-.119.42-.307.539-.538.134-.264.134-.608.134-1.296V4.077m-7.385 0h1.231m-1.23 0H3.076m8.615 0h-1.23m1.23 0h1.23m-7.384 0h4.923m-4.923 0c0-.574 0-.86.094-1.086a1.23 1.23 0 0 1 .666-.667c.226-.093.513-.093 1.086-.093h1.231c.574 0 .86 0 1.086.093a1.23 1.23 0 0 1 .667.667c.093.226.093.512.093 1.086"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
