import { z } from 'zod';
import {
  CardMetaDataDTOSchema,
  OrderDTOSchema,
  UpiMetaDataDTOSchema,
} from '../orderManagementSystem';

const PaymentGatewayEnum = z.enum(['CASH', 'PINE_LABS', 'MANUAL']);

export const PaymentOptionDTOSchema = z.object({
  id: z.number(),
  gateway: z.string(),
  name: z.string(),
  options: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
        meta: z
          .object({
            // * `EXPIRY_DATE` this should not be optional
            EXPIRY_DATE: z.number().optional(),
            AVAILABLE_CREDIT: z.number(),
            EXPIRED: z.boolean(),
          })
          .optional(),
      })
    )
    .optional(),
  paymentTerminals: z
    .array(
      z.object({
        id: z.number(),
        provider: z.string(),
        displayName: z.string(),
      })
    )
    .optional(),
});

export type PaymentOptionDTOType = z.infer<typeof PaymentOptionDTOSchema>;

export const PaymentTerminalDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  provider: z.string(),
  config: z.object({
    STORE_ID: z.string(),
    CLIENT_ID: z.string(),
    MERCHANT_ID: z.string(),
    SECURITY_TOKEN: z.string(),
  }),
  displayName: z.string(),
  enabled: z.boolean(),
  salesChannelId: z.number(),
});

export type PaymentTerminalDTOType = z.infer<typeof PaymentTerminalDTOSchema>;

export const CartTransactionDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  cartId: z.string(),
  salesChannelOrderId: z.string(),
  posTerminalId: z.number(),
  paymentGateway: z.string(),
  status: z.string(),
  amount: z.number(),
  salesChannelId: z.number(),
  paymentTerminalId: z.number(),
  paymentMode: z.string(),
  cartMinAmountToBePaid: z.number().optional(),
  cartTotalAmountToBePaid: z.number().optional(),
  cartTotalAmountPaid: z.number().optional(),
  order: OrderDTOSchema.optional(),
  paymentModeDisplayName: z.string().optional(),
  changeAmount: z.number().optional(),
  metadata: z
    .intersection(CardMetaDataDTOSchema, UpiMetaDataDTOSchema)
    .optional(),
});

export type CartTransactionDTOType = z.infer<typeof CartTransactionDTOSchema>;

export const CartTransactionAddDTOSchema = z.object({
  paymentGateway: z.string(),
  amount: z.number().optional(),
  changeAmount: z.number().optional(),
  paymentMode: z.string(),
  remarks: z.string().optional(),
  paymentTerminalId: z.number().optional(),
  metadata: z.record(z.literal('CREDIT_NOTE_REF'), z.string()).optional(),
});

export type CartTransactionAddDTOType = z.infer<
  typeof CartTransactionAddDTOSchema
>;
