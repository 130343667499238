import { IdentityActionsTypes } from './constants';
import { IAuthState } from './types';

export const resetAuthState = () => ({
  type: IdentityActionsTypes.RESET_STATE,
});

export const setToken = (payload: string | null) => ({
  type: IdentityActionsTypes.SET_TOKEN,
  payload,
});

export const setUser = (payload: IAuthState['user']) => ({
  type: IdentityActionsTypes.SET_USER,
  payload,
});

export const setAuthenticationInfo = (
  payload: Pick<IAuthState, 'token' | 'refreshToken' | 'erpUser'>
) => ({
  type: IdentityActionsTypes.SET_AUTHENTICATION_INFO,
  payload,
});

export const setSelectedSalesChannel = (
  payload: IAuthState['selectedSalesChannel']
) => ({
  type: IdentityActionsTypes.SET_SELECTED_SALES_CHANNEL,
  payload,
});
