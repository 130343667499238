export const RETURN_REASONS = [
  {
    label: 'Incorrect size/ did not fit',
    value: 'INCORRECT_SIZE',
  },
  {
    label: 'Item defective/ damaged',
    value: 'DEFECTIVE_DAMAGED',
  },
  {
    label: 'Wrong item delivered',
    value: 'WRONG_ITEM_DELIVERED',
  },
  {
    label: 'Product not as described',
    value: 'NOT_AS_DESCRIBED',
  },
  {
    label: 'Quality not as expected',
    value: 'NOT_AS_EXPECTED',
  },
  {
    label: 'Change of mind',
    value: 'CHANGE_OF_MIND',
  },
  {
    label: 'Color mismatch',
    value: 'COLOR_MISMATCH',
  },
  {
    label: 'Late delivery',
    value: 'LATE_DELIVERY',
  },
  {
    label: 'Too expensive',
    value: 'TOO_EXPENSIVE',
  },
  {
    label: 'Better price found',
    value: 'BETTER_PRICE_FOUND',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
];

export type ReturnReasonValue = (typeof RETURN_REASONS)[number]['value'];

export const CANCELLATION_REASONS = [
  {
    label: 'Delayed fulfillment',
    value: 'DELAYED_FULFILLMENT',
  },
  {
    label: 'Found better price somewhere else',
    value: 'BETTER_PRICE_FOUND',
  },
  {
    label: 'I do not want this item anymore',
    value: 'NOT_WANT_ITEM',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
];

export type CancellationReasonValue =
  (typeof CANCELLATION_REASONS)[number]['value'];
