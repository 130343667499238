import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { AnimatePresence } from 'framer-motion';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  Portal,
} from '@point-of-sale/components';
import { useBoolean, useOnClickOutside } from '@point-of-sale/hooks';
import { useAppSelector } from '@point-of-sale/store';
import {
  DeliveryModeType,
  ProductVariantDTOType,
  FulfillmentModeType,
  AddressDTOType,
  INormalizedServiceabilityData,
} from '@point-of-sale/schemas';
import FacilitiesList from './FacilitiesList';
import DeliverToHome from './DeliverToHome';
import {
  CUSTOMER_DELIVERY_LOCATION_NAMES,
  HOME_DELIVERY,
  STORE_PICKUP,
  STORE_PURCHASE,
} from './constants';

import * as Styles from './styles';

type Columns = 'inventory' | 'deliveryDate';

interface IDestinationSelectorProps {
  variant: ProductVariantDTOType | null;
  fulfillmentMode: FulfillmentModeType;
  isLoading?: boolean;
  columns?: Array<Columns>;
  fulfillmentFacilityId?: number;
  deliveryMode?: DeliveryModeType;
  customerDeliveryStoreId?: number;
  onChange?: ({
    customerDeliveryStoreId,
    deliveryMode,
    promiseDate,
    callback,
  }: {
    customerDeliveryStoreId?: number;
    deliveryMode: DeliveryModeType;
    promiseDate?: string;
    callback: (passThrough: boolean) => void;
  }) => void;
  serviceabilityData: INormalizedServiceabilityData[string];
  isMTO: boolean;
  isMTC: boolean;
  width?: string;
  customerAddress: AddressDTOType | undefined;

  shouldShowOtherFacilities?: boolean;

  defaultTat: number;
}

const DestinationSelector = ({
  variant,
  onChange,
  columns = [],
  fulfillmentMode,
  fulfillmentFacilityId,
  deliveryMode,
  customerDeliveryStoreId,
  isLoading = false,
  serviceabilityData,
  isMTO,
  isMTC,
  width = '150px',
  customerAddress,
  shouldShowOtherFacilities = true,
  defaultTat,
}: IDestinationSelectorProps) => {
  const facilities = useAppSelector(store => store.common.facilities.data);
  const currentFacilityId = useAppSelector(
    store => store.identity.selectedSalesChannel?.facilityId
  );

  const [customerDeliveryLocation, setCustomerDeliveryLocation] = useState<{
    name: string | keyof typeof CUSTOMER_DELIVERY_LOCATION_NAMES;
    id: number | DeliveryModeType;
  }>({
    id: -1,
    name: '',
  });

  const [isPopupOpen, popupOpenActions] = useBoolean();
  const [isClickOutsideAllowed, clickOutsideAllowedActions] = useBoolean(true);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  useOnClickOutside(wrapperRef, () => {
    if (isClickOutsideAllowed) {
      popupOpenActions.off();
    }
  });

  // TODO: handle fulfillmentFacilityId from inventory details modal
  ///    ['MTO', 'MTC'].includes(fulfillmentMode) ||
  // const shouldShowHomeDelivery = fulfillmentFacilityId !== currentStoreId;

  useEffect(() => {
    if (currentFacilityId) {
      setCustomerDeliveryLocation({
        id: STORE_PICKUP,
        name: facilities.records[currentFacilityId].name,
      });
    }
  }, []);

  const currentFacility = currentFacilityId
    ? facilities.records[currentFacilityId]
    : null;

  const shouldShowDelivery = columns.includes('deliveryDate');
  const shouldShowInventory = columns.includes('inventory');

  // TODO: Revisit this logic
  useEffect(() => {
    if (deliveryMode === STORE_PURCHASE) {
      if (currentFacility) {
        setCustomerDeliveryLocation({
          id: currentFacility.id,
          name: currentFacility.name || '',
        });
      }
    } else if (deliveryMode === STORE_PICKUP) {
      if (customerDeliveryStoreId) {
        const deliveryFacility = facilities.records[customerDeliveryStoreId];

        setCustomerDeliveryLocation({
          id: customerDeliveryStoreId,
          name: deliveryFacility?.name || '',
        });
      }
    } else if (deliveryMode === HOME_DELIVERY) {
      setCustomerDeliveryLocation({
        id: HOME_DELIVERY,
        name: CUSTOMER_DELIVERY_LOCATION_NAMES[HOME_DELIVERY],
      });
    }
  }, [
    fulfillmentMode,
    fulfillmentFacilityId,
    deliveryMode,
    customerDeliveryStoreId,
    facilities.records,
    currentFacility,
  ]);

  return (
    <Styles.Wrapper ref={wrapperRef} $width={width}>
      <Styles.InputWrapper
        ref={ref => setReferenceElement(ref)}
        onClick={popupOpenActions.on}
      >
        <input type="text" value={customerDeliveryLocation.name} readOnly />
        {isPopupOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
      </Styles.InputWrapper>
      <AnimatePresence>
        {isPopupOpen && (
          <Portal>
            <Styles.PopupWrapper
              $isLoading={isLoading}
              ref={ref => setPopperElement(ref)}
              style={{
                ...styles.popper,
                // width: referenceElement?.getBoundingClientRect().width,
              }}
              {...attributes.popper}
              onMouseEnter={clickOutsideAllowedActions.off}
              onMouseLeave={clickOutsideAllowedActions.on}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: '200px' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ type: 'tween', duration: 0.2 }}
            >
              <Styles.Table>
                <FacilitiesList
                  isMTO={isMTO}
                  isMTC={isMTC}
                  defaultTat={defaultTat}
                  shouldShowDelivery={shouldShowDelivery}
                  shouldShowInventory={shouldShowInventory}
                  shouldShowOtherFacilities={shouldShowOtherFacilities}
                  customerDeliveryLocationId={customerDeliveryLocation.id}
                  variant={variant}
                  facilities={facilities}
                  onChange={(selectedFacilityId, promiseDate) => {
                    onChange?.({
                      customerDeliveryStoreId: selectedFacilityId,
                      deliveryMode: STORE_PICKUP,
                      promiseDate,

                      callback: passThrough => {
                        if (passThrough) {
                          setCustomerDeliveryLocation({
                            id: selectedFacilityId,
                            name: facilities.records[selectedFacilityId].name,
                          });
                        }
                      },
                    });
                  }}
                  serviceabilityData={serviceabilityData}
                />

                <DeliverToHome
                  isMTO={isMTO}
                  isMTC={isMTC}
                  defaultTat={defaultTat}
                  shouldShowDelivery={shouldShowDelivery}
                  isSelected={customerDeliveryLocation.id === HOME_DELIVERY}
                  onChange={promiseDate => {
                    onChange?.({
                      deliveryMode: HOME_DELIVERY,
                      promiseDate,
                      callback: passThrough => {
                        if (passThrough) {
                          setCustomerDeliveryLocation({
                            id: HOME_DELIVERY,
                            name: CUSTOMER_DELIVERY_LOCATION_NAMES[
                              HOME_DELIVERY
                            ],
                          });
                        }
                      },
                    });
                  }}
                  fulfillmentFacilityId={fulfillmentFacilityId}
                  serviceabilityData={serviceabilityData}
                  customerAddress={customerAddress}
                />
              </Styles.Table>
            </Styles.PopupWrapper>
          </Portal>
        )}
      </AnimatePresence>
    </Styles.Wrapper>
  );
};

export default DestinationSelector;
