import { callApi } from '../services/callApi';

export const getStoreStaffApi = () =>
  callApi('POST', `/pos/storestaff/list?page=1&size=100`, {});

export const getSalesChannelsApi = () =>
  callApi('POST', `/pos/saleschannel/search?page=1&size=100`, {
    filters: [
      {
        field: 'salesChannelType',
        filterType: 'IN',
        value: 'POS',
      },
    ],
  });

export const getFacilitiesApi = () =>
  callApi('POST', `/pos/facility/search?page=1&size=100`, {
    filters: [
      {
        filterType: 'IN',
        value: 'STORE,WAREHOUSE', // VENDOR for jit
        field: 'facilityType',
      },
    ],
  });

export const getAnalyticsDashboardUrlApi = (facilityName: string) =>
  callApi('GET', `/pos/analytics-dashboard?facilityName=${facilityName}`);
