export function updateAttributesOfCdnImage(
  url: string | undefined,
  attributes: {
    width?: number;
    quality?: number;
  }
) {
  if (!url) {
    return url;
  }

  let updatedUrl = url;

  if (attributes.width) {
    updatedUrl = updatedUrl.replace(/width=\d+/, `width=${attributes.width}`);
  }

  if (attributes.quality) {
    updatedUrl = updatedUrl.replace(
      /quality=\d+/,
      `quality=${attributes.quality}`
    );
  }

  return updatedUrl;
}
