import useProductContext from '../context/useProductContext';
import toast from 'react-hot-toast';
import {
  addItemToCart,
  setMode,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import { Button } from '@point-of-sale/components';
import { CUSTOM_TAILORED } from '@point-of-sale/constants';
import { useBoolean } from '@point-of-sale/hooks';
import { addDays, getUnixTime } from 'date-fns';
import { AlterationEntryDTOSchema } from '@point-of-sale/schemas';

interface IPlaceMadeToOrderProps {
  /**
   * means that this button will
   * expand the Product View and Set the Mode to MTO
   * instead of adding the item to Cart
   */
  doesNotAddToCart?: boolean;
}

const PlaceMadeToOrder = ({
  doesNotAddToCart = false,
}: IPlaceMadeToOrderProps) => {
  const mode = useAppSelector(store => store.pointOfSale.mode);

  const selectedAddressId = useAppSelector(
    store => store.pointOfSale.selectedAddressId
  );

  const selectedSalesChannel = useAppSelector(
    store => store.identity.selectedSalesChannel
  );

  const {
    quantity,
    selectedVariant,
    fulfillmentFacilityId,
    expandActions,
    data,
  } = useProductContext();

  const [isLoading, loadingActions] = useBoolean();

  const dispatch = useAppDispatch();

  function addToCart() {
    if (!selectedVariant) {
      toast.error('Please select a variant');
      return;
    }

    if (fulfillmentFacilityId) {
      const tat =
        selectedVariant.size === CUSTOM_TAILORED.key
          ? selectedVariant.mtcTAT
          : selectedVariant.mtoTAT;

      loadingActions.on();

      const promiseDate = getUnixTime(
        addDays(new Date(), (tat ?? 0) + 1)
      ).toString();

      let extras = {};

      if (selectedVariant.size === CUSTOM_TAILORED.key) {
        extras = {
          alterationEntry: {
            referenceColor: selectedVariant.color,
            referenceSize: selectedVariant.size,
            customizationCategory: data.customizationCategory,
          },
        };
      }

      dispatch(
        addItemToCart(
          {
            skuId: selectedVariant.skuId,
            skuCode: selectedVariant.skuCode,
            // DEFAULT IN STORE PICKUP
            deliveryMode: 'STORE_PICKUP',
            fulfillmentMode:
              selectedVariant.size === CUSTOM_TAILORED.key ? 'MTC' : 'MTO',
            fulfillmentFacilityId: -1,
            quantity,
            customerDeliveryStoreId: selectedSalesChannel?.facilityId,
            // customerDeliveryAddressId: selectedAddressId,
            promiseDate: promiseDate,
            ...extras,
          },
          loadingActions.off
        )
      );
    }
  }

  function expandAndSetModeToMTO() {
    expandActions.on();
    dispatch(setMode('MADE_TO_ORDER'));
  }

  if (!selectedVariant?.mtoAcceptedByBrand) {
    return null;
  }

  return (
    <Button
      sizeVariant={doesNotAddToCart ? 'xs' : 'medium'}
      variant="fill"
      colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
      onClick={doesNotAddToCart ? expandAndSetModeToMTO : addToCart}
      isDisabled={!selectedVariant?.mtoAcceptedByBrand || !selectedVariant}
      isLoading={isLoading}
    >
      {doesNotAddToCart ? 'Switch To MTO' : 'Place MTO'}
    </Button>
  );
};

export default PlaceMadeToOrder;
