import toast from 'react-hot-toast';
import { Button, Skeleton, Text } from '@point-of-sale/components';
import {
  addCustomerToCart,
  createNewCustomer,
  searchCustomer,
  setCustomerInfoModalUpdateCallback,
  setIsEditCustomerInfoModalOpen,
  updateCustomer,
  useAppDispatch,
  useAppSelector,
} from '@point-of-sale/store';
import CustomerDetails from '../../CustomerDetails';
import { isEmptyObject } from '@point-of-sale/utils';
import * as Styles from './styles';
import { EMAIL_REGEX, PHONE_REGEX } from '@point-of-sale/constants';

const CustomerInfo = () => {
  const mode = useAppSelector(store => store.pointOfSale.mode);
  const isLoading = useAppSelector(
    store => store.pointOfSale.customer.isLoading
  );
  const customerData = useAppSelector(store => store.pointOfSale.customer.data);

  const isEditCustomerInfoModalOpen = useAppSelector(
    store => store.pointOfSale.isEditCustomerInfoModalOpen
  );
  const customerInfoModalUpdateCallback = useAppSelector(
    store => store.pointOfSale.customerInfoModalUpdateCallback
  );

  const dispatch = useAppDispatch();

  const isGuestCheckout = isEmptyObject(customerData);

  return (
    <Styles.Wrapper>
      {isLoading ? (
        <Skeleton height={30} width={200} />
      ) : (
        <Styles.Left>
          {isGuestCheckout ? (
            <Text fontSize={16} weight="semibold">
              GUEST
            </Text>
          ) : (
            <>
              <Text fontSize={16} weight="semibold">
                {customerData.name}
              </Text>
              <Text fontSize={16} weight="semibold">
                {customerData.phone}
              </Text>
            </>
          )}
        </Styles.Left>
      )}

      <Button
        sizeVariant="large"
        variant="ghost"
        onClick={() => {
          dispatch(setIsEditCustomerInfoModalOpen(true));
        }}
        colorScheme={mode === 'MADE_TO_ORDER' ? 'secondary' : 'primary'}
      >
        {isGuestCheckout ? ' Add' : 'Edit'}
      </Button>

      <CustomerDetails
        isModal
        isOpen={isEditCustomerInfoModalOpen}
        onClose={() => {
          dispatch(setIsEditCustomerInfoModalOpen(false));
        }}
        actionables={formData => {
          return (
            <Styles.ButtonContainer>
              <Button
                onClick={() => {
                  function successCallback(doesCustomerExist?: boolean) {
                    if (doesCustomerExist) {
                      dispatch(
                        addCustomerToCart(() => toast.success('Customer Added'))
                      );
                    } else {
                      dispatch(
                        createNewCustomer(formData, () =>
                          dispatch(
                            addCustomerToCart(() =>
                              toast.success('Customer Added')
                            )
                          )
                        )
                      );
                    }
                  }

                  if (formData.email && !EMAIL_REGEX.test(formData.email)) {
                    toast.error('Please Enter a valid email');
                    return;
                  }
                  if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
                    toast.error('Please Enter a valid phone number');
                    return;
                  }

                  if (!customerData.id) {
                    if (formData.phone) {
                      dispatch(
                        searchCustomer(
                          {
                            countryCode: '+91',
                            phoneNumber: formData.phone,
                          },
                          successCallback
                        )
                      );
                    } else if (formData.email) {
                      dispatch(
                        searchCustomer(
                          { email: formData.email },
                          successCallback
                        )
                      );
                    }
                  } else if (customerData.id) {
                    customerInfoModalUpdateCallback?.();
                    dispatch(setCustomerInfoModalUpdateCallback(undefined));

                    dispatch(
                      updateCustomer(customerData.id, formData, () =>
                        dispatch(setIsEditCustomerInfoModalOpen(false))
                      )
                    );
                  }
                  //  else {
                  //   // this should never ever happen
                  //   toast.error('Customer ID not found, Cannot Update');
                  // }
                }}
                colorScheme="dark"
              >
                {isGuestCheckout ? 'Search' : 'Update'}
              </Button>
            </Styles.ButtonContainer>
          );
        }}
      />
    </Styles.Wrapper>
  );
};

export default CustomerInfo;
