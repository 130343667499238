import { z } from 'zod';
import { AddressDTOSchema } from '../customer';
import { CostBreakupDTOSchema } from '../common';
import {
  DeliveryModeSchema,
  FulfillmentModeSchema,
  LineItemDTOSchema,
} from '../cart';
import { OrderDTOSchema } from '../orderManagementSystem';

export const PendingPaymentAddDTOSchema = z.object({
  paymentGateway: z.string(),
  changeAmount: z.number().optional(),
  paymentMode: z.string(),
  remarks: z.string().optional(),
  paymentTerminalId: z.number().optional(),
  metadata: z.record(z.literal('CREDIT_NOTE_REF'), z.string()).optional(),
  lineItemIds: z.array(z.number()),
  salesStaffId: z.number().optional(),
  packageId: z.number(),
});

export type PendingPaymentAddDTOType = z.infer<
  typeof PendingPaymentAddDTOSchema
>;

export const OrderTransactionDTOSchema = z.object({
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  id: z.number(),
  salesChannelOrderId: z.string(),
  posTerminalId: z.number(),
  paymentGateway: z.string(),
  status: z.string(),
  amount: z.number(),
  changeAmount: z.number(),
  salesChannelId: z.number(),
  paymentTerminalId: z.number(),
  paymentMode: z.string(),
  paymentModeDisplayName: z.string(),
  packageId: z.number(),
  order: OrderDTOSchema.optional(),
});

export type OrderTransactionDTOType = z.infer<typeof OrderTransactionDTOSchema>;

export const StorePickupsDTOSchema = z.object({
  id: z.number(),
  createdBy: z.string(),
  createdOn: z.string(),
  lastModifiedOn: z.string(),
  version: z.number(),
  customerId: z.number().optional(),
  customerName: z.string().optional(),
  customerPhone: z.string().optional(),
  salesChannelOrderId: z.string(),
  salesChannelId: z.number(),
  salesFacilityId: z.number(),
  salesFacilityGroupId: z.number(),
  currentStatus: z.string(),
  orderId: z.number(),
  history: z.array(z.object({ status: z.string(), eventDate: z.string() })),
  orderLineItemIds: z.array(z.number()),
  orderLineItems: z.array(LineItemDTOSchema).optional(),
  deliveryMode: DeliveryModeSchema,
  fulfillmentMode: FulfillmentModeSchema,
  customerBillingAddress: z.union([z.object({}), AddressDTOSchema.optional()]),
  customerDeliveryAddress: z
    .union([z.object({}), AddressDTOSchema.optional()])
    .optional(),
  customerDeliveryStoreId: z.number().optional(),
  fulfillmentFacilityId: z.number().optional(),
  unicomFacilityCode: z.string().optional(),
  fulfillmentFacilityType: z.string().optional(),
  fulfillmentFacilityGroupId: z.number().optional(),
  unicomSaleOrderCode: z.string().optional(),
  costBreakup: CostBreakupDTOSchema,
  estimatedDeliveryDate: z.string().optional(),
  onhold: z.boolean().optional(),
  isExpress: z.boolean().optional(),
});

export type StorePickupsDTOType = z.infer<typeof StorePickupsDTOSchema>;
